import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button, Badge } from "react-bootstrap";
import PageTitle from "../../../../layouts/PageTitle";
import DataTablev2 from "../../../Datatable";
import { GetAll, ChangeStatus, Delete } from '../../../../../services/ReportProblemsService'
import DefaultLoader from "../../../Loader";
import Swal from "sweetalert2";
import AlertResponses from "../../../Alert";
import ModalComponent from "../../../Modal";
import InboxDetails from "../../../../../screens/InboxDetails";

export default function Inbox() {
   const messages = useRef(null);
   const dataModal = useRef(null)
   const [showDeleteButton, setShowDeleteButton] = useState(false);
   const [selectedRows, setSelectedRows] = useState(null);
   const [errors, setErrors] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
   const [showModal, setShowModal] = useState(false);

   useEffect(() => {
      GetAll(messages, setIsLoading, setErrors)
   }, [])

   const deleteRows = (state) => {
      if (state.selectedRows !== (null || undefined) && state.selectedRows.length > 0) {
         setSelectedRows(state.selectedRows);
         setShowDeleteButton(true);
      } else {
         setShowDeleteButton(false);
      }
   }

   const status = {
      0: {
         id: 0,
         label: "Criado",
         type: "light"
      },
      1: {
         id: 1,
         label: "Em andamento",
         type: "info"
      },
      2: {
         id: 2,
         label: "Finalizado",
         type: "success"
      },

   }


   const types = {
      0: {
         id: 0,
         label: "Problema na Refeição",
         type: "light"
      },
      1: {
         id: 1,
         label: "Problema na Entrega",
         type: "info"
      },
      2: {
         id: 2,
         label: "Problema no Pagamento",
         type: "danger"
      },
      3: {
         id: 3,
         label: "Problema no App",
         type: "warning"
      },
   }

   const BadgeComponentMemo = (row) => {
      return useMemo(() => {
         return (
            <DropdownButton
               variant=''
               style={styles.dropdown}
               title={status[row.status].label}
               className={`badge badge-${status[row.status].type} badge-sm light badge-delivery-status badge-${row.id}`}
               onSelect={(e) => {
                  ChangeStatus({ id: row.id, status: e }, setNotify).then(res => {
                     if (res) {
                        document.querySelector(`.badge-${row.id}`).classList.remove(`badge-${status[row.status].type}`)
                        document.querySelector(`.badge-${row.id}`).classList.add(`badge-${status[e].type}`)
                        document.querySelector(`.badge-${row.id} button`).innerText = status[e].label
                     }
                  })

               }}
            >
               {Object.values(status).map((value, i) => i !== 5 &&
                  <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
            </DropdownButton>
         )
      }, [])
   }

   const modalProps = {
      title: 'Mensagem',
      content: <InboxDetails data={dataModal.current} />,
      btnLabel: 'Salvar',
      showModal: showModal,
      setShowModal: setShowModal,
      showFooter: false,
   }

   const columns = [
      {
         name: 'Nome',
         selector: (row) => row.user__first_name + ' ' + row.user__last_name,
         sortable: true,
         maxWidth: '20%'
      },
      {
         name: 'Whatsapp',
         selector: (row) => <a target={'_blank'} style={{ textDecoration: 'underline' }} href={`https://api.whatsapp.com/send?phone=+55${row.user__phone}&text=Ol%C3%A1${encodeURI(', ' + row.user__first_name + '! ')}%20Recebemos%20sua%20mensagem%20notificando%20que%20teve%20algum%20problema%20com%20seu%20pedido.%20Estamos%20entrando%20em%20contato%20para%20ajud%C3%A1-l%C3%B4!%20`}>{row.user__phone}</a>,
         sortable: true,
         maxWidth: '15%'
      },
      {
         name: 'Mensagem',
         selector: (row) => <a onClick={() => {dataModal.current = row; setShowModal(true)}} style={{cursor: 'pointer' }}>{row.description}</a>,
         maxWidth: '25%'
      },
      {
         name: 'Tipo',
         selector: (row) => <Badge variant={`primary badge-xs light`}>{types[row.type].label}</Badge>,
         maxWidth: '20%'
      },
      {
         name: 'Status',
         selector: (row) => BadgeComponentMemo(row),
         sortable: true,
         maxWidth: '20%',
         allowOverflow: true
      },
   ];


   return (
      <>

         {
            isLoading ? <DefaultLoader isLoading={isLoading} /> :
               <div className="row">
                  <div className="col-lg-12">
                     <div className="col-xl-12 col-xxl-12 col-sm-12">
                        <h2 className="d-inline-block">Mensagens</h2>
                        {showDeleteButton &&

                           <Button className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                              onClick={() =>
                                 Swal.fire({
                                    title: "Você tem certeza?",
                                    text:
                                       "Todos os arquivos selecionados serão deletados. Esta ação é irreversível!",
                                    icon: "warning",
                                    buttons: true,
                                    confirmButtonText: `Continuar a ação`,
                                    cancelButtonText: `Cancelar`,
                                    showCancelButton: true,
                                    confirmButtonColor: 'red'
                                 }).then((value) => {
                                    if (value) Delete(selectedRows, setNotify)
                                 })
                              }
                           >
                              <span>DELETAR SELECIONADOS</span>
                           </Button>
                        }
                     </div>

                     <DataTablev2
                        data={messages.current}
                        columns={columns}
                        title="  "
                        selectable={true}
                        onSelectedRowsChange={deleteRows}
                        showDeleteButton={showDeleteButton}
                        filterDefaultField={'user__first_name'}
                        filterByField={{ labels: ['Nome', 'Status', 'Tipo'], fields: ['user__first_name', 'status', 'type'] }}
                     />
                  </div>
                  {
                     notify.visible &&
                     <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
                  }

                  {showModal && <ModalComponent props={modalProps} />}
               </div>
         }
      </>
   );
};

const styles = {
   photoDefault: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      height: '50px',
      borderRadius: '25px',
      background: '#efefef'
   },
   i: {
      fontSize: '18px',
      color: '#3d4465'
   },
   dropdown: {
      padding: '5px 15px',
      fontSize: '12px'
   }
}
