import React, { useEffect, createContext, useState } from 'react'
import {useHistory} from 'react-router-dom'

const UserContext = createContext();

export function UserProvider({ children }) {

    const [user, setUser] = useState(null);
    const [logged, setLogged] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let token = localStorage.getItem('token');
        if(token === null || token === undefined ||  token === ''){
          history.push('/login')
        }
      }, [])

    return (
        <UserContext.Provider value={{ user, setUser, logged, setLogged }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;