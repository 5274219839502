import { api } from '../api/Middleware'
import { VW_PAYMENTS_ANALYTICS, VW_ORDERS_ANALYTICS, VW_USERS_ANALYTICS, VW_BILLING_AND_ORDERS, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetOrders = async (setOrders, setErrors) => {
    try {
        let response = await api.get(VW_ORDERS_ANALYTICS);
        if (response.status === 200) setOrders(response.data); 
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
        
    }
}

export const GetUsers = async (setUsers, setErrors) => {
    try {
        let response = await api.get(VW_USERS_ANALYTICS);
        if (response.status === 200) setUsers(response.data); 
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
        
    }
}

export const GetPayments = async (setUsers, setErrors) => {
    try {
        let response = await api.get(VW_PAYMENTS_ANALYTICS);
        if (response.status === 200) setUsers(response.data); 
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
        
    }
}

export const GetBillingAndOrders = async (setBillingAndOrders, setErrors) => {
    try {
        let response = await api.get(VW_BILLING_AND_ORDERS);
        if (response.status === 200) setBillingAndOrders(response.data); 
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
        
    }
}

