export const ERROR_MESSAGES = {
    orders: {
        get: 'Desculpe, ocorreu um problema inesperado ao tentar listar seus pedidos. Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.',
        status: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar o status do seu pedido. Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.',
    },
    products: {
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar seu produto.Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.'
    },
    categories: {
        get: 'Desculpe, ocorreu um problema inesperado ao tentar listar suas categorias. Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.',
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar a categoria.Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.'
    },
    promotions: {
        get: "Desculpe, ocorreu um problema inesperado ao tentar listar seus produtos. Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma."
    },
    login: {
        auth: "Desculpe, ocorreu um problema inesperado ao tentar logar-se. Por favor, tente novamente mais tarde. Se o problema persistir, contacte o suporte de sua plataforma.",
        permission: "Usuário sem permissão para acessar esta área!",
        generic: "Usuário não encontrado ou sem permissão!"
    },
    configurations: {
        getStore: "Desculpe, ocorreu um problema inesperado ao tentar recuperar suas informações. Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.",
        getCustomizations: "Desculpe, ocorreu um problema inesperado ao tentar recuperar suas configurações. Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.",
        updateCustomizations: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar suas configurações.Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.'
    },
    variations: {
        get: 'Desculpe, ocorreu um problema inesperado ao tentar listar suas variações. Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.',
        create: 'Desculpe, ocorreu um problema inesperado ao tentar criar a variação.Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.',
        update: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar a variação.Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.',
        status: 'Desculpe, ocorreu um problema inesperado ao tentar atualizar o status da sua variação. Por favor, tente novamente. Se o problema persistir, contacte o suporte de sua plataforma.',
    }
}