export const BASE_URL = process.env.REACT_APP_BASE_URL
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL

export const IFOOD_BASE_URL = "https://merchant-api.ifood.com.br/";
export const LOGIN_URL = BASE_URL + "auth/login";
export const REFRESH_TOKEN_URL = BASE_URL + "refresh_token/";
export const CATEGORIES_URL = BASE_URL + "categories/";
export const PRODUCTS_URL = BASE_URL + "products/";
export const PROMOTIONS_URL = BASE_URL + "promotions/";
export const USERS_URL = BASE_URL + "users/";
export const VALIDATE_CPF_URL = BASE_URL + "users/validate_cpf/";
export const USER_ADRESSES = BASE_URL + "adresses/";
export const COUPONS_URL = BASE_URL + "coupons/";
export const USER_COUPONS = BASE_URL + "user_coupons/";
export const DELIVERY = BASE_URL + "delivery/";
export const AUTH = BASE_URL + "auth/";
export const ORDERS_URL = BASE_URL + "orders/";
export const PAYMENT_METHODS_URL = BASE_URL + "payment_methods/";
export const REVERSE_GEOCODE =
  "https://revgeocode.search.hereapi.com/v1/revgeocode";
// export const PLACES_API =
//   "https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?";
export const PLACES_API = "https://geocode.search.hereapi.com/v1/geocode?";
export const GEOCODER_API = "https://geocoder.ls.hereapi.com/6.2/geocode.json?";
export const STORE_URL = BASE_URL + "store/";
export const REPORT_PROBLEMS_URL = BASE_URL + "report_problems/";
export const VW_ITEMS = BASE_URL + "dbviews/";
export const VW_ORDERS = BASE_URL + "dbvieworders/";
export const VW_HOME = BASE_URL + "dbviewhome/";
export const VW_USERS_ANALYTICS = BASE_URL + "dbview_users_analytics/";
export const VW_PAYMENTS_ANALYTICS = BASE_URL + "dbview_payment_analytics/";
export const VW_ORDERS_ANALYTICS = BASE_URL + "dbview_orders_analytics/";
export const VW_BILLING_AND_ORDERS = BASE_URL + "dbview_billing_and_orders/";
export const CUSTOMIZATIONS_URL = BASE_URL + "customizations/";
export const VARIATIONS_URL = BASE_URL + "variations/";
export const IFOOD_AUTH_URL =
  IFOOD_BASE_URL + "authentication/v1.0/oauth/token";

//Images Url's
export const IMAGES_BASE_URL = "api.generalpepper.com.br";
export const IMAGES_URL = IMAGES_BASE_URL + "/images/";
export const THUMBOR_URL = "https://images.generalpepper.com.br/";

//DEV
// export const IMAGES_BASE_URL = "127.0.0.1:6999/"
// export const IMAGES_URL = IMAGES_BASE_URL
// export const THUMBOR_URL = "http://127.0.0.1:7000/"
