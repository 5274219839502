import React, { useEffect, useState, createRef } from "react";
import { useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { Hash } from '../helpers/Helpers'
import { Create, Update, GetOne } from '../services/UsersServices'
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { Theme } from '../App'
import Errors from '../jsx/components/Errors'
import AlertResponses from '../jsx/components/Alert'
import { useForm } from "react-hook-form";

export default function UserDetails(props) {

  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const { register, handleSubmit, watch, setError, setValue, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id !== undefined && props.location.data !== undefined) {
      setUser(props.location.data)
      setValue('birthday', props.location.data.birthday)
      setValue('phone', props.location.data.phone)
      setValue('cpf', props.location.data.cpf)
    } else if (id !== undefined) {
      GetOne(id, setUser, setValue, setIsLoading);
    } else {
      setUser('')
    }
  }, []);

  useEffect(() => {
    user !== null && setIsLoading(false)
  }, [user]);

  /*   useEffect(() => {
      let isReady = false;
      if (!isReady && id !== null && user !== null && categories !== null) setIsLoading(false);
      else if (!isReady && id === (null || undefined) && categories !== null) setIsLoading(false);
      return () => { isReady = true }
    }, [categories, user]);
   */
  const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 20%;
`;

  const imagePreview = (image) => {
    let url = URL.createObjectURL(image);
    setImageFile(url)
  }

  const onSubmit = (data) => {

    var form = new FormData();

    form.append('first_name', data.first_name);
    form.append('last_name', data.last_name);
    form.append('email', data.email);
    form.append('cpf', data.cpf.replace(/[^0-9]/g, ''))
    form.append('birthday', data.birthday.split('/').reverse().join('-'))
    form.append('phone', data.phone.replace(/[^0-9]/g, ''))
    data.photo.length > 0 && form.append('photo', data.photo[0]);
    form.append('superuser_level', data.superuser_level);
    form.append('is_active', parseInt(data.is_active));
    form.append('is_superuser', 1);

    if (id !== undefined) {
      form.append('id', id);
      Update(id, form, setUser, setNotify);
    } else {
      Create(form, setUser, setNotify, setError);
    }

  }


  return (
    <>
      <div className="row">
        {
          isLoading ? <BeatLoader color={Theme.colors.primary} loading={isLoading} css={override} size={12} />
            :
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-4 col-md-4 col-xxl-4">
                      {
                        user.photo != (null || undefined) || imageFile !== null ?
                          <img className="p-us user-image img-fluid  mt-4" src={imageFile === null ? Hash('400x400', user.photo, true) : imageFile} alt="image" />
                          : <div className="p-us image-default">
                            <i className="ti-image"></i>
                          </div>
                      }

                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Upload</span>
                        </div>
                        <div className="custom-file">
                          <input accept="image/*" type="file" className="custom-file-input"
                            ref={register} {...register("photo")}
                            onChange={(e) => {
                              if (e.target.files[0].size > 4000000) {
                                setNotify({ title: 'Oops!', message: 'Por favor, insira imagens até 4MB!', type: 'warning', visible: true });
                              } else {
                                imagePreview(e.target.files[0])
                              }
                            }} />
                          <label className="custom-file-label">Selecione...</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="text-capitalize">{user !== null ? user.name : 'Novo Usuário'}</h3>
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label>Primeiro nome</label>
                                  <input
                                    {...register("first_name", { required: true })}
                                    className="form-control text-black"
                                    defaultValue={user !== null ? user.first_name : ''}
                                  />
                                  {errors.first_name &&
                                    <small className="ml-2 text-danger">
                                      {errors.first_name.type === 'required'
                                        && 'Por favor, preencha este campo!'}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Sobrenome</label>
                                  <input
                                    {...register("last_name", { required: true })}
                                    className="form-control text-black"
                                    defaultValue={user !== null ? user.last_name : ''}
                                  />
                                  {errors.last_name &&
                                    <small className="ml-2 text-danger">
                                      {errors.last_name.type === 'required'
                                        && 'Por favor, preencha este campo!'}</small>}
                                </div>
                                <div className="form-group col-md-12">
                                  <label>E-mail</label>
                                  <input
                                    {...register("email", {
                                      required: true,
                                      pattern: /^[a-zA-Z0-9]+@.*/
                                    })}
                                    className="form-control text-black"
                                    placeholder="insira um e-mail do google"
                                    defaultValue={user !== null ? user.email : ''}
                                  />
                                  {errors.email &&
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.email.type === 'required' ? 'Por favor, preencha este campo!' :
                                        'Por favor, insira um e-mail google válido!'}</small>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Aniversário</label>
                                  <NumberFormat
                                    defaultValue={user !== null ? new Date(user.birthday).toLocaleDateString() : ''}
                                    format="##/##/####"
                                    className="form-control text-black"
                                    onChange={(e) => setValue("birthday", e.target.value)}
                                    {...register("birthday", { required: true })}

                                  />
                                  {errors.birthday &&
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.birthday.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>CPF</label>
                                  <NumberFormat
                                    defaultValue={user !== null ? user.cpf : ''}
                                    format="###.###.###-##"
                                    className="form-control text-black"
                                    {...register("cpf", { required: true })}
                                  />
                                  {errors.cpf &&
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.cpf.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Telefone</label>
                                  <NumberFormat
                                    defaultValue={user !== null ? user.phone : ''}
                                    format="(##) #####-####"
                                    className="form-control text-black"
                                    onChange={(e) => setValue("phone", e.target.value)}
                                    {...register("phone", { required: true })}
                                  />
                                  {errors.phone &&
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.phone.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Nível</label>
                                  <select
                                    defaultValue={user !== null ? user.superuser_level : ''}
                                    className="form-control text-black"
                                    {...register("superuser_level", { required: true })}
                                  >
                                    <option value="1">Gerente</option>
                                    <option value="2">Supervisor</option>
                                    {/* {
                                      categories !== null &&
                                      categories.map((_categorie, i) =>
                                        <option key={i} value={_categorie.id}
                                          defaultValue={_categorie.id === categorie && _categorie.id}
                                        >{_categorie.name}</option>
                                      )
                                    } */}
                                  </select>
                                  {errors.superuser_level &&
                                    <small className="ml-2 text-danger">
                                      {errors.superuser_level.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                </div>

                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Status</label>
                                  <select
                                    className="form-control text-black"
                                    defaultValue={user !== null ? user.is_active : ''}
                                    {...register("is_active", { required: true })}
                                  >
                                    <option value={1}>Ativo</option>
                                    <option value={0}>Desativado</option>
                                  </select>
                                </div>
                                {errors.is_active &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.is_active.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                              </div>

                              <button type="submit" className="btn btn-block btn-primary">
                                Salvar
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                notify.visible &&
                <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
              }
            </div>
        }
      </div>
    </>
  );
};