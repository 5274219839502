import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
    render() {
        const { children, triggerLogin, ...props } = this.props;
        return (
            <div className="google-btn" onClick={triggerLogin} {...props}>
                <div className="google-icon-wrapper">
                    <img className="google-icon mt-2" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                </div>
                <p className="text-white m-auto">{children}</p>
            </div>
        );
    }
}

export default SocialLogin(SocialButton);