import React, { useEffect, useState, createRef, useRef } from "react";
import { useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { Hash } from '../helpers/Helpers'
import { Create, Update, GetOne, GetCategories } from '../services/ProductsServices'
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { Theme } from '../App'
import Errors from '../jsx/components/Errors'
import AlertResponses from '../jsx/components/Alert'
import { useForm, Controller } from "react-hook-form";
import { GetAll } from '../services/VariationsServices'

export default function ProductDetail(props) {

  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const imageRef = createRef(null);
  const [price, setPrice] = useState(null);
  const [categorie, setCategorie] = useState(null);
  //TODO: Puxar o status na api dos produtos
  const [status, setStatus] = useState('');
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const { register, handleSubmit, watch, control, formState: { errors } } = useForm();
  const [variations, setVariations] = useState(null);

  const [categories, setCategories] = useState(null);
  let [isLoading, setIsLoading] = useState(true);

  const GetVariations = async () => {
    let response = await GetAll(null, null, null, true);
    setVariations(response)
  }

  useEffect(() => {
    if (id !== undefined) GetOne(id, setProduct);
    GetCategories(setCategories);
    GetVariations()
  }, []);

  useEffect(() => {
    let isReady = false;
    if (!isReady && id !== null && product !== null && categories !== null && variations !== null) setIsLoading(false);
    else if (!isReady && id === (null || undefined) && categories !== null && variations !== null) setIsLoading(false);
    return () => { isReady = true }


  }, [categories, product, variations]);

  const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 20%;
`;

  const imagePreview = (image) => {
    let url = URL.createObjectURL(image);
    setImageFile(url)
  }

  const onSubmit = (data) => {
    document.querySelector('.btn-submit').setAttribute('disabled', true)

    let arr = [];
    let a = [];
    Object.keys(data).filter(value => {
      if (value.includes('variation')) {
        if (data[value]) {
          let n = JSON.parse(data[value]);
          arr.push(n.id)
        }
      }

    })

    if (typeof (data.price) === 'string') {
      if (data.price.includes('R$')) {
        data.price = parseFloat(data.price.replace(/[^0-9,]/g, '').replace(',', '.'));
      }
    }

    var form = new FormData();


    form.append('name', data.name);
    form.append('price', data.price);
    form.append('description', data.description);
    data.image.length > 0 && form.append('image', data.image[0]);
    form.append('categorie', data.categorie);
    arr.length > 0 ? form.append('variations', JSON.stringify(arr)) : form.append('variations', null);
    form.append('status', data.status);
    form.append('featured', data.featured);
    if (id !== undefined) {
      form.append('id', id);
      Update(id, form, setProduct, setNotify);
    } else {
      Create(form, setProduct, setNotify);
    }

  }

  const ShowVariations = () => {
    return (
      <div className="form-group col-md-12">
        <label>Variações</label>
        {
          variations.map((v, i) => {
            return (

              <div className="custom-control custom-checkbox mb-3 mt-3" key={i}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`variation${i}`}
                  defaultValue={JSON.stringify(v)}
                  {...register(`variation-${i}`)}
                  defaultChecked={product && product.variations && product.variations.indexOf(v.id) > -1 ? true : false}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`variation${i}`}
                >
                  {v.data.internalTitle}
                </label>
              </div>

            )
          })
        }
      </div>
    )
  }

  return (
    <>
      <div className="row">
        {
          isLoading ? <BeatLoader color={Theme.colors.primary} loading={isLoading} css={override} size={12} />
            :
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-4 col-md-4 col-xxl-4">
                      {
                        product && product.image || imageFile ?
                          <img className="img-fluid rounded mt-4 product-image" src={imageFile === null ? Hash('400x400', 'products/' + product.image.split('/').pop()) : imageFile} alt="image" />
                          : <div className="image-default rounded-lg">
                            <i className="ti-image"></i>
                          </div>
                      }

                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Upload</span>
                        </div>
                        <div className="custom-file">
                          <input accept="image/*" type="file" className="custom-file-input"
                            ref={register} {...register("image")}
                            onChange={(e) => imagePreview(e.target.files[0])} />
                          <label className="custom-file-label">Selecione...</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="">{product !== null ? product.name : 'Novo Produto'}</h3>
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Nome</label>
                                  <input
                                    {...register("name", { required: true, maxLength: 30 })}
                                    className="form-control text-black"
                                    defaultValue={product !== null ? product.name : ''}
                                  />
                                  {errors.name &&
                                    <small className="ml-2 text-danger">
                                      {errors.name.type === 'required'
                                        ? 'Por favor, preencha este campo!'
                                        : 'Este campo deve conter no máximo 30 caracteres!'}</small>}
                                </div>
                                <div className="form-group col-md-12">
                                  <label>Categoria</label>
                                  <select
                                    defaultValue={product !== null ? product.categorie : ''}
                                    className="form-control text-black"
                                    {...register("categorie", { required: true })}
                                  >
                                    {
                                      categories !== null &&
                                      categories.map((_categorie, i) =>
                                        <option key={i} value={_categorie.id}
                                          defaultValue={_categorie.id === categorie && _categorie.id}
                                        >{_categorie.name}</option>
                                      )
                                    }
                                  </select>
                                  {errors.categorie &&
                                    <small className="ml-2 text-danger">
                                      {errors.categorie.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                </div>
                                {
                                  variations !== null &&

                                  ShowVariations()
                                }
                                <div className="form-group col-md-12">
                                  <label>Valor</label>
                                  <Controller
                                    name="price"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={product !== null ? parseFloat(product.price) : ''}
                                    render={({ field }) =>
                                      <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        prefix="R$"
                                        className="form-control text-black"
                                        {...field}
                                      />
                                    }

                                  />
                                  {/*  <NumberFormat
                                    defaultValue={product !== null ? product.price : ''}
                                    fixedDecimalScale={true}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    className="form-control text-black"
                                    {...register("price", { required: true })}
                                  /> */}

                                </div>
                                {errors.price &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.price.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                <div className="form-group col-md-12">
                                  <label>Descrição</label>
                                  <textarea
                                    className="form-control text-black"
                                    rows="4"
                                    defaultValue={product !== null ? product.description : ''}
                                    {...register("description", { required: true })}
                                  ></textarea>
                                </div>
                                {errors.description &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.description.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Status</label>
                                  <select
                                    className="form-control text-black"
                                    defaultValue={product !== null ? product.status : ''}
                                    {...register("status", { required: true })}
                                  >
                                    <option value={1}>Ativo</option>
                                    <option value={2}>Desativado</option>
                                  </select>
                                </div>
                                {errors.status &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.status.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Destaque <i className="ti-info-alt" data-toggle="tooltip" title="Destaque no site e app" style={{ cursor: 'pointer' }}></i></label>
                                  <select
                                    className="form-control text-black"
                                    defaultValue={product !== null ? product.featured : ''}
                                    {...register("featured", { required: true })}
                                  >
                                    <option value={0}>Não</option>
                                    <option value={1}>Sim</option>
                                  </select>
                                </div>
                                {errors.status &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.status.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                              </div>

                              <input type="submit" className="btn btn-block btn-primary btn-submit">
                              </input>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                notify.visible &&
                <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
              }
            </div>
        }
      </div>
    </>
  );
};
