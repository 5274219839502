import { api } from '../api/Middleware'
import { REPORT_PROBLEMS_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetAll = async (messages, setIsLoading, setErrors) => {
    let response = await api.get(REPORT_PROBLEMS_URL);
    try {
        if (response.status === 200) messages.current = response.data
    } catch (error) {
        if (error.response.status === 401) {
            window.location.href = APP_BASE_URL
        } else {
            setErrors({ title: "Oops!", message: ERROR_MESSAGES.promotions.get })
        }
    }
    setIsLoading(false)
}

export const ChangeStatus = async (data, setNotify) => {
    try {
        let response = await api.put(REPORT_PROBLEMS_URL + data.id+ '/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Status alterado com sucesso!', type: 'success', visible: true })
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        return false;
    }
}

export const Delete = async (data, setNotify) => {
    try {
        let ids = [];
        data.map(product => ids.push(product.id))
        let response = await api.delete(REPORT_PROBLEMS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }
}