import React, { useEffect, useState, createRef } from "react";
import { useParams } from "react-router-dom";
import { Hash } from '../helpers/Helpers'
import { Create, Update, GetOne } from '../services/PromotionsServices'
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { Theme } from '../App'
import ErrorsComponent from '../jsx/components/Errors'
import AlertResponses from '../jsx/components/Alert'
import { useForm } from "react-hook-form";
import { GetAll } from '../services/CategoriesServices'
import NumberFormat from 'react-number-format';
import ProgressBarComponent from '../jsx/components/ProgressBar'

export default function PromotionDetails(props) {
  const { id } = useParams();
  const [promotion, setPromotion] = useState(null);
  const [name, setName] = useState(null);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const imageRef = createRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [erros, setErros] = useState(null)
  const [progress, setProgress] = useState(false);

  const [status, setStatus] = useState('');
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const { register, handleSubmit, watch, setError, formState: { errors } } = useForm();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    if (id !== undefined) {
      GetOne(id, setPromotion, setIsLoading);
    }

    GetAll(setCategories, setErros, setIsLoading);
  }, []);

  useEffect(() => {
    let isReady = false;
    if (!isReady && id !== undefined && promotion !== null && categories !== null) setIsLoading(false);
    else if (!isReady && id === undefined && categories !== null) setIsLoading(false);
    return () => { isReady = true }
  }, [promotion, categories]);

  const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 20%;
`;

  const imagePreview = (image) => {
    let url = URL.createObjectURL(image);
    setImageFile(url)
  }

  const onSubmit = (data) => {

    var form = new FormData();

    if (typeof (price) === 'number') data.price = data.price.toString();

    if (data.price.indexOf('R$') > -1) {
      data.price = data.price.replace(/[^0-9,]/g, '').replace(',', '.');
    }

    form.append('name', data.name);
    form.append('description', data.description)
    form.append('rules', data.rules)
    form.append('price', data.price);
    data.image.length > 0 && form.append('image', data.image[0]);
    form.append('promotion_categorie_id', data.promotion_categorie)
    form.append('status', data.status);

    if (id !== undefined) {
      form.append('id', id);
      Update(id, form, setPromotion, setNotify, setProgress);
    } else {
      Create(form, setPromotion, setNotify, setError, setProgress);
    }
  }

  return (
    <>
      <div className="row">
        {
          isLoading ? <BeatLoader color={Theme.colors.primary} loading={isLoading} css={override} size={12} />
            :
            <div className="col-lg-12">
              {erros !== null && <ErrorsComponent />}
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-4 col-md-4 col-xxl-4">
                      {
                        promotion !== null || imageFile !== null ?
                          //TODO: VOLTAR PARA O NORMAL DE PRO
                          <img className="img-fluid rounded mt-4 promotion-image" src={imageFile === null ? promotion.thumb : imageFile} alt="image" style={styles.image} />
                          /*  <img className="img-fluid rounded mt-4 promotion-image" src={imageFile === null ? Hash('400x400', 'promotions/' + promotion.thumb.split('/').pop()) : imageFile} alt="image" /> */
                          : <div className="image-default rounded-lg">
                            <i className="ti-image"></i>
                          </div>
                      }

                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Upload</span>
                        </div>
                        <div className="custom-file">
                          <input accept="image/*" type="file" className="custom-file-input"
                            ref={register} {...register("image", { required: true })}
                            onChange={(e) => imagePreview(e.target.files[0])} />
                          <label className="custom-file-label">Selecione...</label>

                        </div>
                      </div>
                      {errors.image &&
                        <small className="ml-2 text-danger">
                          {errors.image.type === 'required'
                            && 'Por favor, selecione uma imagem!'}</small>}
                    </div>

                    <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="">{promotion !== null ? promotion.name : 'Nova Promoção'}</h3>
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Nome</label>
                                  <input
                                    {...register("name", { required: true, maxLength: 35 })}
                                    className="form-control text-black"
                                    defaultValue={promotion !== null ? promotion.name : ''}
                                  />
                                  {errors.name &&
                                    <small className="ml-2 text-danger">
                                      {errors.name.type === 'required'
                                        ? 'Por favor, preencha este campo!'
                                        : 'Este campo deve conter no máximo 35 caracteres!'}</small>}
                                </div>
                                <div className="form-group col-md-12">
                                  <label>Categoria</label>

                                  <select
                                    //defaultValue={promotion !== null ? promotion.promotion_categorie : ''}
                                    value={promotion !== null ? promotion.promotion_categorie : ''}
                                    className="form-control text-black"
                                    {...register("promotion_categorie", { required: true })}
                                  >
                                    {
                                      categories !== null &&
                                      categories.map((_categorie, i) =>
                                        <option key={i} value={_categorie.id}
                                        >{_categorie.name}</option>
                                      )
                                    }
                                  </select>
                                  {errors.categorie &&
                                    <small className="ml-2 text-danger">
                                      {errors.categorie.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                </div>
                                <div className="form-group col-md-12">
                                  <label>Valor</label>
                                  <NumberFormat
                                    defaultValue={promotion !== null ? promotion.price : ''}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$"
                                    className="form-control text-black"
                                    {...register("price", { required: true })}
                                  />

                                </div>
                                {errors.price &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.price.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                <div className="form-group col-md-12">
                                  <label>Descrição</label>
                                  <textarea
                                    className="form-control text-black"
                                    rows="4"
                                    defaultValue={promotion !== null ? promotion.description : ''}
                                    {...register("description", { required: true })}
                                  ></textarea>
                                </div>
                                {errors.description &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.description.type === 'required' && 'Por favor, preencha este campo!'}</small>}

                                <div className="form-group col-md-12">
                                  <label>Regras</label>
                                  <textarea
                                    className="form-control text-black"
                                    rows="4"
                                    defaultValue={promotion !== null ? promotion.rules : ''}
                                    {...register("rules")}
                                  ></textarea>
                                </div>

                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Status</label>
                                  <select
                                    className="form-control text-black"
                                    defaultValue={promotion !== null ? promotion.status : ''}
                                    {...register("status", { required: true })}
                                  >
                                    <option value={1}>Ativo</option>
                                    <option value={2}>Desativado</option>
                                  </select>
                                </div>
                                {errors.status &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.status.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                              </div>

                              <input type="submit" className="btn btn-block btn-primary">
                              </input>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {progress && <ProgressBarComponent />}
              {
                notify.visible &&
                <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
              }
            </div>
        }
      </div>
    </>
  );
};

const styles = {
  image: {
    maxWidth: 330,
    maxHeight: 250,
    width: '100%',
    objectFit: 'cover'
  }
}
