import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import Select from 'react-select'
import { Auth } from '../services/IfoodIntegrationServices';

export default function IfoodIntegration() {

    const [steps, setSteps] = useState(0);
    const { register, handleSubmit, watch, setError, formState: { errors } } = useForm();
    const { register: registerProducts, handleSubmit: handleSubmitProducts, setValue: setValueProducts, setError: setErrorProducts, formState: { errors: errorsProducts } } = useForm();
    const [catalogs, setCatalogs] = useState(null);

    const onSubmit = (data) => {
        let form = new URLSearchParams();
        form.append('grantType','client_credentials')
        form.append('clientId',process.env.REACT_APP_CLIENT_ID)
        form.append('clientSecret',process.env.REACT_APP_CLIENT_SECRET)
        Auth(form)
    }

    const onSubmitProducts = (data) => {

    }

    return (
        <>
            <div className="row mt-4 flex-column">
                <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                    <div className="col-xl-4 col-xxl-5 col-sm-12">
                        <h2>Integração iFood</h2>
                    </div>
                </div>

                <div className="col-xl-6 col-xxl-6 col-sm-12">
                    <div className="card mt-4">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>ID da Loja</label>
                                        <input
                                            {...register("storeId", { required: true, minLength: 30 })}
                                            className="form-control text-black"
                                            defaultValue={''}
                                        />
                                    </div>
                                    {errors.storeId &&
                                        <small className="ml-2 mb-2 text-danger">
                                            {errors.storeId.type === 'required'
                                                ? 'Por favor, preencha este campo!'
                                                : 'O ID contém pelo menos 30 caracteres!'}</small>}
                                </div>
                                <button className="btn btn-primary float-right" type="submit">Conectar</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-6 col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmitProducts(onSubmitProducts)}>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>Catálogos</label>
                                        <Select
                                            name="operation"
                                            options={catalogs}
                                            defaultValue={''}
                                            /* onChange={(e) => setValue('operation', e.value)} */
                                            className="rounded-form-control"
                                            placeholder="Selecione uma opção..."
                                        />
                                    </div>
                                    {errors.storeId &&
                                        <small className="ml-2 mb-2 text-danger">
                                            {errors.storeId.type === 'required'
                                                ? 'Por favor, preencha este campo!'
                                                : 'O ID contém pelo menos 30 caracteres!'}</small>}
                                </div>
                                <button className="btn btn-primary float-right" type="submit">Conectar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}