import React, { useEffect, useState } from 'react'
import { Alert, Media } from "react-bootstrap";

export default function AlertResponses(args) {
    const status = {
        success: {
            text: args.title,
            msg: args.message,
            variant: 'success',
            icon: 'check-circle-outline',
        },
        warning: {
            text: args.title,
            msg: args.message,
            variant: 'warning',
            icon: 'alert-outline',
        },
        error: {
            text: args.title,
            msg: args.message,
            variant: 'danger',
            icon: 'alert',
        },

    }

    const [alertType, setAlertType] = useState({});
    const [timer, setTimer] = useState(3000);

    useEffect(() => {
        if (args.type === 'success') setAlertType(status.success);
        if (args.type === 'error') setAlertType(status.error);
        if (args.type === 'warning') setAlertType(status.warning);
        setTimeout(() => {
            args.setNotify({ ...args.visible, visible: false });
        }, 6000)
    }, [args.visible])

    return (
        <Alert
            variant={alertType.variant}
            className="alert-dismissible left-icon-big"
            style={args.pageLogin === undefined ? styles.alert : {...styles.alert, position: 'absolute', bottom:0}}
            dismissible={true}
            show={args.visible}
        >
            <Media>
                <div variant="" className="alert-left-icon-big">
                    <span>
                        <i className={`mdi mdi-${alertType.icon}`}></i>
                    </span>
                </div>
                <Media.Body>
                    <h6 className="mt-1 mb-2">{alertType.text}</h6>
                    <p className="mb-0">{alertType.msg}</p>
                </Media.Body>
            </Media>
        </Alert>
    )
}

const styles = {
    alert: {
        position: 'fixed',
        bottom: '1rem',
        right: 0,
        zIndex: 1000,
        maxWidth: '400px'
    }
}