import React, { useState, useEffect, useRef, useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactPlayer from 'react-player'

/// Image
import { Dropdown, Button, Card } from "react-bootstrap";
import DefaultLoader from '../../components/Loader'
import { GetNewOrders, ChangeStatus } from '../../../services/OrdersServices'
import sound from "../../../sounds/order_alert.mp3";
import ModalComponent from '../../components/Modal'
import { Logout } from "../../../services/LoginServices";
import { useForm } from "react-hook-form";
import { UpdateStore, GetData } from '../../../services/ConfigurationsServices'
import AlertResponses from "../../components/Alert";
import Swal from "sweetalert2";

const Header = ({ onNote }) => {
  /*  var path = window.location.pathname.split("/");
   var name = path[path.length - 1].split("-");
   var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   var finalName = filterName.includes("app")
     ? filterName.filter((f) => f !== "app")
     : filterName.includes("ui")
       ? filterName.filter((f) => f !== "ui")
       : filterName.includes("uc")
         ? filterName.filter((f) => f !== "uc")
         : filterName.includes("basic")
           ? filterName.filter((f) => f !== "basic")
           : filterName.includes("jquery")
             ? filterName.filter((f) => f !== "jquery")
             : filterName.includes("table")
               ? filterName.filter((f) => f !== "table")
               : filterName.includes("page")
                 ? filterName.filter((f) => f !== "page")
                 : filterName.includes("email")
                   ? filterName.filter((f) => f !== "email")
                   : filterName.includes("ecom")
                     ? filterName.filter((f) => f !== "ecom")
                     : filterName.includes("chart")
                       ? filterName.filter((f) => f !== "chart")
                       : filterName.includes("editor")
                         ? filterName.filter((f) => f !== "editor")
                         : filterName;
  */

  // let location = useLocation();

  // const finalName = useCallback(() => {
  //   switch (true) {
  //     case /\/orders.*/.test(location.pathname):
  //       return 'Pedidos';
  //       break;
  //     case /\/products.*/.test(location.pathname):
  //       return 'Produtos';
  //       break;
  //     case /\/ecom-product-detail.*/.test(location.pathname):
  //       return 'Detalhes do Produto';
  //       break;
  //     default:
  //       return 'Dashboard';
  //       break;
  //   }
  // }, [location.pathname])

  const user = useRef(JSON.parse(localStorage.getItem('user')))
  const store = useRef({})
  const [isLoading, setIsLoading] = useState(true);
  const [newOrders, setNewOrders] = useState([]);
  const [newOrder, setNewOrder] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [fireSound, setFireSound] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [counter, setCounter] = useState(1)
  const variationName = useRef('')
  const variations = useRef(false)
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

  const onSubmit = (data) => {

    var form = new FormData();

    let obj = {
      from: data.from,
      to: data.to
    }
    let storeId = user.current.store_id;

    form.append('deliveryTime', JSON.stringify(obj));
    form.append('store', window.location.host);
    form.append('store_id', storeId);

    UpdateStore(storeId, form, setNotify)

  }

  const NewOrderDetails = ({ order }) => {

    return order.map((value, index) =>
      <Card className="mb-0 shadow-none" key={index}>
        <Card.Body className="p-3">
          <small className="d-block w-100 text-right">
            {new Date(value.data.created_at).toLocaleString('pt-BR')}
          </small>
          <h6 className="mb-1">Pedido #{value.id}:</h6>
          <p className="mb-1">{value.data.name}</p>
          {value.data.order.map((v, i) => {
            return (
              <div key={i} className="mb-3">
                <p className="text-primary mb-0" style={{ lineHeight: 1.5 }}>{v.quantity}x {variationName.current !== '' ? `${variationName.current} ${v.name}` : v.name}<br></br>
                  {
                    variations.current &&
                    v.variationData.map((vv, ii) => {
                      if (vv !== null && vv.group != 0) {
                        return (
                          <div key={ii} style={{ lineHeight: 1 }}>
                            <small>+ {vv.value.name}</small><br></br>
                          </div>
                        )
                      }

                    })
                  }
                </p>
              </div>
            )
          })}
          <div className="d-flex justify-content-between align-items-bottom">
            <Button className="i-false btn btn-sm btn-secondary" style={{ height: 50, alignSelf: 'flex-end' }}
              onClick={(e) => {

                Swal.fire({
                  title: 'Você tem certeza?',
                  text: "Deseja realmente recusar este pedido?",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: 'var(--primary)',
                  cancelButtonColor: 'var(--subtitles)',
                  confirmButtonText: 'Sim, recusar!',
                  cancelButtonText: "Cancelar"
                }).then((result) => {
                  if (result.isConfirmed) {

                    setCounter(state => state + 1)
                    ChangeStatus({ id: value.id, status: 5 }, setNotify)

                    if (order.length === counter) {
                      setNewOrders([])
                      setNewOrder([]);
                      setShowModal(false);
                    }
                  }
                })



              }}>
              <span>Recusar pedido</span>
            </Button>
            <Button className="i-false btn btn-primary"
              onClick={(e) => {

                e.target.innerHTML = 'Pedido recebido!'
                setCounter(state => state + 1)
                ChangeStatus({ id: value.id, status: 1 }, setNotify)

                if (order.length === counter) {
                  setNewOrders([])
                  setNewOrder([]);
                  setShowModal(false);
                  window.location.assign(`${window.location.origin}/order-details/${value.id}?print=1`)
                }

                /*  */
              }}>
              <span>Aceitar pedido</span>
            </Button>
          </div>

        </Card.Body>
      </Card >
    )
  }

  const modalProps = {
    title: 'Novo pedido',
    content: <NewOrderDetails order={newOrder} />,
    btnLabel: 'Salvar',
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  }

  const NewOrders = () => {
    setInterval(async () => {
      let response = await GetNewOrders(null, true);
      let arr = [];
      response.filter(value => Object.values(value.data.status)[1] === undefined && arr.push(value))

      if (arr.length > 0) {

        if (arr[0].data.order[0].variationData && arr[0].data.order[0].variationData.length > 0) {
          arr[0].data.order[0].variationData.filter(v => {
            if (v !== null && v.group == 0) {
              variationName.current = v.value.name;
            }


            if (v !== null && v.group != 0) {
              variations.current = true;
            }
          })

        }

        setFireSound(true);
        setNewOrder(arr);
        setShowModal(true);
      }
    }, 20000)
  }


  useEffect(() => {

    GetData(store, null, null, null, null)
      .finally(() => {
        if (user.current) {
          GetNewOrders(setNewOrders);
          //setIsLoading(false)
          NewOrders()
        }
      })


  }, [])

  return (
    <div className="header">
      <div className="header-content z-index999">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize", color: 'var(--titles)' }}
              >
                Dashboard
              </div>
            </div>
            {
              store &&
              <ul className="navbar-nav header-right">
                <Dropdown className="nav-item dropdown notification_dropdown" as="li">
                  <Dropdown.Toggle variant="light i-false sharp">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu" style={{ minWidth: '15rem' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <label className="pl-3 pt-2 mb-0">Tempo de entrega</label>
                      <div className="input-group p-2">
                        <input type="number" className="form-control text-black" placeholder="De:" {...register("from", { required: true })}
                          defaultValue={store.current.deliveryTime ? setValue('from', store.current.deliveryTime.from) : ''} />
                        <input type="number" className="form-control text-black" placeholder="À:" {...register("to", { required: true })}
                          defaultValue={store.current.deliveryTime ? setValue('to', store.current.deliveryTime.to) : ''} />
                      </div>
                      {errors.from &&
                        <small className="ml-2 text-danger">
                          {errors.from.type === 'required'
                            && 'Por favor, preencha todos os campos!'}</small>}
                      {errors.to &&
                        <small className="ml-2 text-danger">
                          {errors.to.type === 'required'
                            && 'Por favor, preencha todos os campos!'}</small>}
                      <small className="pl-3" style={{ color: 'var(--titles) !important' }}>OBS: Tempo em minutos</small>
                      <input type="submit" className="btn btn-block btn-primary mt-2" />
                    </form>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  className="nav-item dropdown notification_dropdown pl-1"
                  as="li"
                >
                  <Dropdown.Toggle
                    as="a"
                    variant=""
                    className="nav-link  ai-icon i-false"
                    href="#"
                    role="button"
                    data-toggle="dropdown"

                  >
                    <svg
                      width={28}
                      height={28}
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.75 14.8385V12.0463C21.7471 9.88552 20.9385 7.80353 19.4821 6.20735C18.0258 4.61116 16.0264 3.61555 13.875 3.41516V1.625C13.875 1.39294 13.7828 1.17038 13.6187 1.00628C13.4546 0.842187 13.2321 0.75 13 0.75C12.7679 0.75 12.5454 0.842187 12.3813 1.00628C12.2172 1.17038 12.125 1.39294 12.125 1.625V3.41534C9.97361 3.61572 7.97429 4.61131 6.51794 6.20746C5.06159 7.80361 4.25291 9.88555 4.25 12.0463V14.8383C3.26257 15.0412 2.37529 15.5784 1.73774 16.3593C1.10019 17.1401 0.751339 18.1169 0.75 19.125C0.750764 19.821 1.02757 20.4882 1.51969 20.9803C2.01181 21.4724 2.67904 21.7492 3.375 21.75H8.71346C8.91521 22.738 9.45205 23.6259 10.2331 24.2636C11.0142 24.9013 11.9916 25.2497 13 25.2497C14.0084 25.2497 14.9858 24.9013 15.7669 24.2636C16.548 23.6259 17.0848 22.738 17.2865 21.75H22.625C23.321 21.7492 23.9882 21.4724 24.4803 20.9803C24.9724 20.4882 25.2492 19.821 25.25 19.125C25.2486 18.117 24.8998 17.1402 24.2622 16.3594C23.6247 15.5786 22.7374 15.0414 21.75 14.8385ZM6 12.0463C6.00232 10.2113 6.73226 8.45223 8.02974 7.15474C9.32723 5.85726 11.0863 5.12732 12.9212 5.125H13.0788C14.9137 5.12732 16.6728 5.85726 17.9703 7.15474C19.2677 8.45223 19.9977 10.2113 20 12.0463V14.75H6V12.0463ZM13 23.5C12.4589 23.4983 11.9316 23.3292 11.4905 23.0159C11.0493 22.7026 10.716 22.2604 10.5363 21.75H15.4637C15.284 22.2604 14.9507 22.7026 14.5095 23.0159C14.0684 23.3292 13.5411 23.4983 13 23.5ZM22.625 20H3.375C3.14298 19.9999 2.9205 19.9076 2.75644 19.7436C2.59237 19.5795 2.50014 19.357 2.5 19.125C2.50076 18.429 2.77757 17.7618 3.26969 17.2697C3.76181 16.7776 4.42904 16.5008 5.125 16.5H20.875C21.571 16.5008 22.2382 16.7776 22.7303 17.2697C23.2224 17.7618 23.4992 18.429 23.5 19.125C23.4999 19.357 23.4076 19.5795 23.2436 19.7436C23.0795 19.9076 22.857 19.9999 22.625 20Z"
                        fill="#4C8147"
                      />
                    </svg>
                    <span className="badge light text-white bg-primary rounded-circle">
                      {newOrders.length}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right" className="mt-2">
                    <PerfectScrollbar className="widget-media dz-scroll p-3 height380 ps z-index999">
                      <ul className="timeline">
                        {newOrders.length > 0 ?
                          newOrders.map((value, index) => {
                            return (
                              <Link to={{ pathname: '/order-details/' + value.id }} key={index}>
                                <li>
                                  <div className="timeline-panel pb-0">
                                    <div className="media-body">
                                      <small className="d-block w-100 text-right">
                                        {new Date(value.data.created_at).toLocaleString('pt-BR')}
                                      </small>
                                      <span className="badge light text-white bg-primary badge-sm mb-2 ml-n1">
                                        Em andamento
                                      </span>
                                      <h6 className="mb-1">Pedido #{value.id}:</h6>
                                      <p className="mb-1">{value.data.name}</p>
                                      {/* {value.data.order.map(v => {
                                          return (
                                            <>
                                              <p className="text-primary mb-0">{v.quantity}x {variationName.current !== '' ? `${variationName.current} ${v.name}` : v.name}
                                                {
                                                  variations.current &&
                                                  v.variationData.map((vv, ii) => {
                                                    if (vv.group != 0) {
                                                      return (
                                                        <div key={ii} style={{ lineHeight: 1 }}>
                                                          <small>+ {vv.value.name}</small><br></br>
                                                        </div>
                                                      )
                                                    }

                                                  })
                                                }
                                              </p><br></br>
                                            </>
                                          )
                                        })}
 */}
                                      <hr></hr>
                                    </div>
                                  </div>
                                </li>
                              </Link>
                            )
                          })
                          :
                          <p>Nenhum pedido disponível no momento.</p>
                        }
                      </ul>
                      <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                        <div
                          className="ps__thumb-x"
                          tabIndex={0}
                          style={{ left: 0, width: 0 }}
                        />
                      </div>
                      <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                        <div
                          className="ps__thumb-y"
                          tabIndex={0}
                          style={{ top: 0, height: 0 }}
                        />
                      </div>
                    </PerfectScrollbar>
                    {/* <Link className="all-notification" to="#">
                        See all notifications <i className="ti-arrow-right" />
                      </Link> */}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="nav-item dropdown header-profile" as="li">
                  <Dropdown.Toggle
                    as="a"
                    to="#"
                    variant=""
                    className="nav-link  i-false p-0c-pointer pointr"
                  >
                    <img src={user.current && user.current.user_photo ? user.current.user_photo : ''} width={20} alt="profile" />
                    <div className="header-info">
                      <span className="text-black">
                        <strong>{user.current && user.current.first_name ? user.current.first_name : ''}</strong>
                      </span>
                      <p className="fs-12 mb-0">{user.current && user.current.superuser_level ? user.current.superuser_level === 1 ? 'Gerente' : 'Supervisor' : ''}</p>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right" className="mt-2">
                    <Link to={`/user-details/${user.current && user.current.user_id ? user.current.user_id : ''}`} className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ml-2">Perfil </span>
                    </Link>
                    <Link to="/email-inbox" className="dropdown-item ai-icon">
                      <svg
                        id="icon-inbox"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-success"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                        <polyline points="22,6 12,13 2,6" />
                      </svg>
                      <span className="ml-2">Mensagens </span>
                    </Link>
                    <Link to="javascript:void(0)" onClick={() => Logout()} className="dropdown-item ai-icon">
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ml-2">Sair </span>
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
                {
                  <ReactPlayer
                    url={sound}
                    playing={fireSound}
                    loop={true}
                    style={styles.player}
                  />
                }
              </ul>
            }
          </div>
          {showModal && <ModalComponent props={modalProps} />}
        </nav>
      </div>
      {
        notify.visible &&
        <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
      }
    </div>
  );
};

const styles = {
  player: {
    visibility: 'hidden',
    position: 'absolute'
  }

}

export default Header;
