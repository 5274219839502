import React, { Fragment, useEffect, useState } from "react";
//import { GetCustomizations } from './services/ConfigurationsServices'
import { api } from "./api/Middleware";
import { STORE_URL } from "./api/Urls";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";

/// Components
import Markup from "./jsx";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import { withResizeDetector } from "react-resize-detector";

export const AppSettings = {};
export const Theme = {};

const App = ({ width }) => {

  const [isLoading, setIsLoading] = useState(true);

  const override = css`
  position: fixed;
  width: 150px;
  display: block;
  margin: 0 auto;
  border-color: red;
  top: 50%;
  left: 0;
  right: 0;
`;

  const GetConfigs = async () => {

    try {
      let response = await api.get(`${STORE_URL}configs/`);
      if (response.status === 200) {
        let obj = response.data;
        Object.assign(AppSettings,
          {
            store: obj.store,
            logo: obj.logo.logo,
            moneyFormat: {
              minimumFractionDigits: 2, style: "currency", currency: "BRL"
            },
            facebookAppId: "4433810360015071",
            googlepAppId: "361008005922-b8p6jfm6pt6e06onun3mqct2dtdldbg0.apps.googleusercontent.com",
            hereApiKey: "-qm6pEDnHjBi5jzKNtCdp6KTpmyx4qyRhD-dYij5LVg",
            mapBoxApiKey: "pk.eyJ1Ijoic2VyZ2lvemFuaW5vdHRpIiwiYSI6ImNrOXJsdnN5YzB2cHAzZG8zd2thcmd2bzEifQ.sZA22f03xHvpGceZtbxc3A"
          }
        )

        Object.assign(Theme, {
          colors: {
            id: obj.colors.id,
            background: obj.colors.colors.background,
            primary: obj.colors.colors.primary,
            secondary: obj.colors.colors.secondary,
            tertiary: obj.colors.colors.tertiary,
            titles: obj.colors.colors.titles,
            secondary: obj.colors.colors.subtitles,
            success: "#10ac84",
            value: obj.colors.colors.value,
          },
        })

        document.documentElement.style.setProperty('--background', obj.colors.colors.background);
        document.documentElement.style.setProperty('--primary', obj.colors.colors.primary);
        document.documentElement.style.setProperty('--secondary', obj.colors.colors.secondary);
        document.documentElement.style.setProperty('--tertiary', obj.colors.colors.tertiary);
        document.documentElement.style.setProperty('--titles', obj.colors.colors.titles);
        document.documentElement.style.setProperty('--subtitles', obj.colors.colors.subtitles);
        document.documentElement.style.setProperty('--success', '#10ac84');
      }

    } catch (error) {
      console.log(error)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    GetConfigs()
  }, [])

  const body = document.querySelector("body");
  body.setAttribute("data-typography", "poppins");
  body.setAttribute("data-theme-version", "light");
  body.setAttribute("data-layout", "vertical");
  body.setAttribute("data-nav-headerbg", "color_1");
  body.setAttribute("data-headerbg", "color_1");
  body.setAttribute("data-sidebar-style", "full");
  body.setAttribute("data-sibebarbg", "color_1");
  body.setAttribute("data-primary", "color_1");
  body.setAttribute("data-sidebar-position", "fixed");
  body.setAttribute("data-header-position", "fixed");
  body.setAttribute("data-container", "wide");
  body.setAttribute("direction", "ltr");

  width >= 768 && width < 1280
    ? body.setAttribute("data-sidebar-style", "mini")
    : width <= 768
      ? body.setAttribute("data-sidebar-style", "overlay")
      : body.setAttribute("data-sidebar-style", "full");

  return (
    <Fragment>
      {
        isLoading ? <BarLoader color="var(--primary)" loading={isLoading} size={150} css={override} /> : <Markup />
      }

    </Fragment>
  );
};

export default withResizeDetector(App);
