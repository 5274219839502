import React, { useState } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from 'date-fns/locale/pt-BR'

function BasicDateAndTimePicker({props}) {
   const [selectedDate, handleDateChange] = useState(new Date());
   
   return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        { <DateTimePicker
            label=""
            inputVariant="outlined"
            value={props.date === undefined ? selectedDate : props.date}
            onChange={handleDateChange}
            style={{width: '100%'}}
            format="dd/MM/yyyy HH:mm"
            name={props.name}
         />}
      </MuiPickersUtilsProvider>
   );
}

export default BasicDateAndTimePicker;
