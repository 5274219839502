import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import DataTablev2 from '../jsx/components/Datatable'
import { Get, ChangeStatus, Delete } from '../services/OrdersServices'
import { Hash } from '../helpers/Helpers'
import NumberFormat from 'react-number-format';
import DefaultLoader from '../jsx/components/Loader'
import Swal from "sweetalert2";
import AlertResponses from '../jsx/components/Alert'
import ErrorsComponent from '../jsx/components/Errors'

export default function Orders() {

  const [orders, setOrders] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [deliveryStatus, setDeliveryStatus] = useState('')

  //TODO: no datatable nao filtra pelo campo created_at por causa do formato da data q no campo é 2021-08-03 e o user
  // digita 30/08/2021
  useEffect(() => {
    Get(setOrders, setErrors, setIsLoading);
  }, [])

  const deleteRows = (state) => {
    if (state.selectedRows !== (null || undefined) && state.selectedRows.length > 0) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  }

  const status = {
    0: {
      id: 0,
      label: "Criado",
      type: "light"
    },
    1: {
      id: 1,
      label: "Recebido",
      type: "light"
    },
    2: {
      id: 2,
      label: "Em andamento",
      type: "info"
    },
    3: {
      id: 3,
      label: "Saiu para entrega",
      type: "primary"
    },
    4: {
      id: 4,
      label: "Finalizado",
      type: "success"
    },
    5: {
      id: 5,
      label: "Cancelado",
      type: "danger"
    },
    null: {
      id: 6,
      label: "Indefinido",
      type: "dark"
    },
  }

  const BadgeComponentMemo = (row) => {

    return useMemo(() => {
      return (
        <DropdownButton
          variant=''
          style={styles.dropdown}
          title={status[row.status[0].id].label}
          className={`badge badge-${status[row.status[0].id].type} badge-sm light badge-delivery-status badge-${row.id_order}`}
          onSelect={(e) => {
            ChangeStatus({ id: row.id_order, status: e }, setNotify).then(res => {
              if (res) {
                document.querySelector(`.badge-${row.id_order}`).classList.remove(`badge-${status[row.status[0].id].type}`)
                document.querySelector(`.badge-${row.id_order}`).classList.add(`badge-${status[e].type}`)
                document.querySelector(`.badge-${row.id_order} button`).innerText = status[e].label
              }
            })

          }}
        >
          {Object.values(status).map((value, i) => /* i !== 5 && */ i !== 6 &&
            <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
        </DropdownButton>
      )
    }, [deliveryStatus])
  }

  const columns = [
    {
      name: 'Nº',
      selector: (row) => <Link to={{
        pathname: `/order-details/${row.id_order}`,
        order: row,
      }}>{row.id_order}</Link>,
      maxWidth: '5%'
    },
    {
      name: '',
      selector: (row) => row.photo !== null && row.photo !== '' ? row.photo : <div style={styles.photoDefault}><i className="ti-user" style={styles.i}></i></div>,
      maxWidth: '5%'
    },
    {
      name: 'Cliente',
      selector: (row) => <Link to={{
        pathname: `/order-details/${row.id_order}`,
        order: row,
      }}>{row.name}</Link>,
      sortable: true,
      maxWidth: '27.5%'
    },
    {
      name: 'Pagamento',
      selector: (row) => row.payment_method.name,
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Total',
      selector: (row) =>
        <NumberFormat
          displayType="text"
          decimalScale={2}
          defaultValue={row.total}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$"
          fixedDecimalScale={true}
        />,
      maxWidth: '10%'
    },
    {
      name: 'Criado',
      selector: (row) => {
        let date = new Date(row.created_at);
        return date.toLocaleString()
      },
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Status',
      selector: (row) => BadgeComponentMemo(row),
      sortable: true,
      maxWidth: '17.5%',
      allowOverflow: true
    },
    /*  {
       name: 'Ações',
       selector: (row) => actions(row),
       maxWidth: '5%',
       allowOverflow: true
     }, */
  ];

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
            {
              errors !== null && <ErrorsComponent args={errors} />
            }
            <div className="row mt-4">
              <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                <div className="col-xl-4 col-xxl-5 col-sm-12">
                  <h2>Pedidos</h2>
                </div>

                <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                  {showDeleteButton &&

                    <Button className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() =>
                        Swal.fire({
                          title: "Você tem certeza?",
                          text:
                            "Todos os arquivos selecionados serão deletados. Esta ação é irreversível!",
                          icon: "warning",
                          buttons: true,
                          confirmButtonText: `Continuar a ação`,
                          cancelButtonText: `Cancelar`,
                          showCancelButton: true,
                          confirmButtonColor: 'red'
                        }).then((value) => {
                          if (value) Delete(selectedRows, setNotify)
                        })
                      }
                    >
                      <span>DELETAR SELECIONADOS</span>
                    </Button>
                  }
                  {/*   <Button className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                    href="/ecom-product-detail">
                    <span>NOVO PRODUTO</span>
                  </Button> */}
                </div>

              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }

            <DataTablev2
              data={orders}
              columns={columns}
              title="  "
              selectable={true}
              onSelectedRowsChange={deleteRows}
              showDeleteButton={showDeleteButton}
              filterByField={{ labels: ['Nome', 'Criado em'], fields: ['name', 'created_at'] }} />
          </>
      }
    </>
  );
}

const styles = {
  photoDefault: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    background: '#efefef'
  },
  i: {
    fontSize: '18px',
    color: '#3d4465'
  },
  dropdown: {
    padding: '5px 15px',
    fontSize: '12px'
  }
}