import React, { useState, useEffect } from 'react'
import { ProgressBar } from 'react-bootstrap'

export default function ProgressBarComponent() {

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                /* if (oldProgress === 100) {
                    return 0;
                } */
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <ProgressBar
            now={progress}
            variant="primary"
            className='m-3'
            style={styles.progress}
        />
    )
}

const styles = {
    progress: {
        position: 'fixed',
        bottom: 0,
        width: '85%',
        zIndex: '1000'
    }
}