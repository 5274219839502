import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import DataTablev2 from '../jsx/components/Datatable'
import { GetAll, Delete, ChangeStatus } from '../services/CustomersServices'
import { Hash } from '../helpers/Helpers'
import NumberFormat from 'react-number-format';
import Swal from "sweetalert2";
import DefaultLoader from '../jsx/components/Loader'
import AlertResponses from '../jsx/components/Alert'
import ProgressBarComponent from '../jsx/components/ProgressBar'

export default function GeneralCustomers() {

  const [users, setUsers] = useState('');
  const [erros, setErros] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    GetAll(setUsers, setErros, setIsLoading);
  }, [])


  const deleteRows = (state) => {
    if (state.selectedRows !== (null || undefined) && state.selectedRows.length > 0) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  }

  const status = {
    0: {
      id: 0,
      label: "Desativado",
      type: "light"
    },
    1: {
      id: 1,
      label: "Ativo",
      type: "success"
    },
  }

  const BadgeComponentMemo = (row) => {
    return useMemo(() => {
      return (
        <DropdownButton
          variant=''
          style={styles.dropdown}
          title={status[row.is_active].label}
          className={`badge badge-${status[row.is_active].type} badge-sm light badge-delivery-status badge-${row.id}`}
          onSelect={(e) => {
            ChangeStatus({ id: row.id, status: e }, setNotify, setProgress).then(res => {
              if (res) {
                document.querySelector(`.badge-${row.id}`).classList.remove(`badge-${status[row.is_active].type}`)
                document.querySelector(`.badge-${row.id}`).classList.add(`badge-${status[e].type}`)
                document.querySelector(`.badge-${row.id} button`).innerText = status[e].label
              }
            })

          }}
        >
          {Object.values(status).map((value, i) => i !== 5 &&
            <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
        </DropdownButton>
      )
    }, [users])
  }

  const columns = [
    {
      name: '#',
      selector: (row) => row.photo !== null && row.photo !== '' ? <img src={Hash('100x100', '/images/users/' + row.photo.split('/').pop(), null, true)} style={styles.photoDefault} /> : <div style={styles.photoDefault}><i className="ti-user" style={styles.i}></i></div>,
      sortable: true,
      maxWidth: '5%'
    },
    {
      name: 'Nome',
      selector: (row) => <Link to={{ pathname: `/customer-details/${row.id}` }}>{row.name}</Link>,
      sortable: true,
      maxWidth: '20%%'
    },
    {
      name: 'CPF',
      selector: (row) => <NumberFormat displayType="text" format="###.###.###-##" value={row.cpf} />,
      sortable: true,
      maxWidth: '12.5%'
    },
    {
      name: 'Telefone',
      selector: (row) => <NumberFormat displayType="text" format="(##) #####-####" value={row.phone} />,
      sortable: true,
      maxWidth: '12.5%'
    },
    {
      name: 'E-mail',
      selector: (row) => row.email,
      maxWidth: '27.5%'
    },
    {
      name: 'Criado em',
      selector: (row) => new Date(row.created_at).toLocaleDateString('pt-BR'),
      maxWidth: '10%'
    },
    {
      name: 'Status',
      selector: (row) => BadgeComponentMemo(row),
      sortable: true,
      maxWidth: '12.5%',
      allowOverflow: true
    },
  ];

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
            <div className="row mt-4">
              <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                <div className="col-xl-4 col-xxl-5 col-sm-12">
                  <h2>Clientes</h2>
                </div>

                <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                  {showDeleteButton &&

                    <Button className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() =>
                        Swal.fire({
                          title: "Você tem certeza?",
                          text:
                            "Todos os usuários selecionados serão deletados. Esta ação é irreversível!",
                          icon: "warning",
                          buttons: true,
                          confirmButtonText: `Continuar a ação`,
                          cancelButtonText: `Cancelar`,
                          showCancelButton: true,
                          confirmButtonColor: 'red'
                        }).then((value) => {
                          if (value) Delete(selectedRows, setNotify, setProgress)
                        })
                      }
                    >
                      <span>DELETAR SELECIONADOS</span>
                    </Button>
                  }
                  {/*  <Button className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                    href="/ecom-product-detail">
                    <span></span>
                  </Button> */}
                </div>

              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }

            <DataTablev2
              data={users}
              columns={columns}
              title="  "
              onSelectedRowsChange={deleteRows}
              showDeleteButton={showDeleteButton}
              filterByField={{ labels: ['Nome'], fields: ['name'] }}
            />
            {progress && <ProgressBarComponent />}
          </>
      }
    </>
  );
}

const styles = {
  photoDefault: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    background: '#efefef'
  },
  i: {
    fontSize: '18px',
    color: '#3d4465'
  },
}