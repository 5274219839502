import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";
import { IMAGES_URL } from "../../../api/Urls";
import { Hash } from "../../../helpers/Helpers";

/// images
import logo from "../../../images/logo.svg";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header">
         <Link to="/" className="brand-logo pr-0">
            <img className="logo-abbr" src={Hash('100x100',`customizations/logo.png`)} alt="" />
            {/* <img className="logo-compact" src={logoText} alt="" /> */}
            <h4 className="brand-title mt-3" style={{fontWeight: '600'}}>General Pepper</h4>
            {/* <img  src={logoText} alt="" /> */}
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
