import React from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom";
import { UserProvider } from '../contexts/UserContext'
import { GetToken, VerifyIfTokenIsExpired } from '../api/Middleware'

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Login from "../screens/Login";
import Home from "../screens/Home";
import Orders from "../screens/Orders";
import OrderId from "../screens/OrderId";
import GeneralCustomers from "../screens/GeneralCustomers";
import CustomerDetails from "../screens/CustomerDetails";
import Analytics from "../screens/Analytics";
import Reviews from "./components/Dashboard/Reviews";
import Categories from '../screens/Categories'
import CategorieDetails from "../screens/CategorieDetails";
import Promotions from '../screens/Promotions'
import Products from '../screens/Products'
import ProductDetail from "../screens/ProductDetail";
import PromotionDetails from '../screens/PromotionDetails'
import Coupons from '../screens/Coupons'
import CouponDetails from '../screens/CouponDetails'
import PaymentMethods from '../screens/PaymentMethods'
import PaymentMethodsDetails from "../screens/PaymentMethodsDetails";
import Users from "../screens/Users";
import UserDetails from "../screens/UserDetails";
import Customizations from "../screens/Customizations";
import DeliveryFee from "../screens/DeliveryFee";
import Variations from "../screens/Variations";
import VariationDetails from "../screens/VariationDetails";
import IfoodIntegration from "../screens/IfoodIntegration";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Read from "./components/AppsMenu/Email/Read/Read";

const Markup = () => {

  const routes = [

    /// Dashboard
    { url: "", component: Home },
    { url: "login", component: Login },
    { url: "orders", component: Orders },
    { url: "order-details/:id", component: OrderId },
    { url: "analytics", component: Analytics },
    { url: "review", component: Reviews },
    { url: "variations", component: Variations },
    { url: "variation-details", component: VariationDetails },
    { url: "variation-details/:id", component: VariationDetails },
    { url: "products", component: Products },
    { url: "categories", component: Categories },
    { url: "categorie-details", component: CategorieDetails },
    { url: "categorie-details/:id", component: CategorieDetails },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-detail/:id", component: ProductDetail },
    { url: "promotions", component: Promotions },
    { url: "promotion-details", component: PromotionDetails },
    { url: "promotion-details/:id", component: PromotionDetails },
    { url: "general-customers", component: GeneralCustomers },
    { url: "customer-details/:id", component: CustomerDetails },
    { url: "coupons", component: Coupons },
    { url: "coupon-details", component: CouponDetails },
    { url: "coupon-details/:id", component: CouponDetails },
    { url: "payment-methods", component: PaymentMethods },
    { url: "payment-method-details", component: PaymentMethodsDetails },
    { url: "payment-method-details/:id", component: PaymentMethodsDetails },
    { url: "users", component: Users },
    { url: "user-details", component: UserDetails },
    { url: "user-details/:id", component: UserDetails },
    { url: "delivery-fee", component: DeliveryFee },
    { url: "customizations", component: Customizations },
    //{ url: "delivery-fee-details/:id", component: UserDetails },
    { url: "ifood-integration", component: IfoodIntegration },

    { url: "email-inbox", component: Inbox },
    { url: "email-compose", component: Compose },
    { url: "email-read", component: Read },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("login");

  return (
    <Router basename="/">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"} ${!path ? "right-profile" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: '100vh' }}
          >
            <UserProvider>
              <Switch>
                {routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}
              </Switch>
            </UserProvider>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </Router>
  );
};

export default Markup;
