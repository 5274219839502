import { api } from '../api/Middleware'
import { STORE_URL, CUSTOMIZATIONS_URL, PLACES_API } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'
import { AppSettings } from '../App'


export const GetData = async (store, setValue, setNotify, customizations) => {
    try {
        let response = await api.get(STORE_URL);

        if (response.status === 200) {
            if (response.data.length > 0) {
                store.current = response.data[0]
                if (setValue !== null) {
                    setValue('phone', response.data[0].phone);
                    setValue('cellPhone', response.data[0].cellPhone);
                    setValue('monday', response.data[0].monday === 'Fechado' ? '' : response.data[0].openingHours.monday);
                    setValue('tuesday', response.data[0].tuesday === 'Fechado' ? '' : response.data[0].openingHours.tuesday);
                    setValue('wednesday', response.data[0].wednesday === 'Fechado' ? '' : response.data[0].openingHours.wednesday);
                    setValue('thursday', response.data[0].thursday === 'Fechado' ? '' : response.data[0].openingHours.thursday);
                    setValue('friday', response.data[0].friday === 'Fechado' ? '' : response.data[0].openingHours.friday);
                    setValue('saturday', response.data[0].saturday === 'Fechado' ? '' : response.data[0].openingHours.saturday);
                    setValue('sunday', response.data[0].sunday === 'Fechado' ? '' : response.data[0].openingHours.sunday);
                }

            }
        }

    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.configurations.getStore, type: 'error', visible: true });
    }

    if (customizations !== null) {
        try {
            let response = await api.get(CUSTOMIZATIONS_URL);
            if (response.status === 200) {
                customizations.current = response.data[0]
            }

        } catch (error) {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.configurations.getCustomizations, type: 'error', visible: true });
        }
    }

}


export const CreateStore = async (data, setNotify) => {
    try {
        let response = await api.post(STORE_URL, data);
        if (response.status === 201) {
            setNotify({ title: 'Sucesso!', message: 'Informações cadastradas com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }

}

export const UpdateStore = async (id, data, setNotify) => {
    try {
        let response = await api.put(STORE_URL + id + '/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Dados atualizados com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }
}

export const CreateCustomization = async (data, setNotify) => {
    try {
        let response = await api.post(CUSTOMIZATIONS_URL, data);
        if (response.status === 200 || response.status === 201) {
            setNotify({ title: 'Sucesso!', message: 'Configuração cadastradas com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }

}

export const GetCustomizations = async (customizations) => {
    try {
        let response = await api.get(CUSTOMIZATIONS_URL);
        if (response.status === 200) {
            return response.data[0]
        }

    } catch (error) {

    }

}

export const UpdateCustomizations = async (id, data, setNotify) => {
    try {
        let response = await api.put(CUSTOMIZATIONS_URL + id + '/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Dados atualizados com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.configurations.updateCustomizations, type: 'error', visible: true });
    }
}

export const FindPlace = async (text, setNotify) => {
    try {
        let response = await api.get(`${PLACES_API}&lang=pt&in=countryCode:BRA&limit=5&apiKey=${AppSettings.hereApiKey}&q=${text}`);
        //let response = await api.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?country=br&proximity=ip&types=place,poi,postcode%2Cpostcode%2Caddress&language=pt&access_token=${AppSettings.mapBoxApiKey}`);

        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        console.log(error)
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
        if (error.response.status !== 401) {
            //return Alert.alert('Oops!', ERROR_MESSAGES.generic);
        }
    }
}