import { IMAGES_URL,IMAGES_BASE_URL,THUMBOR_URL } from '../api/Urls'
import CryptoES from 'crypto-es';

export const Hash = (size, url, external, baseUrl) => {
    let hash = CryptoES.HmacSHA1(`${size}/smart/${external ? url : baseUrl ? IMAGES_BASE_URL + url : IMAGES_URL + url}`, "3Start3Seu6Delivery3")
        .toString(CryptoES.enc.Base64).replace(/\//g, '_').replace(/\+/g, '-');

    let hashed = THUMBOR_URL + hash + `/${size}/smart/${external ? url : baseUrl ? IMAGES_BASE_URL + url : IMAGES_URL + url}`;
    return hashed;
}

export const DateTimeToISOFormat = (dateTimeString) => {
    let [date, time] = dateTimeString.split(' ');

    let [DD, MM, YYYY] = date.split('/');

    let [HH, mm] = time.split(':');
    return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
}

export const RemoveAccents = (string) => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
}
