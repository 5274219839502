import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import ActivityLineChart from "../jsx/components/Sego/Analytics/ActivityLineChart";
import ThreeCharts from '../jsx/components/Charts/ThreeCharts'
import TwoCharts from '../jsx/components/Charts/TwoCharts'
import CountUp from "react-countup";
import { GetOrders, GetUsers, GetPayments, GetBillingAndOrders } from '../services/AnalyticsServices'
import DefaultLoader from '../jsx/components/Loader'
import AlertResponses from '../jsx/components/Alert'
import ErrorsComponent from '../jsx/components/Errors'
import NumberFormat from 'react-number-format';

// Svg
import IcStat3 from "../images/svg/ic_stat3.svg";
import { Hash } from "../helpers/Helpers";


export default function Analytics() {

  const [orders, setOrders] = useState(null);
  const [users, setUsers] = useState(null);
  const [payments, setPayments] = useState(null);
  const [billingAndOrders, setBillingAndOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  let ordersCounter = 1;
  const usersTotal = useRef([]);
  const usersTotalByMonth = useRef([]);
  const usersMonth = useRef([]);

  const billingTotal = useRef([]);
  const billingTotalByMonth = useRef([]);
  const billingMonths = useRef([]);

  const ordersTotal = useRef([]);
  const ordersTotalByMonth = useRef([]);
  const ordersMonths = useRef([]);
  const mediumPriceByMonth = useRef([]);
  const mediumPriceByAllMonths = useRef([]);

  const creditCardValues = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const debitCardValues = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const cashValues = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const months = {
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dez"
  };

  useEffect(() => {
    GetOrders(setOrders, setErrors);
    GetUsers(setUsers, setErrors);
    GetPayments(setPayments, setErrors);
    GetBillingAndOrders(setBillingAndOrders, setErrors);
  }, [])


  useEffect(() => {
    if (orders !== null && users !== null && payments !== null && billingAndOrders !== null) {

      payments.map(value => {

        if (value.operation === 'Crédito') {
          creditCardValues.current[value.month - 1] = parseFloat(value.billing_by_month.replace(',', '.'))

        }
        if (value.operation === 'Débito') {
          debitCardValues.current[value.month - 1] = parseFloat(value.billing_by_month.replace(',', '.'))
        }
        if (value.operation === 'Dinheiro') {
          cashValues.current[value.month - 1] = parseFloat(value.billing_by_month.replace(',', '.'))
        }
      })

      /* Users */
      let uTotal = [];
      let uMonth = [];
      users.map(value => {
        uTotal.push(value.users_total)
        uMonth.push(months[value.month])
      })

      usersTotalByMonth.current.push(uTotal);
      usersMonth.current.push(uMonth);
      usersTotal.current = uTotal.reduce((prev, current) => parseInt(prev) + parseInt(current));
      /* End */

      /* Total billing */
      let bTotal = [];
      let bMonth = [];
      let mPrice = [];
      let oTotal = [];
      let oMonth = [];
      billingAndOrders.map(value => {
        bTotal.push(value.billing_by_month)
        bMonth.push(months[value.month])
        mPrice.push(parseFloat(value.medium_price_by_month.replace(',', '.')))
        oTotal.push(value.orders_total)
        oMonth.push(months[value.month])
      })
      billingTotalByMonth.current.push(bTotal);
      billingMonths.current.push(bMonth);
      billingTotal.current = bTotal.length === 0 ? 0 : bTotal.reduce((prev, current) => parseInt(prev) + parseInt(current));
      /*  */
      
    
      /* Calculo do ticke medio feito: soma do ticket médio mensal dividido pelo total de meses */
      mediumPriceByMonth.current.push(mPrice)
      mediumPriceByAllMonths.current = mPrice.length === 0 ? 0 : mPrice.reduce((p, c) => (p && c) !== (0 || null) && (p + c) / billingAndOrders.length)
      ordersTotalByMonth.current.push(oTotal);
      ordersMonths.current.push(oMonth);
      ordersTotal.current = oTotal.length === 0 ? 0 :  oTotal.reduce((prev, current) => parseInt(prev) + parseInt(current));

      setIsLoading(false)
    }
  }, [orders, users, payments, billingAndOrders])

  return (

    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <div>
            {
              errors !== null && <ErrorsComponent args={errors} />
            }
            <div className="d-sm-flex d-block">
              <p className="fs-18 mr-auto mb-sm-4 mb-3">
                Relatórios
            </p>
             
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header border-0 pb-0 d-sm-flex d-block">
                        <div>
                          <h4 className="card-title mb-1">Pedidos</h4>
                          {/*  <small className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur
                    </small> */}
                        </div>

                      </div>
                      <div className="card-body revenue-chart px-3 pb-0">
                        <div className="d-flex align-items-end pl-3">
                          <div className="mr-4">
                            <h3 className="font-w600 mb-0">
                              <img
                                src={IcStat3}
                                height={22}
                                width={22}
                                className="mr-2 mb-1"
                                alt="IcStat3"
                              />
                              <CountUp start={1} end={usersTotal.current} duration={5} suffix="" />
                            </h3>
                            <small className="text-dark fs-14">Total de pedidos</small>
                          </div>
                          <div>
                      <h3 className="font-w600 mb-0">
                        <img
                          src={IcStat3}
                          height={22}
                          width={22}
                          className="mr-2 mb-1"
                          alt="IcStat3"
                        />
                        <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={mediumPriceByAllMonths.current}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />
                      </h3>
                      <small className="text-dark fs-14">
                        Ticket médio total
                      </small>
                    </div>
                        </div>
                      
                        <TwoCharts
                          label1="Total de pedidos"
                          label2="Ticket médio"
                          data1={ordersTotalByMonth.current[0]}
                          data2={mediumPriceByMonth.current[0]}
                          categories={ordersMonths.current[0]}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header border-0 pb-0 d-sm-flex d-block">
                        <div>
                          <h4 className="card-title mb-1">Faturamento</h4>
                          {/*  <small className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur
                    </small> */}
                        </div>

                      </div>
                      <div className="card-body revenue-chart px-3 pb-0">
                        <div className="d-flex align-items-end pl-3">
                          <div className="mr-4">
                            <h3 className="font-w600 mb-0">
                              <img
                                src={IcStat3}
                                height={22}
                                width={22}
                                className="mr-2 mb-1"
                                alt="IcStat3"
                              />
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={billingTotal.current}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />
                            </h3>
                            <small className="text-dark fs-14">Faturamento total</small>
                          </div>

                        </div>
                        <ActivityLineChart data={billingTotalByMonth.current} categories={billingMonths.current} title="Faturamento do mês" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header border-0 pb-0 d-sm-flex d-block">
                        <div>
                          <h4 className="card-title mb-1">Pagamento</h4>
                          <small>Relatório de recebimento por cada meio de pagamento</small>
                          {/*  <small className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur
                    </small> */}
                        </div>

                      </div>
                      <div className="card-body revenue-chart px-3 pb-0">
                        <div className="d-flex align-items-end pl-3">
                          <div className="mr-4">
                            <h3 className="font-w600 mb-0">
                              <img
                                src={IcStat3}
                                height={22}
                                width={22}
                                className="mr-2 mb-1"
                                alt="IcStat3"
                              />
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={cashValues.current.reduce((p, c) => (p && c) !== (0 || null) && p + c)}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />
                            </h3>
                            <small className="text-dark fs-14">Dinheiro</small>
                          </div>
                          <div className="mr-4">
                            <h3 className="font-w600 mb-0">
                              <img
                                src={IcStat3}
                                height={22}
                                width={22}
                                className="mr-2 mb-1"
                                alt="IcStat3"
                              />
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={debitCardValues.current.reduce((p, c) => (p && c) !== (0 || null) && p + c)}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />

                            </h3>
                            <small className="text-dark fs-14">Cartão de débito</small>
                          </div>
                          <div className="mr-4">
                            <h3 className="font-w600 mb-0">
                              <img
                                src={IcStat3}
                                height={22}
                                width={22}
                                className="mr-2 mb-1"
                                alt="IcStat3"
                              />
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={creditCardValues.current.reduce((p, c) => (p && c) !== (0 || null) && p + c)}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />
                            </h3>
                            <small className="text-dark fs-14">Cartão de crédito</small>
                          </div>
                          {/* <div>
                      <h3 className="font-w600 mb-0">
                        <img
                          src={IcStat3}
                          height={22}
                          width={22}
                          className="mr-2 mb-1"
                          alt="IcStat3"
                        />
                        <CountUp start={1} end={1245} duration={5} />
                      </h3>
                      <small className="text-dark fs-14">
                        Avg. Sales per day
                      </small>
                    </div> */}
                        </div>
                        <ThreeCharts
                          label1="Total no dinheiro"
                          label2="Total no débito"
                          label3="Total no crédito"
                          data1={creditCardValues.current}
                          data2={debitCardValues.current}
                          data3={cashValues.current}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header border-0 pb-0 d-sm-flex d-block">
                        <div>
                          <h4 className="card-title mb-1">Clientes</h4>
                          {/*  <small className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur
                    </small> */}
                        </div>

                      </div>
                      <div className="card-body revenue-chart px-3 pb-0">
                        <div className="d-flex align-items-end pl-3">
                          <div className="mr-4">
                            <h3 className="font-w600 mb-0">
                              <img
                                src={IcStat3}
                                height={22}
                                width={22}
                                className="mr-2 mb-1"
                                alt="IcStat3"
                              />
                              <CountUp start={1} end={usersTotal.current} duration={5} suffix="" />
                            </h3>
                            <small className="text-dark fs-14">Total de clientes</small>
                          </div>
                          {/* <div>
                      <h3 className="font-w600 mb-0">
                        <img
                          src={IcStat3}
                          height={22}
                          width={22}
                          className="mr-2 mb-1"
                          alt="IcStat3"
                        />
                        <CountUp start={1} end={1245} duration={5} />
                      </h3>
                      <small className="text-dark fs-14">
                        Avg. Sales per day
                      </small>
                    </div> */}
                        </div>
                        <ActivityLineChart data={usersTotalByMonth.current} categories={usersMonth.current} title="Novos clientes no mês" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">

                      <div className="card-header border-0 pb-0 d-sm-flex d-block">
                        <div>
                          <h4 className="card-title mb-1">Produtos mais vendidos</h4>
                          <small className="mb-0">
                            Todo o período
                     </small>
                        </div>

                      </div>
                      <div className="card-body p-0 pt-3">
                        {
                          orders !== null &&
                          orders.map((item, i) =>
                            <div className="media items-list-1 pb-3 pt-2" key={i}>
                              <span className="number col-1 px-0 align-self-center mr-3">
                                #{ordersCounter++}

                              </span>
                              <Link to="ecom-product-detail">
                                <img
                                  className="img-fluid rounded mr-3"
                                  width={85}
                                  src={Hash('100x100', item.image)}
                                />
                              </Link>
                              <div className="media-body col-sm-4 col-xxl-5 px-0">
                                <h5 className="mt-0 mb-3">
                                  <Link className="text-black" to="ecom-product-detail">
                                    {item.name}
                                  </Link>
                                </h5>
                                <small className="font-w500">
                                  <strong className="text-secondary mr-2">
                                    <NumberFormat
                                      displayType="text"
                                      defaultValue={item.price.replace('.', ',')}
                                      decimalSeparator=","
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      prefix="R$"
                                    /></strong>
                                </small>
                              </div>
                              <div className="media-footer ml-auto col-sm-3 mt-sm-0 mt-3 px-0 d-flex align-self-center align-items-center">
                                <div className="mr-3">
                                  <span
                                    className="peity-success"
                                    data-style="width:100%;"
                                    style={{ display: "none" }}
                                  >
                                    0,2,1,4
                                        </span>
                                  <svg className="peity" height={30} width={47}>
                                    <polygon
                                      fill="rgba(48, 194, 89, .2)"
                                      points="0 28.5 0 28.5 15.666666666666666 15 31.333333333333332 21.75 47 1.5 47 28.5"
                                    />
                                    <polyline
                                      fill="none"
                                      points="0 28.5 15.666666666666666 15 31.333333333333332 21.75 47 1.5"
                                      stroke="#30c259"
                                      strokeWidth={3}
                                      strokeLinecap="square"
                                    />
                                  </svg>
                                </div>
                                <div>
                                  <h3 className="mb-0 font-w600 text-black">{item.quantity}</h3>
                                  <span className="fs-14">Vendas</span>
                                </div>
                              </div>
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }
          </div>
      }
    </>
  );
}