import BeatLoader from "react-spinners/BeatLoader";
import { Theme } from '../../../AppSettings';
import { css } from "@emotion/react";

export default function DefaultLoader({isLoading, color}) {

const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  ${color !== undefined  ? '' : 'margin-top: 20%;'}
`;

    return (
        <BeatLoader color={color !== undefined ? color : "var(--primary)"} loading={isLoading} css={override} size={12} />
    )
}