import { api } from '../api/Middleware'
import { IFOOD_AUTH_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const Auth = async (data) => {
   
    try {
        let response = await api.post(IFOOD_AUTH_URL, data);
        if (response.status === 200) {
            alert('sussexo')
            console.log(response)
        }

    } catch (error) {
        alert('erro')
       
        console.log(error)
        console.log(error.response)
    }
   
} 