import { api } from '../api/Middleware'
import { VW_ITEMS, PRODUCTS_URL, CATEGORIES_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetOne = async (id, setProduct) => {
    try {
        let response = await api.get(PRODUCTS_URL + id + '/');
        if (response.status === 200) setProduct(response.data);
    } catch (error) {
        //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
        /*  if(error.status !== 401){
             
             
         }  */
    }
}

export const GetAll = async (setProducts, setCategories) => {
    let response = await api.get(VW_ITEMS);
    try {
        if (response.status === 200) {

            let products = [];
            let categories = [];

            response.data.map(value => {
                if (value.reference === 'products') products.push(value.data);
                if (value.reference === 'categories') categories.push(value.data);
            })

            setCategories(categories);
            setProducts(products);
        }
    } catch (error) {
        if (error.status !== 401) throw error.response;
    }
}

export const Create = async (data, setProduct, setNotify) => {

    try {
        let response = await api.post(PRODUCTS_URL, data);
        if (response.status === 200) {
            setProduct(response.data);
            setNotify({ title: 'Sucesso!', message: 'Produto cadastrado com sucesso!', type: 'success', visible: true });
            //window.history.pushState(null, '', window.location.href + response.data.id);
            setTimeout(() => {
                window.location.assign(`${window.location.origin}/products`)
            }, 2000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;

        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
        let btn = document.querySelector('.btn-submit')
        btn.removeAttribute('disabled')
    }

}

export const Update = async (id, data, setProduct, setNotify) => {

    try {
        let response = await api.put(PRODUCTS_URL + id + '/', data);
        if (response.status === 200) {
            setProduct(response.data);
            setNotify({ title: 'Sucesso!', message: 'Produto atualizado com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.assign(`${window.location.origin}/products`)
            }, 2000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;

        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
        let btn = document.querySelector('.btn-submit')
        btn.removeAttribute('disabled')
    }
}

export const Delete = async (data, setNotify) => {
    try {
        let ids = [];
        data.map(product => ids.push(product.id))
        let response = await api.delete(PRODUCTS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }
}

export const GetCategories = async (setCategories) => {

    try {
        let response = await api.get(CATEGORIES_URL);
        if (response.status === 200) setCategories(response.data);
    } catch (error) {
        if (error.status !== 401) throw error.response;
    }
}