import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Accordion, ListGroup } from "react-bootstrap";
import {
  CreateStore,
  UpdateStore,
  CreateCustomization,
  UpdateCustomizations,
  FindPlace,
} from "../services/ConfigurationsServices";
import { Hash } from "../helpers/Helpers";
import DefaultLoader from "../jsx/components/Loader";
import Swal from "sweetalert2";
import AlertResponses from "../jsx/components/Alert";
import ErrorsComponent from "../jsx/components/Errors";
import ProgressBarComponent from "../jsx/components/ProgressBar";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { AppSettings, Theme } from "../App";

export default function Configurations() {
  const [erros, setErros] = useState(null);
  const [notify, setNotify] = useState({
    title: "",
    message: "",
    type: "",
    visible: false,
  });
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: registerLogo,
    handleSubmit: handleSubmitLogo,
    setError: setErrorLogo,
    formState: { errors: errorsLogo },
  } = useForm();
  const {
    register: registerStore,
    handleSubmit: handleSubmitStore,
    setValue: setValueStore,
    control,
    setError: setErrorStore,

    formState: { errors: errorsStore },
  } = useForm();
  const {
    register: registerSocialMedia,
    handleSubmit: handleSubmitSocialMedia,
    setValue: setValueSocialMedia,
    setError: setErrorSocialMedia,
    formState: { errors: errorsSocialMedia },
  } = useForm();
  const [logo, setLogo] = useState(null);
  const [image, setImage] = useState(null);
  const [collapsed, setCollapsed] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState(null);

  const status = {
    0: {
      id: 0,
      label: "Desativada",
      type: "light",
    },
    1: {
      id: 1,
      label: "Ativa",
      type: "success",
    },
  };

  const onSubmitColors = (value) => {
    let values = value.colors.split(",");

    let form = new FormData();

    let colors = {
      background: getComputedStyle(document.documentElement)
        .getPropertyValue(`--${values[0]}`)
        .replace(" ", ""),
      primary: getComputedStyle(document.documentElement)
        .getPropertyValue(`--${values[1]}`)
        .replace(" ", ""),
      secondary: getComputedStyle(document.documentElement)
        .getPropertyValue(`--${values[2]}`)
        .replace(" ", ""),
      tertiary: getComputedStyle(document.documentElement)
        .getPropertyValue(`--${values[3]}`)
        .replace(" ", ""),
      titles: getComputedStyle(document.documentElement)
        .getPropertyValue(`--${values[4]}`)
        .replace(" ", ""),
      subtitles: getComputedStyle(document.documentElement)
        .getPropertyValue(`--${values[5]}`)
        .replace(" ", ""),
      value: value.colors,
    };

    let data = {
      colors: colors,
      store: AppSettings.store.id,
      url: window.location.host,
    };

    if (Theme.colors.id) {
      UpdateCustomizations(Theme.colors.id, data, setNotify);
    } else {
      CreateCustomization(form, setNotify);
    }

    localStorage.setItem("colors", JSON.stringify(colors));
  };

  const onSubmitLogo = (value) => {
    if (value.logo.length === 0) {
      setErrorLogo("logo", {
        message: "Por favor, insira uma imagem.",
        type: "required",
      });
      return;
    }

    let form = new FormData();
    let newImage = new File(
      [value.logo[0]],
      `logo-${Math.floor(Math.random() * 99999999)}.${value.logo[0].type.split("/")[1]
      }`,
      { type: value.logo[0].type }
    );
    form.append("logo", newImage);
    form.append("store", AppSettings.store.id);
    form.append("url", window.location.host);

    if (AppSettings.store.id) {
      UpdateCustomizations(AppSettings.store.id, form, setNotify);
    } else {
      CreateCustomization(form, setNotify);
    }
  };

  const onSubmitStore = (value) => {
    let form = new FormData();
    let obj = {}

    document.querySelectorAll(".input-opening-hours").forEach(input => {
      obj[input.name] = input.value
    })

    form.append("razaoSocial", value.razaoSocial);
    form.append("name", value.name);
    form.append("email", value.email);
    form.append("description", value.description);
    form.append("openingHours", JSON.stringify(obj))

    if (value.cnpj) {
      value.cnpj = value.cnpj.replace(/[^0-9]/g, '');
    }

    form.append("cnpj", value.cnpj);

    /* form.append(
      "openingHours",
      JSON.stringify({
        monday: value.monday,
        tuesday: value.tuesday,
        wednesday: value.wednesday,
        thursday: value.thursday,
        friday: value.friday,
        saturday: value.saturday,
        sunday: value.sunday,
        mondayInt2: value.mondayInt2,
        tuesdayInt2: value.tuesdayInt2,
        wednesdayInt2: value.wednesdayInt2,
        thursdayInt2: value.thursdayInt2,
        fridayInt2: value.fridayInt2,
        saturdayInt2: value.saturdayInt2,
        sundayInt2: value.sundayInt2,
      })
    ); */

    let address = [];


    if (value.address) {

      form.append(
        "address",
        JSON.stringify({
          street: value.address.address.street,
          number: value.number,
          neighborhood: value.address.address.district,
          city: value.address.address.city,
          state: value.address.address.state,
          complete_address: value.address.address.houseNumber ? value.address.title : `${value.address.address.street}, ${value.number}, ${value.address.address.district}, ${value.address.address.city} - ${value.address.address.stateCode}, 
         ${value.address.address.postalCode ? value.address.address.postalCode : ''}, ${value.address.address.countryName}`,
        })
      );
    }

    let phone = document.getElementsByName("phone")[0].value;
    let cellPhone = document.getElementsByName("cellPhone")[0].value;

    if (phone != ("" || null || undefined)) {
      form.append("phone", phone.replace(/[^0-9]/g, ""));
    } else {
      form.append(
        "phone",
        value.phone == undefined || "" ? "" : value.phone.replace(/[^0-9]/g, "")
      );
    }

    if (cellPhone != ("" || null || undefined)) {
      form.append("cellPhone", cellPhone.replace(/[^0-9]/g, ""));
    } else {
      form.append(
        "cellPhone",
        value.cellPhone == undefined || ""
          ? ""
          : value.cellPhone.replace(/[^0-9]/g, "")
      );
    }

    value.image.length > 0 && form.append("image", value.image[0]);
    form.append("store", process.env.REACT_APP_DOMAIN);

    if (AppSettings.store.id) {
      UpdateStore(AppSettings.store.id, form, setNotify);
    } else {
      CreateStore(form, setNotify);
    }
  };

  const onSubmitSocialMedia = (data) => {
    let form = new FormData();

    form.append("store", window.location.host);
    form.append(
      "social_media",
      JSON.stringify({
        facebook: data.facebook,
        instagram: data.instagram,
        tripadvisor: data.tripadvisor,
      })
    );

    if (AppSettings.store.id) {
      UpdateStore(AppSettings.store.id, form, setNotify);
    }
  };

  const imagePreview = (image, set) => {
    let url = URL.createObjectURL(image);
    set(url);
  };

  useEffect(() => {
    if (AppSettings.store) {
      setIsLoading(false)
    }
  }, [AppSettings.store])

  if (isLoading) {
    return <DefaultLoader />
  }

  return (
    <>
      {erros !== null && <ErrorsComponent args={erros} />}
      <div className="item mt-4">
        <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
          <div className="col-xl-4 col-xxl-5 col-sm-12">
            <h2>Configurações</h2>
            <h4>
              <small>Dashboard, App & Site</small>
            </h4>
          </div>
        </div>
      </div>
      {notify.visible && (
        <AlertResponses
          title={notify.title}
          message={notify.message}
          type={notify.type}
          visible={notify.visible}
          setNotify={setNotify}
        />
      )}
      <>
        <Col xl="4" className="d-inline-block align-top">
          <Card className="mb-3 align-items-center">
            <Card.Header className="m-auto flex-column">
              <Card.Title>Logo</Card.Title>
            </Card.Header>
            <Card.Body className="card-body w-100 p-2 d-flex flex-column align-items-center">

              <form
                onSubmit={handleSubmitLogo(onSubmitLogo)}
                className="m-auto text-center"
              >
                {AppSettings.logo || logo ? (
                  <img
                    className="img-fluid rounded-circle"
                    src={
                      logo === null ? Hash("400x400", AppSettings.logo.includes("/images/") ? AppSettings.logo.split("/images/")[1] : AppSettings.logo)
                        : logo
                    }
                    alt="image"
                    style={styles.logo}
                  />
                ) : (
                  <div
                    className="image-default rounded-circle"
                    style={styles.imageDefault}
                  >
                    <i className="ti-image"></i>
                  </div>
                )}
                <div className="input-group mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Upload</span>
                  </div>
                  <div className="custom-file">
                    <input
                      accept="image/*"
                      type="file"
                      className="custom-file-input"
                      ref={registerLogo}
                      {...registerLogo("logo")}
                      onChange={(e) => imagePreview(e.target.files[0], setLogo)}
                    />
                    <label className="custom-file-label text-left">
                      Selecione...
                    </label>
                  </div>
                </div>
                {errorsLogo.logo && (
                  <small className="mt-3 text-danger d-block">
                    {errorsLogo.logo.type === "required" &&
                      "Por favor, selecione uma imagem!"}
                  </small>
                )}
                <Button type="submit" className="btn btn-primary w-50 mt-3">
                  Salvar
                </Button>
              </form>
            </Card.Body>
          </Card>
          <Card className="mb-3 align-items-center">
            <Card.Header className="m-auto flex-column">
              <Card.Title>Redes Sociais</Card.Title>
            </Card.Header>
            <Card.Body className="card-body w-100 p-2 d-flex flex-column align-items-center">
              <form
                onSubmit={handleSubmitSocialMedia(onSubmitSocialMedia)}
                className="m-auto text-center"
              >
                <div className="input-group icon-left mt-3">
                  <i className="ti-facebook"></i>
                  <input
                    className="form-control text-black p-placeholder"
                    placeholder="URL Facebook"
                    defaultValue={
                      AppSettings.store.social_media && AppSettings.store.social_media.facebook
                        ? AppSettings.store.social_media.facebook
                        : ""
                    }
                    {...registerSocialMedia("facebook", {
                      pattern: /^https:\/\/(?:www.)?facebook.com\//,
                    })}
                  />
                </div>

                {errorsSocialMedia.facebook && (
                  <small className="mt-3 text-danger d-block">
                    {errorsSocialMedia.facebook.type === "pattern" &&
                      "Por favor, insira a URL do facebook corretamente!"}
                  </small>
                )}

                <div className="input-group icon-left mt-3">
                  <i className="ti-instagram"></i>
                  <input
                    className="form-control text-black p-placeholder"
                    placeholder="URL Instagram"
                    defaultValue={
                      AppSettings.store.social_media && AppSettings.store.social_media.instagram
                        ? AppSettings.store.social_media.instagram
                        : ""
                    }
                    {...registerSocialMedia("instagram", {
                      pattern: /^https:\/\/(?:www.)?instagram.com\//,
                    })}
                  />
                </div>

                {errorsSocialMedia.instagram && (
                  <small className="mt-3 text-danger d-block">
                    {errorsSocialMedia.instagram.type === "pattern" &&
                      "Por favor, insira a URL do instagram corretamente!"}
                  </small>
                )}

                <div className="input-group icon-left mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    aria-labelledby="title"
                    height={18}
                    width={18}
                    aria-describedby="desc"
                    role="img"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>Tripadvisor</title>
                    <desc>A solid styled icon from Orion Icon Library.</desc>
                    <path
                      data-name="layer4"
                      d="M39.1 13.516a50.1 50.1 0 0 1 5.3 1.209 32.624 32.624 0 0 1 8.492 3.929 1.435 1.435 0 0 0 .7.2h10.189v.1a4.39 4.39 0 0 0-.4.705 16.853 16.853 0 0 0-2.5 5.239.477.477 0 0 0 .1.6 15.734 15.734 0 0 1 2.5 13.3 15.315 15.315 0 0 1-7.094 9.772 15.97 15.97 0 0 1-8.487 2.422 15.691 15.691 0 0 1-3.8-.5 16.211 16.211 0 0 1-7.893-4.634 9.057 9.057 0 0 1-.9-1.007c-1.1 1.713-2.3 3.425-3.4 5.138-1.2-1.713-2.3-3.425-3.4-5.037-.1 0-.1 0-.1.1l-.1.1a15.544 15.544 0 0 1-9.891 5.641 14.656 14.656 0 0 1-6.594-.4 15.314 15.314 0 0 1-7.793-4.936 15.784 15.784 0 0 1-3.8-8.16 13.951 13.951 0 0 1 .3-6.347 13.547 13.547 0 0 1 2.4-5.339.76.76 0 0 0 .1-.5 21.114 21.114 0 0 0-2.2-4.836 7.687 7.687 0 0 0-.8-1.108v-.1h9.9c.1 0 .3-.1.4-.1a34.036 34.036 0 0 1 7.194-3.526 50.8 50.8 0 0 1 5.6-1.511 33.995 33.995 0 0 1 5.6-.705 41.028 41.028 0 0 1 10.377.291zm-4 21.458a12.789 12.789 0 1 0 12.6-12.895 12.8 12.8 0 0 0-12.593 12.895zM15.924 22.079a12.846 12.846 0 1 0 12.788 12.895 12.706 12.706 0 0 0-12.788-12.895zm.7-3.324a15.746 15.746 0 0 1 10.091 4.231 16.211 16.211 0 0 1 5.2 9.772A16.351 16.351 0 0 1 37 23.087a15.491 15.491 0 0 1 10-4.231 36.237 36.237 0 0 0-14.187-3.022 38.507 38.507 0 0 0-16.19 2.921z"
                      fill="#202020"
                    ></path>
                    <path
                      data-name="layer3"
                      d="M47.8 26.814a7.963 7.963 0 0 1 .3 15.917 7.962 7.962 0 1 1-.3-15.917zm5.2 7.959a5.146 5.146 0 1 0-10.291-.1 5.245 5.245 0 0 0 5.2 5.239 5.012 5.012 0 0 0 5.082-5.139zm-37.176-7.959a7.966 7.966 0 0 1 .4 15.917 7.971 7.971 0 0 1-8.293-7.959 7.756 7.756 0 0 1 7.893-7.958zm5.1 7.959a5.036 5.036 0 0 0-5.2-5.138 5.24 5.24 0 0 0 .1 10.477 5.316 5.316 0 0 0 5.095-5.339z"
                      fill="#202020"
                    ></path>
                    <path
                      data-name="layer2"
                      d="M47.8 32.153a2.591 2.591 0 0 1 2.6 2.619 2.656 2.656 0 0 1-2.6 2.619 2.591 2.591 0 0 1-2.6-2.619 2.656 2.656 0 0 1 2.6-2.619z"
                      fill="#202020"
                    ></path>
                    <path
                      data-name="layer1"
                      d="M15.724 32.153a2.677 2.677 0 0 1 2.7 2.619 2.763 2.763 0 0 1-2.7 2.72 2.67 2.67 0 1 1 0-5.339z"
                      fill="#202020"
                    ></path>
                  </svg>
                  <input
                    className="form-control text-black p-placeholder"
                    placeholder="URL Tripadvisor"
                    defaultValue={
                      AppSettings.store.social_media && AppSettings.store.social_media.tripadvisor
                        ? AppSettings.store.social_media.tripadvisor
                        : ""
                    }
                    {...registerSocialMedia("tripadvisor", {
                      pattern: /^https:\/\/(?:www.)?tripadvisor.com.br\//,
                    })}
                  />
                </div>

                {errorsSocialMedia.tripadvisor && (
                  <small className="mt-3 text-danger d-block">
                    {errorsSocialMedia.tripadvisor.type === "pattern" &&
                      "Por favor, insira a URL do tripadvisor corretamente!"}
                  </small>
                )}
                <Button type="submit" className="btn btn-primary w-50 mt-3">
                  Salvar
                </Button>
              </form>
            </Card.Body>
          </Card>
          {/* <Card className='mb-3 align-items-center'>
                  <Card.Header className="m-auto flex-column">
                    <Card.Title>Splash screen</Card.Title>
                    <small className="pt-2">Tela de abertura do App</small>
                  </Card.Header>
                  <Card.Body className="card-body w-100 p-2 d-flex flex-column align-items-center">
                    <form onSubmit={handleSubmitSplash(onSubmitSplashScreen)} className="m-auto text-center">
                      
                      {
                        customizations.current != (null && undefined) && customizations.current.splashscreen !== null || splashScreen !== null ?
                          <img className="img-fluid rounded-lg"
                            src={splashScreen === null ? Hash('400x400', 'customizations/' + customizations.current.splashscreen.split('/').pop()) : splashScreen} alt="image"
                            style={styles.splashScreen} />
                          : <div className="image-default rounded-lg" style={styles.splashScreen}>
                            <i className="ti-image"></i>
                          </div>
                      }
                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Upload</span>
                        </div>
                        <div className="custom-file">
                          <input accept="image/*" type="file" className="custom-file-input"
                            ref={registerSplash} {...registerSplash("splashScreen")}
                            onChange={(e) => imagePreview(e.target.files[0], setSplashScreen)} />
                          <label className="custom-file-label text-left">Selecione...</label>
                        </div>


                      </div>
                      {errorsSplash.splashScreen &&
                        <small className="mt-3 text-danger d-block">
                          {errorsSplash.splashScreen.type === 'required' && 'Por favor, selecione uma imagem!'}</small>}
                      <Button type="submit" className="btn btn-primary w-50 mt-3">Salvar</Button>
                    </form>
                  </Card.Body>
                </Card> */}
        </Col>
        <Col xl="8" className="d-inline-block">
          <Card className="mb-3 align-items-center">
            <Card.Header className="m-auto flex-column">
              <Card.Title>Informações Loja Principal</Card.Title>
            </Card.Header>
            <Card.Body className="card-body w-100 p-3 d-flex flex-column align-items-center">
              <form
                className="w-100"
                onSubmit={handleSubmitStore(onSubmitStore)}
              >
                <div className="form-row flex-column align-items-center mb-5">
                  {AppSettings.store.image || image ? (
                    <img
                      className="img-fluid rounded-circle"
                      src={
                        image === null
                          ? Hash(
                            "400x400",
                            "store/" +
                            AppSettings.store.image.split("/").pop()
                          )
                          : image
                      }
                      alt="image"
                      style={styles.imageDefault}
                    />
                  ) : (
                    <div
                      className="image-default rounded-circle"
                      style={styles.imageDefault}
                    >
                      <i className="ti-image"></i>
                    </div>
                  )}

                  <div className="input-group mt-3 w-50">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Upload</span>
                    </div>
                    <div className="custom-file">
                      <input
                        accept="image/*"
                        type="file"
                        className="custom-file-input"
                        {...registerStore("image", {
                          required: AppSettings.store.id ? false : true,
                        })}
                        onChange={(e) =>
                          imagePreview(e.target.files[0], setImage)
                        }
                      />
                      <label className="custom-file-label text-left">
                        Imagem do Local...
                      </label>
                    </div>
                  </div>
                  {errorsStore.image && (
                    <small className="mt-3 text-danger d-block">
                      {errorsStore.image.type === "required" &&
                        "Por favor, selecione uma imagem!"}
                    </small>
                  )}
                </div>
                <div className="form-row">
                  <div className="form-group col-md-8 d-inline-block">
                    <label className="pl-2">Razão Social</label>
                    <input
                      {...registerStore("razaoSocial", {
                        required: true,
                      })}
                      className="form-control text-black"
                      placeholder="Razão social"
                      defaultValue={
                        AppSettings.store ? AppSettings.store.razaoSocial : ""
                      }
                    />
                    {errorsStore.razaoSocial && (
                      <small className="ml-2 text-danger">
                        Por favor, preencha este campo!
                      </small>
                    )}
                  </div>
                  <div className="form-group col-md-4 d-inline-block">
                    <label className="pl-2">CNPJ</label>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, defaultValue, name } }) =>
                        <NumberFormat
                          name="cnpj"
                          defaultValue={AppSettings.store ? AppSettings.store.cnpj : ""}
                          format="##.###.###/####-##"
                          placeholder="CNPJ"
                          className="form-control text-black input-opening-hours"
                          onChange={(e) =>
                            e.target.value.length > 8 &&
                            setValueStore("cnpj", e.target.value)
                          }
                        />
                      }

                    />
                    {errorsStore.cnpj && (
                      <small className="ml-2 text-danger">
                        {errorsStore.cnpj.type === "required"
                          && "Por favor, preencha este campo!"}
                      </small>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="pl-2">Nome do Estabelecimento</label>
                    <input
                      {...registerStore("name", {
                        required: true,
                        maxLength: 30,
                      })}
                      className="form-control text-black"
                      defaultValue={
                        AppSettings.store ? AppSettings.store.name : ""
                      }
                    />
                    {errorsStore.name && (
                      <small className="ml-2 text-danger">
                        {errorsStore.name.type === "required"
                          ? "Por favor, preencha este campo!"
                          : "Este campo deve conter no máximo 20 caracteres!"}
                      </small>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <label className="pl-2">Contato</label>
                  <div className="form-group col-md-12 d-flex flex-row">
                    <NumberFormat
                      defaultValue={
                        AppSettings.store ? AppSettings.store.cellPhone : ""
                      }
                      format="(##) #####-####"
                      className="form-control text-black col-sm-6 mb-2 mr-1"
                      placeholder="Celular"
                      onChange={(e) =>
                        e.target.value.length > 10 &&
                        setValueStore("cellPhone", e.target.value)
                      }
                      {...registerStore("cellPhone")}
                    />
                    <NumberFormat
                      defaultValue={
                        AppSettings.store ? AppSettings.store.phone : ""
                      }
                      format="(##) ####-####"
                      className="form-control text-black col-sm-6 mb-2"
                      placeholder="Telefone Fixo"
                      onChange={(e) =>
                        e.target.value.length > 8 &&
                        setValueStore("phone", e.target.value)
                      }
                      {...registerStore("phone")}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="pl-2">E-mail</label>
                    <input
                      className="form-control text-black"
                      defaultValue={
                        AppSettings.store ? AppSettings.store.email : ""
                      }
                      {...registerStore("email", {
                        required: true,
                        pattern: /^(\w)+(@.*)+(\.)/,
                      })}
                    />
                    {errorsStore.email && (
                      <small className="ml-2 text-danger">
                        {errorsStore.email.type === "required"
                          ? "Por favor, preencha este campo!"
                          : "Preencha corretamente o e-mail de contato!"}
                      </small>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="pl-2"></label>
                    <div className="accordion__item">
                      <Accordion
                        className="accordion accordion-active-header"
                        defaultActiveKey="1"
                      >
                        <Accordion.Toggle
                          as={Card.Text}
                          className={`accordion__header ${collapsed === 0 ? "collapsed" : ""
                            } accordion__header--primary p-2 mb-0`}
                          eventKey="0"
                          onClick={() =>
                            collapsed ? setCollapsed(0) : setCollapsed(1)
                          }
                        >
                          <span className="accordion__header--icon"></span>
                          <span className="accordion__header--text">
                            Horário de funcionamento
                          </span>
                          <span className="accordion__header--indicator "></span>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          className="accordion__body"
                          eventKey="0"
                        >
                          <Card.Body className="text-center">
                            <div className="form-row int">
                              <div className="form-group int-one mr-2">
                                <label className="d-block text-left">Intervalo 1</label>
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      format="##:## - ##:##"
                                      placeholder="Segunda-feira"
                                      className="form-control text-black input-opening-hours"
                                      style={{ maxWidth: '100%' }}
                                      name="monday"
                                      defaultValue={AppSettings.store.openingHours?.monday}
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('monday', { invalid: true })
                                        } else {
                                          setErrorStore('monday', { invalid: false })
                                          setValueStore("monday", e.target.value)
                                        }
                                      }

                                      }
                                    />
                                  }

                                />
                                {errorsStore?.monday?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                              <div className="form-group int-two">
                                <label className="d-block text-left">Intervalo 2</label>
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="mondayInt2"
                                      defaultValue={AppSettings.store.openingHours?.mondayInt2}
                                      format="##:## - ##:##"
                                      placeholder="Segunda-feira"
                                      className="form-control text-black input-opening-hours"
                                      style={{ maxWidth: '100%' }}
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('mondayInt2', { invalid: true })
                                        } else {
                                          setErrorStore('mondayInt2', { invalid: false })
                                          setValueStore("mondayInt2", e.target.value)
                                        }
                                      }}
                                    />
                                  }

                                />
                                {errorsStore?.mondayInt2?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="form-row int">
                              <div className="form-group int-one mr-2">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="tuesday"
                                      defaultValue={AppSettings.store.openingHours?.tuesday}
                                      format="##:## - ##:##"
                                      placeholder="Terça-feira"
                                      className="form-control text-black input-opening-hours"
                                      style={{ maxWidth: '100%' }}
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('tuesday', { invalid: true })
                                        } else {
                                          setErrorStore('tuesday', { invalid: false })
                                          setValueStore("tuesday", e.target.value)
                                        }
                                      }}
                                    />
                                  }

                                />
                                {errorsStore?.tuesday?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                              <div className="form-group int-two">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="tuesdayInt2"
                                      defaultValue={AppSettings.store.openingHours?.tuesdayInt2}
                                      format="##:## - ##:##"
                                      placeholder="Terça-feira"
                                      className="form-control text-black input-opening-hours"
                                      style={{ maxWidth: '100%' }}
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('tuesdayInt2', { invalid: true })
                                        } else {
                                          setErrorStore('tuesdayInt2', { invalid: false })
                                          setValueStore("tuesdayInt2", e.target.value)
                                        }
                                      }}

                                    />
                                  }
                                />
                                {errorsStore?.tuesdayInt2?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="form-row int">
                              <div className="form-group int-one mr-2">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="wednesday"
                                      defaultValue={AppSettings.store.openingHours?.wednesday}
                                      format="##:## - ##:##"
                                      placeholder="Quarta-feira"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('wednesday', { invalid: true })
                                        } else {
                                          setErrorStore('wednesday', { invalid: false })
                                          setValueStore("wednesday", e.target.value)
                                        }
                                      }}
                                    />
                                  }
                                />
                                {errorsStore?.wednesday?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>

                              <div className="form-group int-two mr-2">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="wednesdayInt2"
                                      defaultValue={AppSettings.store.openingHours?.wednesdayInt2}
                                      format="##:## - ##:##"
                                      placeholder="Quarta-feira"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('wednesdayInt2', { invalid: true })
                                        } else {
                                          setErrorStore('wednesdayInt2', { invalid: false })
                                          setValueStore("wednesdayInt2", e.target.value)
                                        }
                                      }}
                                    />
                                  }
                                />
                                {errorsStore?.wednesdayInt2?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                            </div>


                            <div className="form-row int">
                              <div className="form-group int-one mr-2">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="thursday"
                                      defaultValue={AppSettings.store.openingHours?.thursday}
                                      format="##:## - ##:##"
                                      placeholder="Quinta-feira"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('thursday', { invalid: true })
                                        } else {
                                          setErrorStore('thursday', { invalid: false })
                                          setValueStore("thursday", e.target.value)
                                        }
                                      }}

                                    />
                                  }
                                />
                                {errorsStore?.thursday?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>

                              <div className="form-group int-two">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="thursdayInt2"
                                      defaultValue={AppSettings.store.openingHours?.thursdayInt2}
                                      format="##:## - ##:##"
                                      placeholder="Quinta-feira"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('thursdayInt2', { invalid: true })
                                        } else {
                                          setErrorStore('thursdayInt2', { invalid: false })
                                          setValueStore("thursdayInt2", e.target.value)
                                        }
                                      }}
                                    />
                                  }

                                />
                                {errorsStore?.thursdayInt2?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="form-row int">
                              <div className="form-group int-one mr-2">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="friday"
                                      defaultValue={AppSettings.store.openingHours?.friday}
                                      format="##:## - ##:##"
                                      placeholder="Sexta-feira"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('friday', { invalid: true })
                                        } else {
                                          setErrorStore('friday', { invalid: false })
                                          setValueStore("friday", e.target.value)
                                        }
                                      }}
                                    />
                                  }

                                />
                                {errorsStore?.friday?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>

                              <div className="form-group int-two">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="fridayInt2"
                                      defaultValue={AppSettings.store.openingHours?.fridayInt2}
                                      format="##:## - ##:##"
                                      placeholder="Sexta-feira"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('fridayInt2', { invalid: true })
                                        } else {
                                          setErrorStore('fridayInt2', { invalid: false })
                                          setValueStore("fridayInt2", e.target.value)
                                        }
                                      }}

                                    />
                                  }

                                />
                                {errorsStore?.fridayInt2?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="form-row int">
                              <div className="form-group int-one mr-2">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="saturday"
                                      defaultValue={AppSettings.store.openingHours?.saturday}
                                      format="##:## - ##:##"
                                      placeholder="Sábado"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('saturday', { invalid: true })
                                        } else {
                                          setErrorStore('saturday', { invalid: false })
                                          setValueStore("saturday", e.target.value)
                                        }
                                      }}
                                    />
                                  }

                                />
                                {errorsStore?.saturday?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                              <div className="form-group int-two">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="saturdayInt2"
                                      defaultValue={AppSettings.store.openingHours?.saturdayInt2}
                                      format="##:## - ##:##"
                                      placeholder="Sábado"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('saturdayInt2', { invalid: true })
                                        } else {
                                          setErrorStore('saturdayInt2', { invalid: false })
                                          setValueStore("saturdayInt2", e.target.value)
                                        }
                                      }}
                                    />
                                  }
                                />
                                {errorsStore?.saturdayInt2?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="form-row int">
                              <div className="form-group int-one mr-2">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="sunday"
                                      defaultValue={AppSettings.store.openingHours?.sunday}
                                      format="##:## - ##:##"
                                      placeholder="Domingo"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('sunday', { invalid: true })
                                        } else {
                                          setErrorStore('sunday', { invalid: false })
                                          setValueStore("sunday", e.target.value)
                                        }
                                      }}
                                    />
                                  }
                                />
                                {errorsStore?.sunday?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                              <div className="form-group int-two">
                                <Controller
                                  control={control}
                                  render={({ field: { onChange, defaultValue, name } }) =>
                                    <NumberFormat
                                      name="sundayInt2"
                                      defaultValue={AppSettings.store.openingHours?.sundayInt2}
                                      format="##:## - ##:##"
                                      placeholder="Domingo"
                                      className="form-control text-black input-opening-hours"
                                      onChange={(e) => {
                                        if (e.target.value.replace(/[^0-9]/g, '').length > 1 && e.target.value.replace(/[^0-9]/g, '').length < 7) {
                                          setErrorStore('sundayInt2', { invalid: true })
                                        } else {
                                          setErrorStore('sundayInt2', { invalid: false })
                                          setValueStore("sundayInt2", e.target.value)
                                        }
                                      }}
                                    />
                                  }
                                />
                                {errorsStore?.sundayInt2?.invalid && (
                                  <small className="ml-2 text-danger">
                                    Por favor, preencha corretamente este campo!
                                  </small>
                                )}
                              </div>
                            </div>

                            <small className="mt-2">
                              <i
                                className="ti-info-alt text-info mr-1"
                                style={styles.info}
                              ></i>{" "}
                              Para fechado, basta deixar o campo do dia em
                              branco.
                            </small>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                    </div>
                  </div>
                  {errors.status && (
                    <small className="ml-2 mb-2 text-danger">
                      {errors.expiration.type === "required" &&
                        "Por favor, preencha corretamente este campo!"}
                    </small>
                  )}
                </div>
                <div className="form-row position-relative">
                  <div className="col-lg-10 col-md-9 col-sm-12">
                    <label className="pl-2">Endereço</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          AppSettings.store.address
                            ? AppSettings.store.address.complete_address
                            : ""
                        }
                        {...register("address", { required: true })}
                        onClick={() => {
                          let d = document.querySelector(
                            "#location-suggestions"
                          );
                          if (d.classList.value.includes("d-none")) {
                            d.classList.remove("d-none");
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value.length > 3) {
                            FindPlace(e.target.value, setNotify).then((res) => {
                              setSuggestions(res.items);
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-12">
                    <label className="pl-2">Nº</label>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={
                          AppSettings.store.address
                            ? AppSettings.store.address.number
                            : ""
                        }
                        {...registerStore("number")}
                      />
                    </div>
                  </div>

                  <div id="location-suggestions">
                    <ListGroup as="ul">
                      {suggestions &&
                        suggestions.map((v, i) => (

                          <ListGroup.Item
                            key={i}
                            as="li"
                            className="mt-0"
                            onClick={() => {
                              document.querySelector("#location-suggestions").classList.add("d-none");
                              document.querySelector('input[name="address"]').value = `${v.title}`;

                              if (v.address && v.address.houseNumber) {
                                document.querySelector('input[name="number"]').value = `${v.address.houseNumber}`;
                                setValueStore("number", v.address.houseNumber);
                              }

                              setValueStore("address", v);
                            }}
                          >
                            {`${v.title}`}
                          </ListGroup.Item>
                        ))}
                      {document.querySelector("#location-suggestions ul li") !==
                        null && (
                          <span
                            className="badge"
                            onClick={() => {
                              let d = document.querySelector(
                                "#location-suggestions"
                              );
                              if (!d.classList.value.includes("d-none")) {
                                d.classList.add("d-none");
                              }
                            }}
                          >
                            Fechar
                          </span>
                        )}
                    </ListGroup>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="pl-2">Sobre</label>
                    <textarea
                      defaultValue={
                        AppSettings.store ? AppSettings.store.description : ""
                      }
                      className="form-control"
                      placeholder="Breve história da empresa..."
                      {...registerStore("description", { required: true })}
                      rows={6}
                    ></textarea>
                  </div>
                  {errorsStore.description && (
                    <small className="ml-2 mb-2 text-danger">
                      {errorsStore.description.type === "required" &&
                        "Por favor, preencha este campo!"}
                    </small>
                  )}
                </div>
                <Button type="submit" className="btn btn-block btn-primary">
                  {isLoading ? <DefaultLoader color={"white"} /> : "Salvar"}
                </Button>
              </form>
            </Card.Body>
          </Card>
          <Card className="mb-3 align-items-center">
            <Card.Header className="m-auto flex-column">
              <Card.Title>Cores </Card.Title>
              <small className="d-block">
                Background, Primária, Secundária, Terciária, Títulos &
                Subtítulos
              </small>
            </Card.Header>
            <Card.Body className="card-body w-100 p-2 d-flex align-items-baseline">
              <form
                onSubmit={handleSubmit(onSubmitColors)}
                className="text-center m-auto"
              >
                <div className="row m-auto">
                  <div className="col-sm-1 d-inline-block align-items-center p-0 m-auto text-center">
                    <span
                      className="d-inline-block border border-dark"
                      style={{
                        background: "var(--flat0)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat1)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat2)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat3)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--titles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--subtitles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <div className="radio">
                      <label className="pl-2">
                        <input
                          type="radio"
                          value="flat0,flat1,flat2,flat3,titles,subtitles"
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.value ===
                                "flat0,flat1,flat2,flat3,titles,subtitles"
                                ? true
                                : false
                              : false
                          }
                          {...register("colors", { required: true })}
                          onChange={(e) => {
                            let color = e.target.value.split(",");
                            document.documentElement.style.setProperty(
                              "--background",
                              `var(--${color[0]})`
                            );
                            document.documentElement.style.setProperty(
                              "--primary",
                              `var(--${color[1]})`
                            );
                            document.documentElement.style.setProperty(
                              "--secondary",
                              `var(--${color[2]})`
                            );
                            document.documentElement.style.setProperty(
                              "--tertiary",
                              `var(--${color[3]})`
                            );
                            document.documentElement.style.setProperty(
                              "--titles",
                              `var(--${color[4]})`
                            );
                            document.documentElement.style.setProperty(
                              "--subtitles",
                              `var(--${color[4]})`
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1 d-inline-block align-items-center p-0 m-auto text-center">
                    <span
                      className="d-inline-block border border-dark"
                      style={{
                        background: "var(--flat0)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat4)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat5)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat6)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--titles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--subtitles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <div className="radio">
                      <label className="pl-2">
                        <input
                          type="radio"
                          value="flat0,flat4,flat5,flat6,titles,subtitles"
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.value ===
                                "flat0,flat4,flat5,flat6,titles,subtitles"
                                ? true
                                : false
                              : false
                          }
                          {...register("colors", { required: true })}
                          onChange={(e) => {
                            let color = e.target.value.split(",");
                            document.documentElement.style.setProperty(
                              "--background",
                              `var(--${color[0]})`
                            );
                            document.documentElement.style.setProperty(
                              "--primary",
                              `var(--${color[1]})`
                            );
                            document.documentElement.style.setProperty(
                              "--secondary",
                              `var(--${color[2]})`
                            );
                            document.documentElement.style.setProperty(
                              "--tertiary",
                              `var(--${color[3]})`
                            );
                            document.documentElement.style.setProperty(
                              "--titles",
                              `var(--${color[4]})`
                            );
                            document.documentElement.style.setProperty(
                              "--subtitles",
                              `var(--${color[4]})`
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-sm-1 d-inline-block align-items-center p-0 m-auto text-center">
                          <span className="d-inline-block border border-dark" style={{ background: 'var(--background)', height: 30, width: 30 }}></span>
                          <span className="d-inline-block" style={{ background: 'var(--flat7)', height: 30, width: 30 }}></span>
                          <span className="d-inline-block" style={{ background: 'var(--flat8)', height: 30, width: 30 }}></span>
                          <span className="d-inline-block" style={{ background: 'var(--flat9)', height: 30, width: 30 }}></span>
                          <span className="d-inline-block" style={{ background: 'var(--titles)', height: 30, width: 30 }}></span>
                          <span className="d-inline-block" style={{ background: 'var(--subtitles)', height: 30, width: 30 }}></span>
                          <div className="radio">
                            <label className="pl-2">
                              <input type="radio" value="background,flat7,flat8,flat9,titles,subtitles"
                                defaultChecked={customizations.current != (null && undefined) ?
                                  customizations.current.colors !== undefined &&
                                    customizations.current.colors.value === 'background,flat7,flat8,flat9,titles,subtitles' ? true : false : false}
                                {...register("colors", { required: true })}
                                onChange={(e) => {
                                  let color = e.target.value.split(',')
                                  document.documentElement.style.setProperty('--background', `var(--${ color[0] })`);
                                  document.documentElement.style.setProperty('--primary', `var(--${ color[1] })`);
                                  document.documentElement.style.setProperty('--secondary', `var(--${ color[2] })`);
                                  document.documentElement.style.setProperty('--tertiary', `var(--${ color[3] })`);
                                  document.documentElement.style.setProperty('--titles', `var(--${ color[4] })`);
                                  document.documentElement.style.setProperty('--subtitles', `var(--${ color[4] })`);
                                }} />
                            </label>
                          </div>
                        </div> */}
                  <div className="col-sm-1 d-inline-block align-items-center p-0 m-auto text-center">
                    <span
                      className="d-inline-block border border-dark"
                      style={{
                        background: "var(--flat0)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat10)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat11)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat12)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--titles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--subtitles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <div className="radio">
                      <label className="pl-2">
                        <input
                          type="radio"
                          value="flat0,flat10,flat11,flat12,titles,subtitles"
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.value ===
                                "flat0,flat10,flat11,flat12,title,subtitles"
                                ? true
                                : false
                              : false
                          }
                          {...register("colors", { required: true })}
                          onChange={(e) => {
                            let color = e.target.value.split(",");
                            document.documentElement.style.setProperty(
                              "--background",
                              `var(--${color[0]})`
                            );
                            document.documentElement.style.setProperty(
                              "--primary",
                              `var(--${color[1]})`
                            );
                            document.documentElement.style.setProperty(
                              "--secondary",
                              `var(--${color[2]})`
                            );
                            document.documentElement.style.setProperty(
                              "--tertiary",
                              `var(--${color[3]})`
                            );
                            document.documentElement.style.setProperty(
                              "--titles",
                              `var(--${color[4]})`
                            );
                            document.documentElement.style.setProperty(
                              "--subtitles",
                              `var(--${color[4]})`
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1 d-inline-block align-items-center p-0 m-auto text-center">
                    <span
                      className="d-inline-block border border-dark"
                      style={{
                        background: "var(--flat0)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat13)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat14)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat15)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--titles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--subtitles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <div className="radio">
                      <label className="pl-2">
                        <input
                          type="radio"
                          value="flat0,flat13,flat14,flat15,titles,subtitles"
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.value ===
                                "flat0,flat13,flat14,flat15,titles,subtitles"
                                ? true
                                : false
                              : false
                          }
                          {...register("colors", { required: true })}
                          onChange={(e) => {
                            let color = e.target.value.split(",");
                            document.documentElement.style.setProperty(
                              "--background",
                              `var(--${color[0]})`
                            );
                            document.documentElement.style.setProperty(
                              "--primary",
                              `var(--${color[1]})`
                            );
                            document.documentElement.style.setProperty(
                              "--secondary",
                              `var(--${color[2]})`
                            );
                            document.documentElement.style.setProperty(
                              "--tertiary",
                              `var(--${color[3]})`
                            );
                            document.documentElement.style.setProperty(
                              "--titles",
                              `var(--${color[4]})`
                            );
                            document.documentElement.style.setProperty(
                              "--subtitles",
                              `var(--${color[4]})`
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1 d-inline-block align-items-center p-0 m-auto text-center">
                    <span
                      className="d-inline-block border border-dark"
                      style={{
                        background: "var(--flat0)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat16)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat17)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat18)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--titles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--subtitles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <div className="radio">
                      <label className="pl-2">
                        <input
                          type="radio"
                          value="flat0,flat16,flat17,flat18,titles,subtitles"
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.value ===
                                "flat0,flat16,flat17,flat18,titles,subtitles"
                                ? true
                                : false
                              : false
                          }
                          {...register("colors", { required: true })}
                          onChange={(e) => {
                            let color = e.target.value.split(",");
                            document.documentElement.style.setProperty(
                              "--background",
                              `var(--${color[0]})`
                            );
                            document.documentElement.style.setProperty(
                              "--primary",
                              `var(--${color[1]})`
                            );
                            document.documentElement.style.setProperty(
                              "--secondary",
                              `var(--${color[2]})`
                            );
                            document.documentElement.style.setProperty(
                              "--tertiary",
                              `var(--${color[3]})`
                            );
                            document.documentElement.style.setProperty(
                              "--titles",
                              `var(--${color[4]})`
                            );
                            document.documentElement.style.setProperty(
                              "--subtitles",
                              `var(--${color[4]})`
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1 d-inline-block align-items-center p-0 m-auto text-center">
                    <span
                      className="d-inline-block border border-dark"
                      style={{
                        background: "var(--flat0)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat19)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat20)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat21)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--titles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--subtitles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <div className="radio">
                      <label className="pl-2">
                        <input
                          type="radio"
                          value="flat0,flat19,flat20,flat21,titles,subtitles"
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.value ===
                                "flat0,flat19,flat20,flat21,titles,subtitles"
                                ? true
                                : false
                              : false
                          }
                          {...register("colors", { required: true })}
                          onChange={(e) => {
                            let color = e.target.value.split(",");
                            document.documentElement.style.setProperty(
                              "--background",
                              `var(--${color[0]})`
                            );
                            document.documentElement.style.setProperty(
                              "--primary",
                              `var(--${color[1]})`
                            );
                            document.documentElement.style.setProperty(
                              "--secondary",
                              `var(--${color[2]})`
                            );
                            document.documentElement.style.setProperty(
                              "--tertiary",
                              `var(--${color[3]})`
                            );
                            document.documentElement.style.setProperty(
                              "--titles",
                              `var(--${color[4]})`
                            );
                            document.documentElement.style.setProperty(
                              "--subtitles",
                              `var(--${color[4]})`
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1 d-inline-block align-items-center p-0 m-auto text-center">
                    <span
                      className="d-inline-block border border-dark"
                      style={{
                        background: "var(--flat0)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat22)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat23)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--flat24)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--titles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <span
                      className="d-inline-block"
                      style={{
                        background: "var(--subtitles)",
                        height: 30,
                        width: 30,
                      }}
                    ></span>
                    <div className="radio">
                      <label className="pl-2">
                        <input
                          type="radio"
                          value="flat0,flat22,flat23,flat24,titles,subtitles"
                          defaultChecked={
                            Theme.colors
                              ? Theme.colors.value ===
                                "flat0,flat22,flat23,flat24,titles,subtitles"
                                ? true
                                : false
                              : false
                          }
                          {...register("colors", { required: true })}
                          onChange={(e) => {
                            let color = e.target.value.split(",");
                            document.documentElement.style.setProperty(
                              "--background",
                              `var(--${color[0]})`
                            );
                            document.documentElement.style.setProperty(
                              "--primary",
                              `var(--${color[1]})`
                            );
                            document.documentElement.style.setProperty(
                              "--secondary",
                              `var(--${color[2]})`
                            );
                            document.documentElement.style.setProperty(
                              "--tertiary",
                              `var(--${color[3]})`
                            );
                            document.documentElement.style.setProperty(
                              "--titles",
                              `var(--${color[4]})`
                            );
                            document.documentElement.style.setProperty(
                              "--subtitles",
                              `var(--${color[4]})`
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                {errors.colors && (
                  <small className="ml-2 mb-2 text-danger d-block">
                    {errors.colors.type === "required" &&
                      "Por favor, selecione uma opção!"}
                  </small>
                )}
                <Button type="submit" className="btn btn-primary w-50">
                  Salvar
                </Button>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </>
      {
        notify.visible && (
          <AlertResponses
            title={notify.title}
            message={notify.message}
            type={notify.type}
            visible={notify.visible}
            setNotify={setNotify}
          />
        )
      }
      {progress && <ProgressBarComponent />}
    </>
  );
}

const styles = {
  thumb: {
    maxWidth: 250,
    maxHeight: 165,
  },
  imageDefault: {
    height: 200,
    width: 200,
    margin: "auto",
  },
  logo: {
    maxWidth: 200,
    maxHeight: 200,
    margin: "auto",
  },
  splashScreen: {
    width: "100%",
    margin: "auto",
    minHeight: 500,
    objectFit: "contain",
    border: "1px solid #eaeaea",
    boxShadow: "0px 12px 23px 0px rgb(62 63 122 / 4%)",
  },
  info: {
    fontSize: "1.25rem",
    verticalAlign: "sub",
  },
};
