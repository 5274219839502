import React from "react";
import ReactApexChart from "react-apexcharts";

export default function TwoCharts({label1,label2, data1, data2, categories}) {

  const options = {
    series: [
      {
        name: label1,
        data: data1
      },
      {
        name: label2,
        data: data2
      }
    ],
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: false
    },

    stroke: {
      width: [2, 3],

    },

    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
      }
    },
    markers: {
      shape: "circle",
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: "#3e4954",
          fontSize: "13px",
          fontFamily: "Poppins",
          fontWeight: 100,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      crosshairs: {
        show: false,
      },
    },

    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val + " R$"
            }
          }
        }
      ]
    },


  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={options.series}
        type="area"
        height={350}
      />
    </div>
  );
}