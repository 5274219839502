import { api } from '../api/Middleware'
import { USERS_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetOne = async (id, setUser, setValue, setIsLoading) => {
    try {
        let response = await api.get(USERS_URL + id + '/');
        if (response.status === 200) {
            setValue('birthday', response.data.birthday)
            setValue('phone', response.data.phone)
            setValue('cpf', response.data.cpf)

            setUser(response.data);
        }
    } catch (error) {
        //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
        /*  if(error.status !== 401){
             
             
         }  */
    }
    setIsLoading(false)
}

export const GetAll = async (setUsers, setErrors, setIsLoading) => {

    try {
        let response = await api.get(USERS_URL + 'get_superusers/');
        if (response.status === 200) setUsers(response.data);
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
    }

    setIsLoading(false);
}

export const Create = async (data, setProduct, setNotify, setError) => {
    try {
        let response = await api.post(USERS_URL, data);
        if (response.status === 200) {
            setProduct(response.data);
            setNotify({ title: 'Sucesso!', message: 'Usuário cadastrado com sucesso!', type: 'success', visible: true });
            setTimeout(() => { }, [
                window.location.href = APP_BASE_URL + 'users'
            ], 3000)

        }

    } catch (error) {
        if (error.response.status === 400) {
            setError("name", {
                "message": error.response.data,
                "type": "custom"
            })
        } else if (error.response.status === 401) {
            window.location.href = APP_BASE_URL
        } else {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.categories.update, type: 'error', visible: true });
        }
        //if(error.status !== 401) throw error.response;

    }
}

export const Update = async (id, data, setUser, setNotify) => {
    try {
        let response = await api.put(USERS_URL + id + '/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Usuário atualizado com sucesso!', type: 'success', visible: true });
            setTimeout(() => { }, [
                window.location.href = APP_BASE_URL + 'users'
            ], 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }
}

export const ChangeStatus = async (data, setNotify, setProgress) => {
    setProgress(true);
    try {
        let response = await api.put(USERS_URL + data.id + '/', { "is_active": parseInt(data.status) });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: "Status atualizado com sucesso!", type: 'success', visible: true })
            setProgress(false);
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        setProgress(false);
        return false;
    }

}

export const Delete = async (id, setNotify, setProgress) => {
    setProgress(true);

    try {
        let ids = [id];

        let response = await api.delete(USERS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }

    setProgress(false);
}