import React, { useEffect, useState, useRef } from "react";
import { Create, Update } from '../services/CouponsServices'
import { GetAll } from '../services/CategoriesServices'
import { useForm } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { Button } from "react-bootstrap";
import DefaultLoader from '../jsx/components/Loader'
import Select from 'react-select'
import MetarialDateAndTime from "../jsx/components/Forms/Pickers/MetarialDateAndTime";
import { DateTimeToISOFormat } from "../helpers/Helpers";

export default function CouponDetails(props) {
  const [coupon, setCoupon] = useState(null);
  const [categories, setCategories] = useState(null);
  const [name, setName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [erros, setErros] = useState(null)
  const [progress, setProgress] = useState(false);
  const [status, setStatus] = useState('');
  const { register, handleSubmit, watch, setError, formState: { errors } } = useForm();
  const options = useRef([]);
  const category_selected = useRef(null);

  const MAX_DISCOUNT = 100;

  const GetCategories = async () => {
    let response = await GetAll(null, null, null, true);
    response.filter(categorie => options.current.push({ value: categorie.id, label: categorie.name }))
    setCategories(response)
  }

  useEffect(() => {
    if (props.data !== null || props.data !== '') {
      setCoupon(props.data);
      category_selected.current = props.data.category;
    }
    GetCategories()
  }, []);


  const maxDiscount = ({ value }) => value <= MAX_DISCOUNT;

  const onSubmit = (data) => {

    let percent = document.querySelector('input[name="discount"]').value;
    let expiration = document.querySelector('input[name="expiration"]').value;

    if (percent === '' || percent === null) {
      setError("discount", {
        "message": 'Por favor, preencha este campo!',
        "type": "required"
      })
      return;
    }

    if (expiration === '' || expiration === null) {
      setError("expiration", {
        "message": 'Por favor, preencha corretamente este campo!',
        "type": "required"
      })
      return;
    }

    data.name = data.name.toUpperCase().replace(' ', '')
    data.discount = percent.replace('%', '')
    data.category = category_selected.current
    data.expiration = DateTimeToISOFormat(expiration)
    data.status = 1;

    if (props.data.id !== undefined) {
      Update(props.data.id, data, setCoupon, props.setNotify, setIsLoading);
    } else {
      Create(data, props.setNotify, setError, setIsLoading);
    }
  }

  return (
    <div className="basic-form">
      {coupon !== null &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Nome</label>
              <input
                {...register("name", { required: true, maxLength: 20 })}
                className="form-control text-black"
                defaultValue={coupon !== null ? coupon.name : ''}
              />
              {errors.name &&
                <small className="ml-2 text-danger">
                  {errors.name.type === 'required'
                    ? 'Por favor, preencha este campo!'
                    : 'Este campo deve conter no máximo 20 caracteres!'}</small>}
            </div>
            <div className="form-group col-md-12">
              <label>Categoria</label>
              <Select
                name="category"
                options={options.current} defaultValue={coupon !== null && coupon.category !== undefined ? { value: coupon.category, label: coupon.category_name } : ''}
                onChange={(e) => {
                  category_selected.current = e.value
                }}
                className="rounded-form-control"
                placeholder="Selecione uma opção..."
              />
            </div>
            <div className="form-group col-md-12">
              <label>Desconto</label>
              <NumberFormat
                defaultValue={coupon !== null && coupon.discount !== undefined ? coupon.discount : ''}
                decimalSeparator=","
                suffix="%"
                isAllowed={maxDiscount}
                className="form-control text-black"
                {...register("discount")}
              />

            </div>
            {errors.discount &&
              <small className="ml-2 mb-2 text-danger">
                {errors.discount.type === 'required' && 'Por favor, preencha este campo!'}</small>}
            <div className="form-group col-md-12">
              <label>Regras</label>
              <textarea
                className="form-control text-black"
                rows="4"
                defaultValue={coupon !== null ? coupon.rules : ''}
                {...register("rules", { required: true })}
              ></textarea>
            </div>
            {errors.rules &&
              <small className="ml-2 mb-2 text-danger">
                {errors.rules.type === 'required' && 'Por favor, preencha este campo!'}</small>}

          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Expiração</label>
              <MetarialDateAndTime props={{ name: 'expiration', date: coupon !== null ? coupon.expiration : undefined }} />
            </div>
            {errors.status &&
              <small className="ml-2 mb-2 text-danger">
                {errors.expiration.type === 'required' && 'Por favor, preencha corretamente este campo!'}</small>}
          </div>
          <div className="form-group col-md-12">
            <label>Limite</label>
            <input
              {...register("limit")}
              className="form-control text-black"
              defaultValue={coupon !== null ? coupon.limit : ''}
              type="number"
            />
          </div>
          <Button type="submit" className="btn btn-block btn-primary">
            {isLoading ? <DefaultLoader color={'white'} /> : 'Salvar'}
          </Button>
        </form>
      }
    </div>
  );
};
