import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";
import { Dropdown, Nav, Tab } from "react-bootstrap";

import { Get } from '../services/HomeServices'
import NumberFormat from 'react-number-format';
import DefaultLoader from '../jsx/components/Loader'
import ErrorsComponent from '../jsx/components/Errors'
import { Hash } from "../helpers/Helpers";
import UserContext from '../contexts/UserContext'

const RadialBarChart = loadable(() =>
  pMinDelay(import("../jsx/components/Sego/Home/RadialBarChart"), 1000)
);

export default function Home() {

  const {user} = useContext(UserContext);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const data_dashboard = useRef(null);
  const data_by_month = useRef(null);
  const data_by_week = useRef(null);
  const data_by_day = useRef(null);
  const data_most_orders = useRef(null);
  const history = useHistory();
  let counter = 0;

  useEffect(() => {
    Get(
      data_dashboard,
      data_by_month,
      data_by_week,
      data_by_day,
      data_most_orders,
      setErrors,
      setIsLoading);
  }, [])

  /* useEffect(() => {
    if(data_by_month.current !== null){
      console.log(data_by_month.current[0].data.data_by_month)
    }
    
  }, [data_by_month]) */


  return (
    <>
      {/* <div className="modal fade" id="addOrderModalside">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Menus</h5>
              <button type="button" className="close" data-dismiss="modal">
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="text-black font-w500">Food Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Order Date</label>
                  <input type="date" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Food Price</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <button type="button" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
       */}
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <div className="row">
            {
              errors !== null && <ErrorsComponent args={errors} />
            }
            <div className="col-xl-3 col-xxl-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <div className="media-body mr-2">
                      <h1 className="text-primary font-w600">{data_dashboard.current.orders_total}</h1>
                      <span className="ml-0">Pedidos</span>
                    </div>
                    <span className="mr-3 special-icon">
                      <i className="ti-receipt text-primary h1"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <div className="media-body mr-2">
                      <h1 className="text-primary font-w600">{data_dashboard.current.products_total}</h1>
                      <span className="ml-0">Produtos</span>
                    </div>
                    <span className="mr-3 special-icon">
                      <i className="ti-shopping-cart-full text-primary h1"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <div className="media-body mr-2">
                      <h1 className="text-primary font-w600">
                        <NumberFormat
                          displayType="text"
                          decimalScale={2}
                          defaultValue={data_dashboard.current.orders_total_value === null ? 0 : data_dashboard.current.orders_total_value}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$"
                          fixedDecimalScale={true}
                        />
                      </h1>
                      <span className="ml-0">Vendas</span>
                    </div>
                    <span className="mr-3 special-icon">
                      <i className="ti-money text-primary h1"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">

                    <div className="media-body mr-2">
                      <h1 className="text-primary font-w600">{data_dashboard.current.users_total}</h1>
                      <span className="ml-0">Clientes</span>
                    </div>
                    <span className="mr-3 special-icon">
                      <i className="ti-user text-primary h1"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card">
                <Tab.Container defaultActiveKey="monthly">
                  <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                    <div className="mr-auto pr-3">
                      <h4 className="text-black fs-20">Resumo dos pedidos</h4>
                    </div>
                    <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                      <Nav as="ul" className="nav nav-tabs" role="tablist">
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link
                            className="nav-link"
                            eventKey="monthly"
                            role="tab"
                          >
                            Mensal
                      </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link
                            className="nav-link"
                            eventKey="weekly"
                            role="tab"
                          >
                            Semanal
                      </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link
                            className="nav-link"
                            eventKey="today"
                            role="tab"
                          >
                            Hoje
                      </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tab.Content className="tab-content">
                      <Tab.Pane
                        eventKey="monthly"
                        className="tab-pane fade"
                        id="Monthly"
                      >
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            <RadialBarChart series={data_by_month.current.orders_total_value_by_month === null ? 0 : Math.round((data_by_month.current.orders_total_value_by_month / data_dashboard.current.orders_total_value) * 100)} />
                            <p className="fs-14 mt-n5 text-center">
                              Percentual do mês em relação ao total de receita.
                            </p>
                          </div>
                          <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                            <h3 className="fs-28 text-black font-w600">
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={data_by_month.current.orders_total_value_by_month === null ? 0 : data_by_month.current.orders_total_value_by_month}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />
                            </h3>
                            {/* <span className="mb-3 d-block">from $500,000.00</span> */}
                            <p className="fs-14">
                              Total de receita do mês atual.
                            </p>
                            <Link
                              to="post-details"
                              className="btn btn-primary light btn-rounded"
                            >
                              Mais detalhes
                        </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 mb-md-0 mb-3">
                            <div className="p-3 border rounded">
                              <h3 className="fs-32 text-black font-w600 mb-1">
                                {data_by_month.current.orders_total_by_month}
                              </h3>
                              <span className="fs-16">Pedidos</span>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-md-0 mb-3">
                            <div className="p-3 border rounded">
                              <h3 className="fs-32 text-black font-w600 mb-1">
                                <NumberFormat
                                  displayType="text"
                                  decimalScale={2}
                                  defaultValue={data_by_month.current.medium_price_by_month === null ? 0 : data_by_month.current.medium_price_by_month}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$"
                                  fixedDecimalScale={true}
                                />
                              </h3>
                              <span className="fs-16">Ticket Médio</span>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="weekly"
                        className="tab-pane fade"
                        id="Weekly"
                      >
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            <RadialBarChart series={ data_by_week.current.orders_total_value_by_week !== null ? Math.round((data_by_week.current.orders_total_value_by_week / data_by_month.current.orders_total_value_by_month) * 100) : 0} />
                            <p className="fs-14 mt-n5 text-center">
                              Percentual da semana em relação ao total do mês.
                            </p>
                          </div>
                          <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                            <h3 className="fs-28 text-black font-w600">
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={data_by_week.current.orders_total_value_by_week === null ? 0 : data_by_week.current.orders_total_value_by_week}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />

                            </h3>
                            <p className="fs-14">
                              Total de receita dos últimos 7 dias.
                            </p>
                            <Link
                              to="post-details"
                              className="btn btn-primary light btn-rounded"
                            >
                              Mais detalhes
                        </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 mb-md-0 mb-3">
                            <div className="p-3 border rounded">
                              <h3 className="fs-32 text-black font-w600 mb-1">
                                {data_by_week.current.orders_total_by_week}
                              </h3>
                              <span className="fs-18">Pedidos</span>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-md-0 mb-3">
                            <div className="p-3 border rounded">
                              <h3 className="fs-32 text-black font-w600 mb-1">
                                <NumberFormat
                                  displayType="text"
                                  decimalScale={2}
                                  defaultValue={data_by_week.current.medium_price_by_week === null ? 0 : data_by_week.current.medium_price_by_week}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$"
                                  fixedDecimalScale={true}
                                />
                              </h3>
                              <span className="fs-18">Ticket Médio</span>
                            </div>
                          </div>

                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="today"
                        className="tab-pane fade"
                        id="Today"
                      >
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            <RadialBarChart series={data_by_day.current.orders_total_value_by_day !== null ? Math.round((data_by_day.current.orders_total_value_by_day / data_by_week.current.orders_total_value_by_week) * 100) : 0} />
                            <p className="fs-14 mt-n5 text-center">
                              Percentual do dia em relação ao total da semana.
                            </p>
                          </div>
                          <div className="col-sm-6 mb-sm-0 mb-3 text-center">
                            <h3 className="fs-28 text-black font-w600">
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                defaultValue={data_by_day.current.orders_total_value_by_day === null ? 0 : data_by_day.current.orders_total_value_by_day}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                fixedDecimalScale={true}
                              />
                            </h3>

                            <p className="fs-14">
                              Total de receita hoje.
                            </p>
                            <Link
                              to="post-details"
                              className="btn btn-primary light btn-rounded"
                            >
                              Mais detalhes
                        </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 mb-md-0 mb-3">
                            <div className="p-3 border rounded">
                              <h3 className="fs-32 text-black font-w600 mb-1">
                                {data_by_day.current.orders_total_by_day}
                              </h3>
                              <span className="fs-18">Pedidos</span>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-md-0 mb-3">
                            <div className="p-3 border rounded">
                              <h3 className="fs-32 text-black font-w600 mb-1">
                                <NumberFormat
                                  displayType="text"
                                  decimalScale={2}
                                  defaultValue={data_by_day.current.medium_price_by_day === null ? 0 : data_by_day.current.medium_price_by_day}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$"
                                  fixedDecimalScale={true}
                                />
                              </h3>
                              <span className="fs-18">Ticket Médio</span>
                            </div>
                          </div>

                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card trending-menus">
                    <div className="card-header d-sm-flex d-block pb-0 border-0">
                      <div>
                        <h4 className="text-black fs-20">Mais pedidos da semana</h4>
                        {/* <p className="fs-13 mb-0 text-black">Lorem ipsum dolor</p> */}
                      </div>
                    </div>
                    <div className="card-body">
                      {
                        
                        data_most_orders.current !== null ?
                        data_most_orders.current.map((order, i) => {
                          counter++;
                          return (

                            <div className="d-flex pb-3 mb-3 border-bottom tr-row align-items-center" key={i}>
                              <span className="num">#{counter}</span>
                              <div className="mr-auto pr-3">
                                <Link to="post-details">
                                  <h2 className="text-black fs-14">
                                    {order.name}
                                  </h2>
                                </Link>
 
                                <span className="fs-14 text-primary">Pedido {order.quantity}x</span>
                              </div>
                              <img
                                src={Hash('75x75', order.image)}
                                alt="menu9"
                                width={60}
                                className="rounded"
                              />
                            </div>
                          )
                        })
                        :
                        'Nenhum pedido até o momento.'
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
      }
    </>
  );
}