import React, { useEffect, useState, createRef, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Create, Update, GetOne, GetAll } from '../services/PaymentMethodsServices'
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { Theme } from '../App'
import Errors from '../jsx/components/Errors'
import AlertResponses from '../jsx/components/Alert'
import { useForm } from "react-hook-form";
import Select from 'react-select'
import { Button } from 'react-bootstrap'
import ProgressBarComponent from '../jsx/components/ProgressBar'
import { IMAGES_URL } from "../api/Urls";

export default function PaymentMethodsDetails(props) {
  const { id } = useParams();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [methodsAvailable, setMethodsAvailable] = useState(null);
  const [name, setName] = useState(null);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const imageRef = createRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [erros, setErros] = useState(null)
  const [progress, setProgress] = useState(false)

  const [status, setStatus] = useState('');
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const { register, handleSubmit, watch, setError, setValue, formState: { errors } } = useForm();
  const options = useRef([]);
  const thumbs = ['american-express.png', 'dinheiro.png', 'mastercard.png', 'visa.png', 'alelo.png', 'sodexo.png'];

  useEffect(() => {
    if (id !== undefined) {
      GetOne(id, setPaymentMethods, setIsLoading);
    } else {
      setPaymentMethods('')
      setIsLoading(false)
    }

    if (props.location.methodsAvailable !== (null || undefined)) {
      setMethodsAvailable(props.location.methodsAvailable)
    } else {
      GetAll(setMethodsAvailable, setErros, setIsLoading);
    }
    options.current = [
      {
        label: "À vista",
        value: "À vista"
      },
      {
        label: "Débito",
        value: "Débito"
      },
      {
        label: "Crédito",
        value: "Crédito"
      },
      {
        label: "Refeição",
        value: "Refeição"
      },
      {
        label: "Alimentação",
        value: "Alimentação"
      },
    ]
  }, []);

  const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 20%;
`;

  const onSubmit = (data) => {

    let operation = document.querySelector("input[name='operation']").value
    if (operation === '' || operation === null) {
      setError("operation", {
        "message": 'Por favor, selecione uma opção!',
        "type": "required"
      })
      return;
    }

    if (id !== undefined) {
      Update(id, data, setPaymentMethods, setNotify, setProgress);
    } else {
      Create(data, setPaymentMethods, setNotify, setError, setProgress);
    }

  }

  const Thumbs = useMemo(() => {
    return (
      thumbs.map(thumb =>
        <div className="col-xl-6 col-lg-6 col-md-6 col-xxl-6 mb-4 d-inline-block text-center">
          <img src={`https://${IMAGES_URL}payment_methods/${thumb}`} width="40" />
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              defaultValue={paymentMethods !== null && paymentMethods.thumb === thumb ? paymentMethods.thumb : thumb}
              defaultChecked={paymentMethods !== null && paymentMethods.thumb === thumb ? true : false}
              {...register("thumb", { required: true })}
            />
            <label className="form-check-label">
              Selecionar
            </label>
          </div>

        </div>
      ))
  }, [thumbs])

  return (
    <>
      <div className="row">
        {
          isLoading ? <BeatLoader color={Theme.colors.primary} loading={isLoading} css={override} size={12} />
            :
            <div className="col-lg-12">
              {
                paymentMethods !== null &&

                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-2 col-lg-4 col-md-4 col-xxl-4">
                        <h3 className="m-4 text-center">Ícone</h3>
                        {Thumbs}
                        {errors.thumb &&
                          <small className="ml-4 mb-2 text-danger">
                            {errors.thumb.type === 'required' && 'Por favor, selecione uma ícone!'}</small>}
                      </div>

                      <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="">{paymentMethods !== null ? paymentMethods.name : 'Nova Categoria'}</h3>
                          </div>
                          <div className="card-body">
                            <div className="basic-form">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label>Nome</label>
                                    <input
                                      {...register("name", { required: true, maxLength: 20 })}
                                      className="form-control text-black"
                                      defaultValue={paymentMethods !== null ? paymentMethods.name : ''}
                                    />
                                    {errors.name &&
                                      <small className="ml-2 text-danger">
                                        {errors.name.type === 'required' && 'Por favor, preencha este campo!'}
                                        {errors.name.type === 'maxLength' && 'Este campo deve conter no máximo 20 caracteres!'}
                                        {errors.name.type === 'custom' && errors.name.message}
                                      </small>}
                                  </div>

                                  {errors.price &&
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.price.type === 'required' && 'Por favor, preencha este campo!'}</small>}

                                </div>
                                <div className="form-group">
                                  <label>Operação</label>
                                  <Select
                                    name="operation"
                                    options={options.current}
                                    defaultValue={paymentMethods !== (null || '') ? { value: paymentMethods.operation, label: paymentMethods.operation } : ''}
                                    onChange={(e) => setValue('operation', e.value)}
                                    className="rounded-form-control"
                                    placeholder="Selecione uma opção..."
                                  />
                                  {errors.operation &&
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.operation.type === 'required' && 'Por favor, selecione uma opção!'}</small>}
                                </div>

                                <div className="form-group">
                                  <label>Tipo</label>
                                  <div className="form-check mb-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      defaultValue="Dinheiro"
                                      defaultChecked={paymentMethods !== null && paymentMethods.type === 'Dinheiro' ? true : false}
                                      {...register("type", { required: true })}
                                    />
                                    <label className="form-check-label">
                                      Dinheiro
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      defaultValue="Cartão"
                                      defaultChecked={paymentMethods !== null && paymentMethods.type === 'Cartão' ? true : false}
                                      {...register("type", { required: true })}
                                    />
                                    <label className="form-check-label">
                                      Cartão
                                    </label>
                                  </div>
                                  {errors.type &&
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.type.type === 'required' && 'Por favor, selecione uma opção!'}</small>}
                                </div>

                                <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label>Status</label>
                                    <select
                                      className="form-control text-black"
                                      defaultValue={paymentMethods !== null ? paymentMethods.status : ''}
                                      {...register("status", { required: true })}
                                    >
                                      <option value={1}>Ativo</option>
                                      <option value={0}>Desativado</option>
                                    </select>
                                  </div>
                                  {errors.status &&
                                    <small className="ml-2 mb-2 text-danger">
                                      {errors.status.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                                </div>
                                <Button type="submit" className="btn btn-block btn-primary">
                                  Salvar
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              }
              {progress && <ProgressBarComponent />}
              {
                notify.visible &&
                <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
              }
            </div>
        }
      </div>
    </>
  );
};

const styles = {
  image: {
    maxWidth: 330,
    maxHeight: 250,
    width: '100%',
    objectFit: 'cover'
  }
}
