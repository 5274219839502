import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Hash } from '../../../../helpers/Helpers'
import NumberFormat from 'react-number-format';

const OrderIdData = (args) => {
  const [data, setData] = useState(args.order);

  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive order-list card-table">
            <table className="table items-table table-responsive-md">
              <tbody>
                <tr>
                  <th className="my-0 text-black font-w500 fs-20">Pedido #{data.id_order}</th>
                  <th
                    style={{ width: "10%" }}
                    className="my-0 text-black font-w500 fs-20"
                  >
                    Qtd
                  </th>
                  <th
                    style={{ width: "10%" }}
                    className="my-0 text-black font-w500 fs-20"
                  >
                    Preço
                  </th>

                  <th />
                </tr>
                {data &&
                  data.order.map((data, index) => (
                    <tr
                      key={index}
                      className="alert alert-dismissible border-0"
                    >
                      <td>
                        <div className="media">
                          <Link to="ecom-product-detail">
                            <img
                              className="mr-3 img-fluid rounded"
                              width={85}
                              src={Hash('85x85', data.image)}
                              alt="Product5"
                            />
                          </Link>
                          <div className="media-body">
                            <small className="mt-0 mb-1 font-w500">
                              <Link className="text-primary" to="#">
                                {data.categorie_name}
                              </Link>
                            </small>
                            <h5 className="mt-0 mb-2 mb-4">
                              <Link
                                className="text-black mb-2"
                                to="ecom-product-detail"
                              >
                                {data.name}
                              </Link>
                              {
                                data.variationData &&
                                data.variationData.map((v, i) =>
                                  <div key={i}>
                                    <small>{v.groupTitle}: {v.value.name}</small>
                                  </div>
                                )
                              }
                              {
                                data.observations !== (null || '') &&

                                <div className="mt-3">
                                  Observações:
                                  <p>{data.observations}</p>
                                </div>
                              }
                            </h5>
                            {/* <div className="star-review fs-14">
                              {data.star.map((icon, index) => {
                                return (
                                  <i
                                    key={index}
                                    className={`${
                                      icon === 1
                                        ? "fa fa-star text-orange mr-1"
                                        : "fa fa-star text-gray mr-1"
                                    }`}
                                  />
                                );
                              })}
                              <span className="ml-3 text-dark">
                                ({data.reviews} revies)
                              </span>
                            </div> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <h4 className="my-0 text-black font-w600">
                          {data.quantity}x
                        </h4>
                      </td>
                      <td>
                        <h4 className="my-0 text-black font-w600">
                          <NumberFormat
                            displayType="text"
                            decimalScale={2}
                            defaultValue={data.finalPrice}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            fixedDecimalScale={true}
                          />
                        </h4>
                      </td>
                    </tr>

                  ))}
                {
                  data.payment_method.changeFor != (null && undefined) &&
                  <tr>
                    <td className="d-flex">
                      <div className="mr-3">
                        <h4>Troco para:</h4>
                        <h4 className="my-0 text-black font-w600">
                          <NumberFormat
                            displayType="text"
                            decimalScale={2}
                            defaultValue={data.payment_method.changeFor}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            fixedDecimalScale={true}
                          />
                        </h4>
                      </div>
                    </td>
                  </tr>
                }

                <tr>
                  <td className="d-flex">
                    <div className="mr-3">
                      <h4>Subtotal</h4>
                      <h4 className="my-0 text-black font-w600">
                        <NumberFormat
                          displayType="text"
                          decimalScale={2}
                          defaultValue={data.subtotal}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$"
                          fixedDecimalScale={true}
                        />
                      </h4>
                    </div>
                    <div className="mr-3">
                      <h4>Entrega</h4>
                      <h4 className="my-0 text-black font-w600">
                        <NumberFormat
                          displayType="text"
                          decimalScale={2}
                          defaultValue={data.deliveryFee}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$"
                          fixedDecimalScale={true}
                        />
                      </h4>
                    </div>
                    {
                      data.order[0].coupon !== undefined &&

                      <div>
                        <h4>Cupom: <small className="text-primary">{data.order[0].coupon.couponName.toUpperCase()}</small></h4>
                        <h4 className="my-0 text-black font-w600">-
                          <NumberFormat
                            displayType="text"
                            decimalScale={2}
                            defaultValue={data.order[0].coupon.discount}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            fixedDecimalScale={true}
                          />
                        </h4>
                      </div>
                    }
                  </td>
                  <td>
                    {data.order[0].coupon !== undefined &&
                      <>
                        <h4>Total <span className="badge badge-success light align-middle" onMouseEnter={() => {
                          document.getElementsByClassName('totalWithCoupon')[0].classList.remove('d-none')
                        }}
                          onMouseLeave={() => {
                            document.getElementsByClassName('totalWithCoupon')[0].classList.add('d-none')
                          }}>L</span></h4>
                        <h4 className="my-0 text-black font-w600">
                          <NumberFormat
                            displayType="text"
                            decimalScale={2}
                            defaultValue={data.total}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            fixedDecimalScale={true}
                          />
                        </h4>
                        <small className="totalWithCoupon d-none" style={{
                          position: 'absolute',
                          background: 'rgba(0,0,0,.9)',
                          color: '#fff',
                          padding: '5px',
                          borderRadius: '5px',
                          top: 0,
                          cursor: 'pointer'

                        }}>Total com cupom</small>
                      </>
                    }
                  </td>
                  <td>
                    <h4>Total <span className="badge badge-success light align-middle" onMouseEnter={() => {
                      document.getElementsByClassName('totalWithoutCoupon')[0].classList.remove('d-none')
                    }}
                      onMouseLeave={() => {
                        document.getElementsByClassName('totalWithoutCoupon')[0].classList.add('d-none')
                      }}>B</span></h4>
                    <h4 className="my-0 text-black font-w600">
                      <NumberFormat
                        displayType="text"
                        decimalScale={2}
                        defaultValue={data.gross_total}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$"
                        fixedDecimalScale={true}
                      />

                    </h4>
                    <small className="totalWithoutCoupon d-none" style={{
                      position: 'absolute',
                      background: 'rgba(0,0,0,.9)',
                      color: '#fff',
                      padding: '5px',
                      borderRadius: '5px',
                      top: 0,
                      cursor: 'pointer'

                    }}>Total sem cupom</small>
                  </td>
                  <td></td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderIdData;
