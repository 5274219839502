import { api, VerifyIfTokenIsExpired } from '../api/Middleware'
import { LOGIN_URL, USERS_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'
import jwt_decode from "jwt-decode";


export const HasToken = async (setUser, setLogged, history) => {

    /* 
    *Verifica se tem o token e se está válido
    */
    try {
        let user = localStorage.getItem("user");
        if (user !== null) {
            user = JSON.parse(user)
            let tokenExpired = VerifyIfTokenIsExpired(user.access);

            if (!tokenExpired) {
                setLogged(true);
                setUser(user);
                history.push('/');
            } else {
                localStorage.removeItem('user');
                history.push('/login');
            }
        }
    } catch (error) {
        console.log(error)
    }

}

export const Auth = async (data, setNotify, setIsLoading) => {
    try {
        let response = await api.post(LOGIN_URL, { email: data.email });
        if (response.status === 200) {
            let token = jwt_decode(response.data.access)
            if (!token.is_superuser) {
                return { status: 404, data: 'Você não tem permissão para acessar esta área.' }
            } else {

                delete response.data.refresh;
                localStorage.setItem('token', response.data.access);
                delete response.data.access;
                response.data.user_photo = data.profilePicURL
                localStorage.setItem('user', JSON.stringify(response.data));
                return response.data;
            }
        }
    } catch (error) {
        return error.response;
        //setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }
}

export const Logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = APP_BASE_URL + 'login'
}