import { api } from '../api/Middleware'
import { PAYMENT_METHODS_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetOne = async (id, setCategorie, setIsLoading) => {
    try {
        let response = await api.get(PAYMENT_METHODS_URL + id + '/');
        if (response.status === 200) setCategorie(response.data);
    } catch (error) {
        //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
        /*  if(error.status !== 401){
             
             
         }  */
    }

    //setIsLoading(false)

}

export const GetAll = async (setPaymentMethods, setErrors, setIsLoading, returnData = false) => {

    try {
        let response = await api.get(PAYMENT_METHODS_URL);
        if (response.status === 200) {
            if (returnData !== false) return response.data;
            setPaymentMethods(response.data);
        }
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
    }

    setIsLoading(false)
}

export const Create = async (data, setProduct, setNotify, setError, setProgress) => {
    setProgress(true);
    try {
        let response = await api.post(PAYMENT_METHODS_URL, data);
        if (response.status === 200 || response.status === 201) {
            setProduct(response.data);
            setNotify({ title: 'Sucesso!', message: 'Forma de pagamento cadastrada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.history.back()
            },2000)
        }

    } catch (error) {
        if (error.response.status === 400) {
            setError("name", {
                "message": error.response.data,
                "type": "custom"
            })
        } else if (error.response.status === 401) {
            window.location.href = APP_BASE_URL
        } else {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.categories.update, type: 'error', visible: true });
        }
        //if(error.status !== 401) throw error.response;

    }
    setProgress(false);
}

export const Update = async (id, data, setProduct, setNotify, setProgress) => {
    setProgress(true);
    try {
        let response = await api.put(PAYMENT_METHODS_URL + id + '/', data);
        if (response.status === 200) {
            setProduct(response.data);
            setNotify({ title: 'Sucesso!', message: 'Forma de pagamento atualizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.history.back()
            },2000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }
    setProgress(false);
}

export const ChangeStatus = async (data, setNotify) => {
    try {
        let response = await api.put(PAYMENT_METHODS_URL + data.id + '/', { "status": parseInt(data.status) });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: "Status atualizado com sucesso!", type: 'success', visible: true })
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        return false;
    }
}

export const Delete = async (id, setNotify, setProgress) => {
    setProgress(true);

    try {
        let ids = [id];

        let response = await api.delete(PAYMENT_METHODS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }

    setProgress(false);
}