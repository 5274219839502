import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import DataTablev2 from '../jsx/components/Datatable'
import { GetAll, Delete } from '../services/ProductsServices'
import { Hash } from '../helpers/Helpers'
import NumberFormat from 'react-number-format';
import DefaultLoader from '../jsx/components/Loader'
import Swal from "sweetalert2";
import AlertResponses from '../jsx/components/Alert'

export default function Products() {

  const [products, setProducts] = useState('');
  const [categories, setCategories] = useState('');
  const [erros, setErros] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });

  useEffect(() => {
    GetAll(setProducts, setCategories, setErros, setIsLoading);
  }, [])

  useEffect(() => {
    let isReady = false;
    if (!isReady && products !== '') setIsLoading(false);
    return () => { isReady = true }
  }, [products])

  const deleteRows = (state) => {
    if (state.selectedRows !== (null || undefined) && state.selectedRows.length > 0) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  }

  const actions = (row) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="" className="table-dropdown i-false">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>

          <Link to={{
            pathname: `/ecom-product-detail/${row.id}`,
            product: row,
          }} className="text-danger dropdown-item">Editar</Link>
          <Dropdown.Item onClick={() => ''} className="text-danger">
            Desativar
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  const columns = [
    {
      name: '#',
      selector: (row) => <img src={Hash('100x80', row.image)} className="products-thumb" />,
      sortable: true,
      maxWidth: '10%'
    },
    {
      name: 'Nome',
      selector: (row) => <Link to={{
        pathname: `/ecom-product-detail/${row.id}`,
        product: row,
      }} className="">{row.name}</Link>,
      sortable: true,
      maxWidth: '20%'
    },
    {
      name: 'Categoria',
      selector: (row) => {
        let categorie = categories.filter(categorie => categorie.id === row.categorie_id && categorie.name)
        return categorie[0].name;
      },
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Descrição',
      selector: (row) => row.description,
      maxWidth: '30%'
    },
    {
      name: 'Preço',
      selector: (row) => <NumberFormat
        displayType={'text'}
        value={row.price}
        thousandSeparator="."
        decimalSeparator=","
        prefix="R$"
      />,
      maxWidth: '10%'
    },
    {
      name: '',
      selector: (row) => actions(row),
      maxWidth: '5%',
      allowOverflow: true
    },
  ];

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
            <div className="row mt-4">
              <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                <div className="col-xl-4 col-xxl-5 col-sm-12">
                  <h2>Produtos</h2>
                </div>

                <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                  {showDeleteButton &&

                    <Button className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() =>
                        Swal.fire({
                          title: "Você tem certeza?",
                          text:
                            "Todos os arquivos selecionados serão deletados. Esta ação é irreversível!",
                          icon: "warning",
                          buttons: true,
                          confirmButtonText: `Continuar a ação`,
                          cancelButtonText: `Cancelar`,
                          showCancelButton: true,
                          confirmButtonColor: 'red'
                        }).then((value) => {
                          if (value) Delete(selectedRows, setNotify)
                        })
                      }
                    >
                      <span>DELETAR SELECIONADOS</span>
                    </Button>
                  }
                  <Button className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                    href="/ecom-product-detail">
                    <span>ADICIONAR</span>
                  </Button>
                </div>

              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }

            <DataTablev2
              data={products}
              columns={columns}
              title="  "
              selectable={true}
              onSelectedRowsChange={deleteRows}
              showDeleteButton={showDeleteButton}
              filterByField={{ labels: ['Nome', 'Categoria'], fields: ['name', 'categorie_name'] }}
            />
          </>
      }
    </>
  );
}