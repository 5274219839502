import React, { useEffect, useRef, useState, forwardRef } from "react";
import NumberFormat from 'react-number-format';

export const OrderToPrint = forwardRef((props, ref) => {

  const order = useRef(props.order);


  return (
    <div className="col-xl-4 col-xxl-4" id="orderToPrint" ref={ref} style={{ width: '8cm' }}>
      <div className="row flex-column border-to-print">
        <h2 className="text-center">ENTREGA</h2>
        <hr></hr>
        <h3><small>Pedido:</small> #{order.current.id_order}</h3>
        <h6>{new Date(order.current.created_at).toLocaleString()}</h6>
        <h6>{order.current.name}</h6>
        <h6>Telefone: <NumberFormat format="(##) #####-####" mask="_" displayType="text" defaultValue={order.current.phone} /></h6>
      </div>
      <div className="row flex-column border-to-print">
        <h4 className="mb-3 mt-3 text-center">ENDEREÇO</h4>
        <h6><b>{order.current.address}</b></h6>
        <h6>Complemento: <b>{order.current.complement}</b></h6>
        {
          order.current.reference != (null || '') &&
          <h6>Referência: <b>{order.current.reference}</b></h6>
        }
        {
          order.current.observations != (null || '') &&
          <h6>Observações: <b>{order.current.observations}</b></h6>
        }
      </div>
      <div className="row flex-column border-to-print">
        <h4 className="mb-3 mt-3 text-center">ITENS</h4>
        {
          order.current.order.map((data, index) => (
            <div key={index}>
              <h6>{data.quantity} {data.name}</h6>
              {
                data.variationData &&
                data.variationData.map((v, i) =>
                  <div key={i}>
                    <small>{v.groupTitle}: {v.value.name}</small>
                  </div>
                )
              }
              {
                data.observations != (undefined && '') &&

                <h6 className="mt-2 mb-3">Observações: {data.observations} </h6>
              }
              <h6 className="mt-2 mb-4 d-flex justify-content-between">Valor: {
                <NumberFormat
                  displayType="text"
                  decimalScale={2}
                  defaultValue={data.finalPrice}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$"
                  fixedDecimalScale={true}
                />
              } </h6>
            </div>
          ))}

      </div>
      <div className="row flex-column border-to-print">
        <h4 className="mb-3 mt-3 text-center">PAGAMENTO</h4>
        <h6 className="text-center mb-4">*{order.current.payment_method.pay_on === 'delivery' ? 'Pagar na entrega' : 'Pago pelo APP'}*</h6>
        <h6 className="d-flex justify-content-between"><small>Forma de pagamento:</small> {order.current.payment_method.type}</h6>
        <h6 className="d-flex justify-content-between"><small>Operação: </small>{order.current.payment_method.operation}</h6>
        {
          order.current.payment_method.type === 'Cartão' &&
          <h6 className="d-flex justify-content-between"><small>Bandeira: </small>{order.current.payment_method.name}</h6>
        }
      </div>
      <div className="row flex-column border-to-print">
        <h4 className="mb-3 mt-3 text-center">TOTAL DA COMPRA</h4>

        <h6 className="mt-1 mb-1 d-flex justify-content-between mb-4">Troco para (=){<NumberFormat
          displayType="text"
          decimalScale={2}
          defaultValue={order.current.payment_method.changeFor}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$"
          fixedDecimalScale={true}
        />} </h6>

        <h6 className="mt-1 mb-1 d-flex justify-content-between">Subtotal (=){<NumberFormat
          displayType="text"
          decimalScale={2}
          defaultValue={order.current.subtotal}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$"
          fixedDecimalScale={true}
        />} </h6>
        <h6 className="mt-1 mb-1 d-flex justify-content-between">Entrega (+) {
          <NumberFormat
            displayType="text"
            decimalScale={2}
            defaultValue={order.current.deliveryFee}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$"
            fixedDecimalScale={true}
          />
        } </h6>
        {
          order.current.order[0].coupon !== undefined &&
          <h6 className="mt-1 mb-1 d-flex justify-content-between">Desconto (-){
            <NumberFormat
              displayType="text"
              decimalScale={2}
              defaultValue={order.current.order[0].coupon.discount}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$"
              fixedDecimalScale={true}
            />
          } </h6>
        }
        <h6 className="mt-1 mb-1 d-flex justify-content-between">Total (=) {
          <NumberFormat
            displayType="text"
            decimalScale={2}
            defaultValue={order.current.total}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$"
            fixedDecimalScale={true}
          />
        } </h6>
      </div>

    </div>
  );
})

const styles = {
  photoDefault: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '130px',
    height: '130px',
    borderRadius: '75px',
    background: '#efefef',
    margin: 'auto'
  },
  i: {
    fontSize: '32px',
    color: '#3d4465'
  },
}
