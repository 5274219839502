import { api } from '../api/Middleware'
import { VW_ITEMS, PROMOTIONS_URL, CATEGORIES_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetOne = async (id, setPromotion) => {
    try {
        let response = await api.get(PROMOTIONS_URL + id + '/');
        if (response.status === 200) setPromotion(response.data);
    } catch (error) {
        //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
        /*  if(error.status !== 401){
             
             
         }  */
    }
}

export const GetAll = async (setPromotions, setCategories, setErrors, setIsLoading) => {
    let response = await api.get(VW_ITEMS);
    try {
        if (response.status === 200) {

            let products = [];
            let categories = [];

            response.data.map(value => {
                if (value.reference === 'promotions') products.push(value.data);
                if (value.reference === 'categories') categories.push(value.data);
            })

            setCategories(categories);
            setPromotions(products);
        }
    } catch (error) {
        if (error.response.status === 401) {
            window.location.href = APP_BASE_URL
        } else {
            setErrors({ title: "Oops!", message: ERROR_MESSAGES.promotions.get })
        }
    }
    setIsLoading(false)
}

export const Create = async (data, setPromotion, setNotify, setError, setProgress) => {
    setProgress(true);
    try {
        let response = await api.post(PROMOTIONS_URL, data);
        if (response.status === 200) {
            setPromotion(response.data);
            setNotify({ title: 'Sucesso!', message: 'Promoção cadastrada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.href = APP_BASE_URL + 'promotions'
            }, 2500);
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }

    setProgress(false);
}

export const Update = async (id, data, setPromotion, setNotify, setProgress) => {
    setProgress(true);
    try {
        let response = await api.put(PROMOTIONS_URL + id + '/', data);
        if (response.status === 200) {
            setPromotion(response.data);
            setNotify({ title: 'Sucesso!', message: 'Produto atualizado com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.href = APP_BASE_URL + 'promotions'
            }, 2500);
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }
    setProgress(false);
}

export const Delete = async (data, setNotify, setProgress) => {
    setProgress(true)
    try {
        let ids = [];
        data.map(product => ids.push(product.id))
        let response = await api.delete(PROMOTIONS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }
    setProgress(false)
}

export const ChangeStatus = async (data, setNotify, setProgress) => {
    setProgress(true);

    try {
        let response = await api.put(PROMOTIONS_URL + data.id + '/', { "status": parseInt(data.status) });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: "Status atualizado com sucesso!", type: 'success', visible: true })
            setProgress(false)
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        setProgress(false)
        return false;
    }

}
