import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Button, Card, Col, item } from "react-bootstrap";
import { GetAll, ChangeStatus, Delete } from '../services/PaymentMethodsServices'
import { IMAGES_URL } from '../api/Urls'
import DefaultLoader from '../jsx/components/Loader'
import Swal from "sweetalert2";
import AlertResponses from '../jsx/components/Alert'
import ErrorsComponent from '../jsx/components/Errors'
import ProgressBarComponent from '../jsx/components/ProgressBar'

export default function PaymentMethods() {

  const [paymentMethods, setPaymentMethods] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selecteditems, setSelecteditems] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [progress, setProgress] = useState(false)

  //TODO: no datatable nao filtra pelo campo created_at por causa do formato da data q no campo é 2021-08-03 e o user
  // digita 30/08/2021
  useEffect(() => {
    GetAll(setPaymentMethods, setErrors, setIsLoading);
  }, [])

  const status = {
    0: {
      id: 0,
      label: "Desativada",
      type: "light"
    },
    1: {
      id: 1,
      label: "Ativa",
      type: "success"
    },
  }

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
            {
              errors !== null && <ErrorsComponent args={errors} />
            }
            <div className="item mt-4">
              <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                <div className="col-xl-4 col-xxl-5 col-sm-12">
                  <h2>Formas de Pagamento</h2>
                </div>

                <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">

                  <Button className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                    href="/payment-method-details/">
                    <span>ADICIONAR</span>
                  </Button>
                </div>

              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }
            <>
              {
                paymentMethods.map(item => (

                  <Col xl='3' key={item.id} className="d-inline-block">
                    <Card className='mb-3 align-items-center'>
                      <img
                        className='card-img-top img-fluid mt-3'
                        src={require(`../images/payment_methods/${item.thumb}`).default}
                        style={styles.thumb}
                      />
                      <Card.Header className="m-auto flex-column">
                        <Card.Title>
                          <Link to={{ pathname: '/payment-method-details/' + item.id, methodsAvailable: paymentMethods }}>
                            {item.name}
                          </Link>
                        </Card.Title>
                        <small>{item.operation}</small>
                      </Card.Header>
                      <Card.Body className="card-body d-flex justify-content-between align-items-baseline">
                        <DropdownButton
                          variant=''
                          style={styles.dropdown}
                          title={item.status === 1 ? 'Ativa' : 'Desativada'}
                          className={`badge badge-${item.status === 1 ? 'success' : 'light'} badge-sm light badge-delivery-status badge-${item.id}`}
                          onSelect={(e) => {
                            ChangeStatus({ id: item.id, status: e }, setNotify).then(res => {
                              if (res) {
                                document.querySelector(`.badge-${item.id}`).classList.remove(`badge-${item.status === 1 ? 'success' : 'light'}`)
                                document.querySelector(`.badge-${item.id}`).classList.add(`badge-${status[e].type}`)
                                document.querySelector(`.badge-${item.id} button`).innerText = status[e].label
                              }
                            })

                          }}
                        >
                          {Object.values(status).map((value, i) =>
                            <Dropdown.Item key={i} eventKey={value.id}>{value.label}</Dropdown.Item>)}
                        </DropdownButton>
                        <DropdownButton
                          variant=''
                          style={styles.dropdown}
                          title="Ações"
                          className={`badge badge-primary badge-sm light badge-delivery-status ml-4`}
                          onSelect={(e) => {
                          }}
                        >
                          <Link to={{
                            pathname: `/payment-method-details/${item.id}`,
                            order: item,
                          }} className="text-danger dropdown-item">Editar</Link>
                          <Dropdown.Item onClick={() => Delete(item.id, setNotify, setProgress)}>Excluir</Dropdown.Item>
                        </DropdownButton>
                      </Card.Body>
                    </Card>
                  </Col>

                ))
              }
            </>
            {progress && <ProgressBarComponent />}

          </>
      }
    </>
  );
}

const styles = {
  thumb: {
    maxWidth: 80,
    maxHeight: 80
  }
}