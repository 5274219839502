import React, { useEffect, useState, useRef, useMemo } from "react";
import { Create, Update, FindPlace } from '../services/DeliveryFeeServices'
import { useForm } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { Button, Dropdown } from "react-bootstrap";
import DefaultLoader from '../jsx/components/Loader'
import AsyncSelect from 'react-select/async';

export default function DeliveryFeeDetails(props) {
	const [deliveryFee, setDeliveryFee] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState('');
	const { register, handleSubmit, watch, setError, setValue, formState: { errors } } = useForm();
	const [places, setPlaces] = useState('');
	const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		if (props.data !== null || props.data !== '') {
			setDeliveryFee(props.data);
		}
	}, []);

	const onSubmit = (value) => {

		let fee = document.querySelector('input[name="fee"]').value;

		if (fee === '' || fee === null || fee === undefined) {
			setError("fee", {
				"message": 'Por favor, preencha corretamente este campo!',
				"type": "required"
			})
			return;
		}

		value.fee = value.fee.replace('R$', '').replace(',', '.')
		if (props.data.id !== undefined) {
			Update(props.data.id, data, setDeliveryFee, props.setNotify, setIsLoading);
		} else {
			Create(data, value.fee, props.setNotify, setError, setIsLoading);
		}
	}

	const loadOptions = async (text) => {
		let response = await FindPlace(text, setNotify);
		let options = [];
		if (response) {
			response.map(v => {
				if (v.title) {
					/* let arr = {}
					v.context.map(context => {
						switch (true) {
							case context.id.includes('place'):
								arr.city = context.text
								break;
							case context.id.includes('region'):
								arr.state = context.text
								break;
	
						}
					}) */

					options.push({
						label: `${v.title}`,
						value: {
							neighborhood: v.address.district,
							city: v.address.city,
							state: v.address.state,
							zipcode: v.address.postalCode,
						}
					})
				}

			})
		}


		return options
	}


	const handleInputChange = (value) => {
		if (value.length > 3) {
			loadOptions(value);
		}
	};

	return (
		<div className="basic-form">
			{deliveryFee !== null &&
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-row">
						<div className="form-group col-md-12">
							<label></label>

							<AsyncSelect
								isMulti
								cacheOptions
								loadOptions={loadOptions}
								defaultOptions
								onInputChange={handleInputChange}
								onChange={(e) => setData(e)}
								placeholder="Digite o bairro que deseja..."
							/>
						</div>
						<div className="form-group col-md-12">
							<label>Taxa</label>
							<NumberFormat
								defaultValue={deliveryFee !== null && deliveryFee.fee !== undefined ? deliveryFee.fee : ''}
								decimalSeparator=","
								prefix="R$"
								className="form-control text-black"
								{...register("fee")}
							/>
							{errors.fee &&
								<small className="ml-2 mb-2 text-danger">
									{errors.fee.type === 'required' && 'Por favor, preencha este campo!'}</small>}
						</div>


					</div>

					<Button type="submit" className="btn btn-block btn-primary">
						{isLoading ? <DefaultLoader color={'white'} /> : 'Salvar'}
					</Button>
				</form>
			}
		</div>
	);
};
