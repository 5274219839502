import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
    var handleheartBlast = document.querySelector('.heart');
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }

  }
  //Modals
  state = {
    show: false,
  };
  Showhandler() {
    this.setState({ show: true });
  }
  Hidehandler() {
    this.setState({ show: false });
  }

  forceCloseMenu() {
    if (window.innerWidth < 720) {
      document.querySelector('.nav-control').click()
    }
  }

  render() {
    const date = new Date();
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let
      orders = [
        "orders"
      ],
      categories = [
        "categories"
      ],
      variations = [
        "variations",
      ],
      products = [
        "products",
      ],
      promotions = [
        "promotions"
      ],
      coupons = [
        "coupons"
      ],
      payment_methods = [
        "payment_methods"
      ],
      analytics = [
        "analytics"
      ],
      general_customers = [
        "general-customers"
      ],
      delivery_fee = [
        "delivery-fee"
      ],
      customizations = [
        "customizations"
      ],
      ifoodIntegration = [
        "ifood-integration"
      ],
      users = [
        "users"
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];


    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li>
              <a className="" href={window.location.origin.replace('admin.', '')}
                target="_blank"
                aria-expanded="false">
                <i className="ti-link"></i>
                <span className="nav-text">Acessar site</span>
              </a>
            </li>
            <li className={`${orders.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/orders" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-receipt"></i>
                <span className="nav-text">Pedidos</span>
              </Link>
            </li>
            <li className={`${categories.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/categories" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-view-grid"></i>
                <span className="nav-text">Categorias</span>
              </Link>
            </li>
            <li className={`${variations.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/variations" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-view-list-alt"></i>
                <span className="nav-text">Variações</span>
              </Link>
            </li>
            <li className={`${products.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/products" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-shopping-cart-full"></i>
                <span className="nav-text">Produtos</span>
              </Link>
            </li>
            <li className={`${promotions.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/promotions" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="las la-percentage" style={styles.icon}></i>
                {/*  <i className="ti-ticket"></i> */}
                <span className="nav-text">Promoções</span>
              </Link>
            </li>
            <li className={`${coupons.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/coupons" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-ticket"></i>
                <span className="nav-text">Cupons</span>
              </Link>
            </li>
            <li className={`${general_customers.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/general-customers" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-user"></i>
                <span className="nav-text">Clientes</span>
              </Link>
            </li>
            <li className={`${payment_methods.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/payment-methods" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-credit-card"></i>
                <span className="nav-text">Formas de pagamento</span>
              </Link>
            </li>
            <li className={`${analytics.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/analytics" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-bar-chart"></i>
                <span className="nav-text">Relatórios</span>
              </Link>
            </li>
            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/users" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-id-badge"></i>
                <span className="nav-text">Usuários</span>
              </Link>
            </li>
            <li className={`${delivery_fee.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/delivery-fee" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="las la-calculator" style={styles.icon}></i>
                <span className="nav-text">Taxa de entrega</span>
              </Link>
            </li>
            <li className={`${customizations.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/customizations" aria-expanded="false" onClick={() => this.forceCloseMenu()}>
                <i className="ti-settings"></i>
                <span className="nav-text">Configurações</span>
              </Link>
            </li>

          </MM>

        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;

const styles = {
  icon: {
    fontSize: '1.6rem',
    marginTop: '-4px',
    marginLeft: '-3px'
  }
}
