import React, { useEffect, useState, createRef } from "react";
import { useParams } from "react-router-dom";
import { Hash } from '../helpers/Helpers'
import { Create, Update, GetOne } from '../services/CategoriesServices'
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { Theme } from '../App'
import Errors from '../jsx/components/Errors'
import AlertResponses from '../jsx/components/Alert'
import { useForm } from "react-hook-form";

export default function CategorieDetails(props) {
  const { id } = useParams();
  const [categorie, setCategorie] = useState(null);
  const [name, setName] = useState(null);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const imageRef = createRef(null);
  const [isLoading, setIsLoading] = useState(true);

  //TODO: Puxar o status na api dos produtos
  const [status, setStatus] = useState('');
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const { register, handleSubmit, watch, setError, formState: { errors } } = useForm();


  useEffect(() => {
    if (id !== undefined) {
      GetOne(id, setCategorie, setIsLoading);
    } else {
      setIsLoading(false)
    }
  }, []);

  useEffect(() => {
    let isReady = false;
    if (!isReady && id !== null && categorie !== null) setIsLoading(false);
    else if (!isReady && id === null && categorie !== null) setIsLoading(false);
    return () => { isReady = true }
  }, [categorie]);

  const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 20%;
`;

  const imagePreview = (image) => {
    let url = URL.createObjectURL(image);
    setImageFile(url)
  }

  const onSubmit = (data) => {

    var form = new FormData();

    form.append('name', data.name);
    data.image.length > 0 && form.append('thumb', data.image[0]);
    form.append('status', data.status);

    if (id !== undefined) {
      form.append('id', id);
      Update(id, form, setCategorie, setNotify);
    } else {
      Create(form, setCategorie, setNotify, setError);
    }

  }

  return (
    <>
      <div className="row">
        {
          isLoading ? <BeatLoader color={Theme.colors.primary} loading={isLoading} css={override} size={12} />
            :
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-4 col-md-4 col-xxl-4">
                      {
                        categorie !== null || imageFile !== null ?
                          //TODO: VOLTAR PARA O NORMAL DE PRO
                          <img className="img-fluid rounded mt-4 categorie-image" src={imageFile === null ? categorie.thumb : imageFile} alt="image" style={styles.image} />
                          /*  <img className="img-fluid rounded mt-4 categorie-image" src={imageFile === null ? Hash('400x400', 'categories/' + categorie.thumb.split('/').pop()) : imageFile} alt="image" /> */
                          : <div className="image-default rounded-lg">
                            <i className="ti-image"></i>
                          </div>
                      }

                      <div className="input-group mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Upload</span>
                        </div>
                        <div className="custom-file">
                          <input accept="image/*" type="file" className="custom-file-input"
                            ref={register} {...register("image")}
                            onChange={(e) => imagePreview(e.target.files[0])} />
                          <label className="custom-file-label">Selecione...</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="">{categorie !== null ? categorie.name : 'Nova Categoria'}</h3>
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Nome</label>
                                  <input
                                    {...register("name", { required: true, maxLength: 20 })}
                                    className="form-control text-black"
                                    defaultValue={categorie !== null ? categorie.name : ''}
                                  />
                                  {errors.name &&
                                    <small className="ml-2 text-danger">
                                      {errors.name.type === 'required' && 'Por favor, preencha este campo!'}
                                      {errors.name.type === 'maxLength' && 'Este campo deve conter no máximo 20 caracteres!'}
                                      {errors.name.type === 'custom' && errors.name.message}
                                    </small>}
                                </div>

                                {errors.price &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.price.type === 'required' && 'Por favor, preencha este campo!'}</small>}

                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label>Status</label>
                                  <select
                                    className="form-control text-black"
                                    defaultValue={categorie !== null ? categorie.status : ''}
                                    {...register("status", { required: true })}
                                  >
                                    <option value={1}>Ativo</option>
                                    <option value={0}>Desativado</option>
                                  </select>
                                </div>
                                {errors.status &&
                                  <small className="ml-2 mb-2 text-danger">
                                    {errors.status.type === 'required' && 'Por favor, preencha este campo!'}</small>}
                              </div>
                              {console.log(errors)}
                              <input type="submit" className="btn btn-block btn-primary">
                              </input>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                notify.visible &&
                <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
              }
            </div>
        }
      </div>
    </>
  );
};

const styles = {
  image: {
    maxWidth: 330,
    maxHeight: 250,
    width: '100%',
    objectFit: 'cover'
  }
}
