import React from 'react'
import Svg from '../../../images/error.svg'

export default function ErrorsComponent ({args}){
    return(
        <>
        <div className="col-xl-12 col-lg-12 col-md-12 col-xxl-12 mt-5">
            <div className="col-xl-6 text-center m-auto">
                <img src={Svg} style={{height: '300px', width: '300px'}}/>
                <h1 className="mt-4">{args.title}</h1>
                <h3>{args.message}</h3>
            </div>
        </div>
        </>
    )
}