import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../images/logo.svg";
import SocialButton from "../jsx/components/SocialButton";
import UserContext from '../contexts/UserContext'
import DefaultLoader from '../jsx/components/Loader'
import AlertResponses from '../jsx/components/Alert'
import { Auth, HasToken } from '../services/LoginServices'
import { ERROR_MESSAGES } from "../helpers/ErrorMessages";

const Login = () => {

  const { user, setUser, setLogged } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const history = useHistory();

  useEffect(() => {
    HasToken(setUser, setLogged, history);
  }, [])

  const handleSocialLogin = async (data) => {
    setIsLoading(true);
    let response = await Auth(data._profile, setNotify, setIsLoading);
    if (response.status && response.status === 404) {
      setNotify({ title: 'Oops!', message: ERROR_MESSAGES.login.permission, type: 'error', visible: true });
    } else {
      setUser(response);
      setLogged(true);
      history.push("/");
    };
    setIsLoading(false);
  };

  const handleSocialLoginFailure = (err) => {
    setNotify({ title: 'Oops!', message: ERROR_MESSAGES.login.generic, type: 'error', visible: true });
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 c-login-background"></div>
          <div className="col-md-6 c-login">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <div className="auth-form text-center">
                  <div className="text-center mb-3">
                    <img src={Logo} alt="Logo" width="150" />
                  </div>
                  <h2 className="text-center mt-4 mb-4 text-secondary h2">
                    Portal do Lojista
                  </h2>
                  {isLoading ?
                    <>
                      <h5 className="wait-login">Aguarde...</h5>
                      <DefaultLoader />
                    </>
                    :

                    <SocialButton
                      provider="google"
                      appId="361008005922-b8p6jfm6pt6e06onun3mqct2dtdldbg0.apps.googleusercontent.com"
                      onLoginSuccess={handleSocialLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      key={'google'}
                    >
                      Continue com o Google
                    </SocialButton>
                  }
                </div>
              </div>
            </div>
            <div className="page-login footer">
              <div className="copyright">
                <p>
                  Copyright © Desenvolvidor por {" "}
                  <a href="https://pralevar.app" target="_blank">
                    Pralevar.app
                  </a>{" "}
                  {new Date().getFullYear()}
                </p>
              </div>
            </div>
            {
              notify.visible &&
              <>
                <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} pageLogin={true} />
              </>
            }
          </div>
        </div>
      </div>

    </div>
  );
};

export default Login;
