import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import DataTablev2 from '../jsx/components/Datatable'
import { GetAll, Delete } from '../services/CouponsServices'
import { Hash } from '../helpers/Helpers'
import DefaultLoader from '../jsx/components/Loader'
import Swal from "sweetalert2";
import AlertResponses from '../jsx/components/Alert'
import ErrorsComponent from '../jsx/components/Errors'
import ProgressBarComponent from '../jsx/components/ProgressBar'
import ModalComponent from '../jsx/components/Modal'
import CouponDetails from './CouponDetails'

export default function Coupons() {

  const data = useRef(null);
  const [coupons, setCoupons] = useState('');
  const [coupon, setCoupon] = useState(null);
  const [categories, setCategories] = useState('');
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [notify, setNotify] = useState({ title: '', message: '', type: '', visible: false });
  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const mode = useRef(null);

  useEffect(() => {
    GetAll(setCoupons, setErrors, setIsLoading);
  }, [])

  const deleteRows = (state) => {
    if (state.selectedRows !== (null || undefined) && state.selectedRows.length > 0) {
      setSelectedRows(state.selectedRows);
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  }

  const modalProps = {
    title: 'Novo Cupom',
    content: <CouponDetails data={data.current} setNotify={setNotify} setShowModal={setShowModal}/>,
    btnLabel: 'Salvar',
    showModal: showModal,
    setShowModal: setShowModal,
    showFooter: false,
  }

  const status = {
    1: {
      id: 1,
      label: "Ativo",
      type: "success"
    },
    2: {
      id: 2,
      label: "Expirado",
      type: "danger"
    },
  }

  const BadgeStatus = (item) => {

    item.status = new Date(item.expiration).getTime() > new Date(Date.now()).getTime() ? 1 : 2;

    return (
      <span className={`badge badge-${status[item.status].type} badge-sm light badge-delivery-status badge-${item.id}`}>{status[item.status].label}</span>
    )
  }

  const columns = [
    {
      name: '#',
      selector: (row) => row.id,
      maxWidth: '5%'
    },
    {
      name: 'Nome',
      selector: (row) => <Link onClick={() => {data.current = row; setShowModal(true);}}>{row.name}</Link>,
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Regras',
      selector: (row) => row.rules,
      maxWidth: '15%'
    },
    {
      name: 'Desconto',
      selector: (row) => row.discount + '%',
      maxWidth: '10%'
    },
    {
      name: 'Categoria',
      selector: (row) => row.category_name,
      sortable: true,
      maxWidth: '15%'
    },
    {
      name: 'Criado em',
      selector: (row) => new Date(row.date_created).toLocaleString('pt-BR'),
      maxWidth: '15%',
      sortable: true,
    },
    {
      name: 'Expira em',
      selector: (row) => new Date(row.expiration).toLocaleString('pt-BR'),
      maxWidth: '15%',
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => BadgeStatus(row),
      maxWidth: '10%',
      allowOverflow: true
    }
  ];

  return (
    <>
      {
        isLoading ? <DefaultLoader isLoading={isLoading} /> :
          <>
            {
              errors !== null && <ErrorsComponent args={errors} />
            }
            <div className="row mt-4">
              <div className="col-xl-12 col-xxl-12 col-sm-12 page-header">
                <div className="col-xl-4 col-xxl-5 col-sm-12">
                  <h2>Cupons</h2>
                </div>

                <div className="col-xl-4 col-xxl-5 col-sm-12 d-flex justify-content-end">
                  {showDeleteButton &&

                    <Button className="i-false btn btn-primary mr-3 mb-4 btn btn-primary float-right btn btn-primary"
                      onClick={() =>
                        Swal.fire({
                          title: "Você tem certeza?",
                          text:
                            "Todos os arquivos selecionados serão deletados. Esta ação é irreversível!",
                          icon: "warning",
                          buttons: true,
                          confirmButtonText: `Continuar a ação`,
                          cancelButtonText: `Cancelar`,
                          showCancelButton: true,
                          confirmButtonColor: 'red'
                        }).then((value) => {
                          if (value) Delete(selectedRows, setNotify, setProgress)
                        })
                      }
                    >
                      <span>DELETAR SELECIONADOS</span>
                    </Button>
                  }
                  <Button className="i-false btn btn-primary mb-4 btn btn-primary float-right btn btn-primary"
                    onClick={() => {data.current = ''; setShowModal(true)}}>
                    <span>ADICIONAR</span>
                  </Button>
                </div>

              </div>
            </div>
            {
              notify.visible &&
              <AlertResponses title={notify.title} message={notify.message} type={notify.type} visible={notify.visible} setNotify={setNotify} />
            }

            <DataTablev2
              data={coupons}
              columns={columns}
              title="  "
              selectable={true}
              onSelectedRowsChange={deleteRows}
              showDeleteButton={showDeleteButton}
              filterByField={{ labels: ['Nome', 'Categoria'], fields: ['name','category_name'] }}
            />

            {progress && <ProgressBarComponent />}
           
            {showModal && <ModalComponent props={modalProps}/>}
          </>
      }
    </>
  );
}

const styles = {

}