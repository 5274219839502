import { api } from '../api/Middleware'
import { DELIVERY, PLACES_API, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'
import { RemoveAccents } from '../helpers/Helpers'
import { AppSettings } from '../App'

export const GetOne = async (id, setUser, setValue, setIsLoading) => {
    try {
        let response = await api.get(DELIVERY + id + '/');
        if (response.status === 200) {
            setValue('birthday', response.data.birthday)
            setValue('phone', response.data.phone)
            setValue('cpf', response.data.cpf)

            setUser(response.data);
        }
    } catch (error) {
        //TODO: TRATAR ERRORS 401 NO MIDDLEWARE
        /*  if(error.status !== 401){
             
             
         }  */
    }
    setIsLoading(false)
}

export const GetAll = async (setDeliveryFee, setErrors, setIsLoading) => {

    try {
        let response = await api.get(DELIVERY);
        if (response.status === 200) setDeliveryFee(response.data);
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
    }

    setIsLoading(false);
}

export const Create = async (data, fee, setNotify, setError, setIsLoading) => {
    try {
        let arr = []
        data.map(v => {
            v.value.fee = fee;
            v.value.neighborhood = v.value.neighborhood
            v.value.city = v.value.city
            v.value.state = v.value.state
            // v.value.neighborhood = RemoveAccents(v.value.neighborhood).toLowerCase()
            // v.value.city = RemoveAccents(v.value.city).toLowerCase()
            // v.value.state = RemoveAccents(v.value.state).toLowerCase()
            arr.push(v.value)
        })

        let response = await api.post(DELIVERY, arr);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Bairro(s) cadastrado(s) com sucesso!', type: 'success', visible: true });
            setTimeout(() => { }, [
                window.location.href = '/delivery-fee'
            ], 4000)

        }

    } catch (error) {
        if (error.response.status === 400) {
            setError("name", {
                "message": error.response.data,
                "type": "custom"
            })
        } else if (error.response.status === 401) {
            window.location.href = APP_BASE_URL
        } else {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.categories.update, type: 'error', visible: true });
        }
        //if(error.status !== 401) throw error.response;

    }
}

export const Update = async (id, data, setUser, setNotify) => {
    try {
        let response = await api.put(DELIVERY + id + '/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Usuário atualizado com sucesso!', type: 'success', visible: true });
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.products.update, type: 'error', visible: true });
    }
}

export const ChangeStatus = async (data, setNotify, setProgress) => {
    setProgress(true);
    try {
        let response = await api.put(DELIVERY + data.id + '/', { "status": parseInt(data.status) });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: "Status atualizado com sucesso!", type: 'success', visible: true })
            setProgress(false);
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        setProgress(false);
        return false;
    }

}

export const Delete = async (id, setNotify, setProgress) => {
    setProgress(true);

    try {
        let ids = id.map(value => value.id)

        let response = await api.delete(DELIVERY + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }

    setProgress(false);
}

/* export const FindPlace = async (text, setNotify) => {
    try {
        let response = await api.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json??country=br&proximity=ip&types=neighborhood&language=pt&access_token=${AppSettings.mapBoxApiKey}`);

        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        console.log(error)
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
        if (error.response.status !== 401) {
            //return Alert.alert('Oops!', ERROR_MESSAGES.generic);
        }
    }
} */

export const FindPlace = async (text, setNotify) => {
    try {
        let response = await api.get(`${PLACES_API}&lang=pt&in=countryCode:BRA&limit=5&apiKey=${AppSettings.hereApiKey}&q=${text}`);
        if (response.status === 200) {
            return response.data.items;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }
}