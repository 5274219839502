import axios from "axios";
import { BASE_URL, APP_BASE_URL } from "./Urls";
import jwt_decode from "jwt-decode";

export const api = axios.create({
  baseURL: BASE_URL,
});

export const GetToken = () => {

  return localStorage.getItem("token");
};

export const VerifyIfTokenIsExpired = (token) => {
  let tokenDecoded = jwt_decode(token);

  if (!tokenDecoded.is_superuser) {
    localStorage.clear()
    window.location.href = APP_BASE_URL + '/login'
  }

  return tokenDecoded.exp < Date.now() / 1000;
};

api.interceptors.request.use(
  function (config) {
    switch (true) {
      case config.url.includes("api/v1/store/configs"):
        delete config.headers.Authorization;
        break;
      case config.url.includes("auth/login"):
        delete config.headers.Authorization;
        break;
      case config.url.includes("hereapi.com"):
        delete config.headers.Authorization;
        break;
      case config.url.includes("ifood.com.br") && config.method === "post":
        delete config.headers.Authorization;
        break;
      default:
        let token = GetToken()
        let tokenDecoded = jwt_decode(token)

        if (!tokenDecoded.is_superuser) {
          localStorage.clear()
          window.location.href = APP_BASE_URL + '/login'
          return
        }

        config.headers.Authorization = `Bearer ${token}`;
        break;
    }

    return config;
  },
  function (error) {
    alert(`Ocorreu um erro em sua solicitação. Erro: ${error}`);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = APP_BASE_URL + '/login'
    }

    return Promise.reject(error);
  }
);
