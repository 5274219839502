import { api } from '../api/Middleware'
import { VARIATIONS_URL, APP_BASE_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetAll = async (setVariations, setErrors, setIsLoading, returnData = false) => {

    try {
        let response = await api.get(VARIATIONS_URL);
        if (response.status === 200) {
            if (returnData !== false) return response.data;
            setVariations(response.data);
        }
    } catch (error) {
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.categories.get })
    }

    setIsLoading(false)
}

export const Create = async (data, setVariation, setNotify, setError, setIsLoading, setShowModal) => {


    try {
        let response = await api.post(VARIATIONS_URL, data);
        if (response.status === 201) {
            setVariation(response.data);
            setNotify({ title: 'Sucesso!', message: 'Variação cadastrada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }

    } catch (error) {
        if (error.response.status === 401) {
            window.location.href = APP_BASE_URL
        } else {
            setNotify({ title: 'Oops!', message: ERROR_MESSAGES.variations.create, type: 'error', visible: true });
        }

    }
}
export const Update = async (id, data, setVariation, setNotify, setIsLoading) => {

    try {
        let response = await api.put(VARIATIONS_URL + id + '/', data);
        if (response.status === 200) {
            setVariation(response.data);
            setNotify({ title: 'Sucesso!', message: 'Variação atualizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.variations.update, type: 'error', visible: true });
    }
}

export const ChangeStatus = async (data, setNotify) => {
    try {
        let response = await api.put(VARIATIONS_URL + data.id + '/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: "Status atualizado com sucesso!", type: 'success', visible: true })
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.variations.status, type: 'error', visible: true });
        return false;
    }
}

export const Delete = async (id, setNotify, setProgress) => {
    setProgress(true);

    try {
        let ids = [id];

        let response = await api.delete(VARIATIONS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }

    setProgress(false);
}