import { api } from '../api/Middleware'
import { VW_ORDERS, ORDERS_URL } from '../api/Urls'
import { ERROR_MESSAGES } from '../helpers/ErrorMessages'

export const GetOne = async (id, setOrder, setIsLoading, setErrors) => {
    try {
        let response = await api.get(VW_ORDERS + id + '/');
        if (response.status === 200) setOrder(response.data.data);
    } catch (error) {
        if (error.response.status === 401) {

        }

        if (error.response.status === 404) {
            setErrors({title: 'Oops!', message: 'Produto não encontrado!'})
        }
    }
    setIsLoading(false)
}

export const Get = async (setOrders, setErrors, setIsLoading) => {
    
    try {
        let response = await api.get(VW_ORDERS);
        let data = [];
        response.data.map(item => {
            item.data.status.reverse();
            data.push(item.data)
        })
        
        if (response.status === 200) setOrders(data);
    } catch (error) {
        console.log(error)
        setErrors({ title: 'Oops!', message: ERROR_MESSAGES.orders.get })
    }
    setIsLoading(false);
}

export const ChangeStatus = async (data, setNotify) => {
    try {
        let response = await api.put(ORDERS_URL + 'change_status/', data);
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: response.data, type: 'success', visible: true })
            return true;
        }
    } catch (error) {
        setNotify({ title: 'Oops!', message: ERROR_MESSAGES.orders.status, type: 'error', visible: true });
        return false;
    }
}

export const Delete = async (data, setNotify) => {
    try {
        let ids = [];
        data.map(order => ids.push(order.id))
        let response = await api.delete(ORDERS_URL + 'delete_multiple/', { data: { 'ids': ids } });
        if (response.status === 200) {
            setNotify({ title: 'Sucesso!', message: 'Operação realizada com sucesso!', type: 'success', visible: true });
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }

    } catch (error) {
        //if(error.status !== 401) throw error.response;
        setNotify({ title: 'Oops!', message: error.response.data, type: 'error', visible: true });
    }
}

export const GetNewOrders = async (setNewOrders, returnData) => {
    try {
        let response = await api.get(VW_ORDERS + 'notify_new_orders/');
        if(returnData){
            return response.data
        }else{
            setNewOrders(response.data);
        }
        
    } catch (error) {
        console.log(error)
    }
}