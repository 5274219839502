import React, { useEffect, useState, useRef } from "react";
import { Create, Update } from '../services/VariationsServices'
import { useForm } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { Button, Accordion, Card } from "react-bootstrap";
import DefaultLoader from '../jsx/components/Loader'

export default function VariationDetails(props) {
  const [variation, setVariation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [erros, setErros] = useState(null)
  const [progress, setProgress] = useState(false);
  const { register, handleSubmit, watch, setError, setValue, formState: { errors } } = useForm();
  const [collapsed, setCollapsed] = useState(0);
  const [count, setCount] = useState(1)

  useEffect(() => {
    if (props.data !== null || props.data !== '') {
      setVariation(props.data);
    }
  }, []);


  const onSubmit = (data) => {

    if (props.data !== null && props.data.id !== undefined) {

      /* let arr = {
        internalTitle: data.internalTitle,
        title: data.title,
        requiredChoice: data.requiredChoice ? 1 : 0,
        status: 1,
        items: []
      }; */


      Object.keys(variation.data.items).map(value => {
        let test = /R\$|\,/.test(variation.data.items[value].additional_price)
        if (variation.data.items[value].additional_price && test) {
          variation.data.items[value].additional_price = parseFloat(variation.data.items[value].additional_price.replace(/R\$/, '').replace(',', '.'));
        }

      })

      variation.data.requiredChoice = data.requiredChoice ? 1 : 0
      variation.data.addVariationValueToProduct = data.addVariationValueToProduct ? 1 : 0

      Update(props.data.id, variation, setVariation, props.setNotify, setIsLoading);
    } else {

      let arr = {
        internalTitle: data.internalTitle,
        title: data.title,
        requiredChoice: data.requiredChoice ? 1 : 0,
        addVariationValueToProduct: data.addVariationValueToProduct ? 1 : 0,
        status: 1,
        items: []
      };

      delete data.title;

      Object.keys(data).map(value => {

        let i = value.split('-')[1]

        if (arr.items[i]) {
          if (value.includes('description')) arr.items[i].description = data[value];
          if (value.includes('additional_price')) arr.items[i].additional_price = parseFloat(data[value].replace(/R\$/, '').replace(',', '.'));
        } else {
          arr.items[i] = { name: data[value] }
        }

      })

      let form = new FormData();
      form.append('data', JSON.stringify(arr))
      Create(form, setVariation, props.setNotify);
    }
  }

  return (
    <div className="basic-form">
      {variation !== null ?
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Título Interno</label>
              <input
                {...register("internalTitle", { required: true, maxLength: 30 })}
                className="form-control text-black"
                defaultValue={variation ? setValue('internalTitle', variation.data.internalTitle) : ''}
                onChange={(e) => variation.data.internalTitle = e.target.value}
              />
              {errors.internalTitle &&
                <small className="ml-2 text-danger">
                  {errors.internalTitle.type === 'required'
                    ? 'Por favor, preencha este campo!'
                    : 'Este campo deve conter no máximo 30 caracteres!'}</small>}
            </div>
            <div className="form-group col-md-12">
              <label>Título no app</label>
              <input
                {...register("title", { required: true, maxLength: 35 })}
                className="form-control text-black"
                defaultValue={variation ? setValue('title', variation.data.title) : ''}
                onChange={(e) => variation.data.title = e.target.value}
              />
              {errors.title &&
                <small className="ml-2 text-danger">
                  {errors.title.type === 'required'
                    ? 'Por favor, preencha este campo!'
                    : 'Este campo deve conter no máximo 35 caracteres!'}</small>}
            </div>
            <div className="form-group col-md-12">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="custom-control custom-checkbox ml-1">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="requiredChoice"
                    {...register("requiredChoice")}
                    onChange={(e) => variation.data.requiredChoice = e.target.checked}
                    defaultValue={variation.data.requiredChoice}
                    defaultChecked={variation.data.requiredChoice ? true : false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="requiredChoice"
                  >Escolha obrigatória?</label>
                </div>

              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="custom-control custom-checkbox ml-1">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="addVariationValueToProduct"
                    {...register("addVariationValueToProduct")}
                    onChange={(e) => variation.data.addVariationValueToProduct = e.target.checked}
                    defaultValue={variation.data.addVariationValueToProduct}
                    defaultChecked={variation.data.addVariationValueToProduct ? true : false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="addVariationValueToProduct"
                  >Somar o valor do produto na variação?</label>
                </div>
              </div>
            </div>

            <div className="form-group col-md-12">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <label>Variações</label>
                <Button type="button" className="btn btn-primary light sharp"
                  onClick={() => {
                    let v = { ...variation }
                    v.data.items.push({ name: 'Nova', description: '', additional_price: '' })
                    setVariation(v)
                  }}>
                  <i className="ti-plus"></i>
                  {isLoading ? <DefaultLoader color={'white'} /> : ''}
                </Button>
              </div>
              {
                variation.data.items.map((value, i) => {
                  return (
                    <div className='accordion__item mb-2' key={i}>
                      <Accordion
                        className='accordion accordion-active-header'
                      >
                        <div key={i} className={`accordion-${i}`}>
                          <Accordion.Toggle
                            as={Card.Text}
                            className={`accordion__header ${collapsed !== `${i}` ? 'collapsed' : ''} accordion__header--primary p-2 mb-1`}
                            eventKey={`${i}`}
                            onClick={() => setCollapsed(`${i}`)}

                          >
                            <span className='accordion__header--icon'></span>
                            <span className='accordion__header--text'>{value.name}</span>
                            <span className='accordion__header--indicator '></span>
                          </Accordion.Toggle>
                          <Accordion.Collapse
                            className='accordion__body'
                            eventKey={`${i}`}
                          >
                            <Card.Body className="text-left pt-3">
                              <div className="form-group">
                                <label className="pl-2">Nome</label>
                                <input type="text" className="form-control input-rounded" defaultValue={value.name}
                                  {...register(`name${i}`)}
                                  onChange={(e) => {
                                    document.querySelector(`.accordion-${i} .accordion__header--text`).innerHTML = e.target.value;
                                    variation.data.items[i].name = e.target.value;
                                  }} />

                              </div>

                              <div className="form-group">
                                <label className="pl-2">Descrição</label>
                                <input type="text" className="form-control input-rounded" defaultValue={value.description}
                                  onChange={(e) => variation.data.items[i].description = e.target.value} />

                              </div>

                              <div className="form-group">
                                <label className="pl-2">Valor adicional</label>

                                <NumberFormat
                                  defaultValue={value.additional_price}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$"
                                  className="form-control input-rounded"
                                  onChange={(e) => variation.data.items[i].additional_price = e.target.value}
                                />

                              </div>
                            </Card.Body>

                          </Accordion.Collapse>
                        </div>

                      </Accordion>
                    </div>
                  )
                })
              }

            </div>
          </div>


          <Button type="submit" className="btn btn-block btn-primary">
            {isLoading ? <DefaultLoader color={'white'} /> : 'Salvar'}
          </Button>
        </form>
        :
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Título Interno</label>
              <input
                {...register("internalTitle", { required: true, maxLength: 30 })}
                className="form-control text-black"
                defaultValue={''}
              /* onChange={(e) => variation.data.title = e.target.value} */
              />
              {errors.internalTitle &&
                <small className="ml-2 text-danger">
                  {errors.internalTitle.type === 'required'
                    ? 'Por favor, preencha este campo!'
                    : 'Este campo deve conter no máximo 30 caracteres!'}</small>}
            </div>
            <div className="form-group col-md-12">
              <label>Título no app</label>
              <input
                {...register("title", { required: true, maxLength: 35 })}
                className="form-control text-black"
                defaultValue={''}
              /* onChange={(e) => variation.data.title = e.target.value} */
              />
              {errors.title &&
                <small className="ml-2 text-danger">
                  {errors.title.type === 'required'
                    ? 'Por favor, preencha este campo!'
                    : 'Este campo deve conter no máximo 35 caracteres!'}</small>}
            </div>
            <div className="custom-control custom-checkbox ml-1 mb-1">
              <input
                type="checkbox"
                className="custom-control-input"
                id="requiredChoice"
                {...register("requiredChoice")}
              /*  onChange={(e) => variation.data.requiredChoice = e.target.checked} */
              />
              <label
                className="custom-control-label"
                htmlFor="requiredChoice"
              >Escolha obrigatória?</label>
            </div>
            <div className="custom-control custom-checkbox ml-1 mb-4">
              <input
                type="checkbox"
                className="custom-control-input"
                id="addVariationValueToProduct"
                {...register("addVariationValueToProduct")}
              /*  onChange={(e) => variation.data.requiredChoice = e.target.checked} */
              />
              <label
                className="custom-control-label"
                htmlFor="addVariationValueToProduct"
              >Somar o valor do produto na variação?</label>
            </div>

            <div className="form-group col-md-12">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <label>Variações</label>
                <Button type="button" className="btn btn-primary light sharp"
                  onClick={() => setCount(count + 1)}>
                  <i className="ti-plus"></i>
                  {isLoading ? <DefaultLoader color={'white'} /> : ''}
                </Button>
              </div>


              <div className='accordion__item mb-2'>
                <Accordion
                  className='accordion accordion-active-header'
                >
                  {[...Array(count)].map((value, i) => {
                    return (
                      <div className={`accordion-${i}`} key={i}>
                        <Accordion.Toggle
                          as={Card.Text}
                          className={`accordion__header ${collapsed !== `${i}` ? 'collapsed' : ''} accordion__header--primary p-2 mb-1`}
                          eventKey={`${i}`}
                        /* onClick={() => setCollapsed(`${i}`)} */

                        >
                          <span className='accordion__header--icon'></span>
                          <span className='accordion__header--text'>Nova</span>
                          <span className='accordion__header--indicator '></span>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          className='accordion__body'
                          eventKey={`${i}`}
                        >
                          <Card.Body className="text-left pt-3">
                            <div className="form-group">
                              <label className="pl-2">Nome</label>
                              <input type="text" className="form-control input-rounded" defaultValue={''}
                                maxLength={20}
                                {...register(`name-${i}`, { required: true, maxLength: 20 })}
                                onChange={(e) => {
                                  document.querySelector(`.accordion-${i} .accordion__header--text`).innerHTML = e.target.value
                                }} />
                              {errors[`name-${i}`] &&
                                <small className="ml-2 text-danger">
                                  {errors[`name-${i}`].type === 'required'
                                    ? 'Por favor, preencha este campo!'
                                    : 'Este campo deve conter no máximo 20 caracteres!'}</small>}
                            </div>

                            <div className="form-group">
                              <label className="pl-2">Descrição</label>
                              <input type="text" className="form-control input-rounded" defaultValue={''}
                                maxLength={30}
                                {...register(`description-${i}`)}
                              />

                            </div>

                            <div className="form-group">
                              <label className="pl-2">Valor adicional</label>

                              <NumberFormat
                                defaultValue={''}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                className="form-control input-rounded"
                                {...register(`additional_price-${i}`)}

                              />

                            </div>
                          </Card.Body>

                        </Accordion.Collapse>
                      </div>
                    )
                  })}

                </Accordion>
              </div>
            </div>
          </div>
          <Button type="submit" className="btn btn-block btn-primary">
            {isLoading ? <DefaultLoader color={'white'} /> : 'Salvar'}
          </Button>
        </form>
      }
    </div>
  );
};

const styles = {
  i: {
    fontWeight: '700',

  }
}